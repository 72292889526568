import React, { useState } from 'react';
import SimpleToolbar from '../../../components/SimpleToolbar';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddCustomOfferGroup from '../AddOfferGroup';
import EditCustomOfferGroup from '../EditOfferGroup';
import { CustomOfferGroup } from '../types';
import useCustomOffer from '../useCustomOffers';

type Props = {
  onAddOfferGroupDone: (newGroup: CustomOfferGroup) => any;
  onEditOfferGroupDone: (updatedGroup: CustomOfferGroup) => any;
  selectedRow: any;
};

const OfferGroupToolbar = ({ onAddOfferGroupDone, onEditOfferGroupDone , selectedRow }: Props) => {
  const api = useCustomOffer();
  const [editGroup, setEditGroup] = useState<CustomOfferGroup | null>(null);
  const onAddOfferGroup = async (newGroup: CustomOfferGroup) => {
    const result = await api.createOfferGroup().call(newGroup);
    onAddOfferGroupDone(result);
  };

  
  const onEditOfferGroup = async (updatedGroup: CustomOfferGroup) => {

    const result = await api.updateOfferGroup().call(selectedRow._id,updatedGroup);

    if(result){
      const mergedGroup = {
        ...selectedRow,
        ...updatedGroup,
        trafficSources: selectedRow.trafficSources,
        _id: selectedRow._id,
        createdAt: selectedRow.createdAt,
        updatedAt: new Date().toISOString(), 
        __v: selectedRow.__v
      };
      onEditOfferGroupDone(mergedGroup);
    }

  };

  const buttons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <AddCustomOfferGroup
            open={open}
            handleClose={handleClose}
            onSubmit={onAddOfferGroup}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRow),
      icon: <EditIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <EditCustomOfferGroup
            open={open}
            handleClose={handleClose}
            onSubmit={onEditOfferGroup}
            offerData={selectedRow}
          />
        );
      },
    },
  ];
  return <SimpleToolbar modalButtons={buttons}></SimpleToolbar>;
};

export default OfferGroupToolbar;
