import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

function TooltipIconButton({ title, icon, onClick, size = 'medium' }) {

  const handleClick = e => {
    e.stopPropagation();

    onClick();
  };

  
  return (
    <Tooltip title={title}>
      <IconButton onClick={handleClick} size={size}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default TooltipIconButton;
