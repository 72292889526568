import React, { useState, useContext } from 'react';
import { SingleAffiliateContext } from '../context';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

import {
  Typography,
  Button,
  CircularProgress,
  TextField,
  MenuItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import SimpleModal from '../../../../components/SimpleModal';
import { QualityEntry } from '../SingleQualityReport/types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onEditQualityReports: (
    qualityReportId: QualityEntry['_id'],
    newQualityReport: QualityEntry
  ) => any;
  initialValues: QualityEntry;
};

const EditQualityReports = ({
  open,
  handleClose,
  onEditQualityReports,
  initialValues,
}: Props) => {
  const { affiliate } = useContext(SingleAffiliateContext);
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  console.log('editValues >>>', values);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues(() => ({
      ...values,
      [name]: value,
    }));
  };

  const handleDateChange = (newDate: Dayjs | null) => {
    const affiliateId = affiliate?._id;

    setValues((prevValues: any) => ({
      ...prevValues,
      affiliateId: affiliateId,
      date: newDate?.toDate(),
    }));
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await onEditQualityReports(initialValues._id, {
        ...values,
        isPublished: !!values.isPublished,
      });
      handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit Merchant Quality Report</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DatePicker
          label="Pick reporting date"
          value={dayjs(values.date)}
          onChange={handleDateChange}
          maxDate={dayjs()}
        />
      </LocalizationProvider>
      <TextField
        select
        fullWidth
        name="isPublished"
        onChange={handleChange}
        label="Publish status"
        value={+values.isPublished}
      >
        <MenuItem value={1}>Published</MenuItem>
        <MenuItem value={0}>Not Published</MenuItem>
      </TextField>
      <Button
        color="primary"
        variant="contained"
        onClick={onSubmit}
        fullWidth
        disabled={!values.date || loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
      </Button>
    </SimpleModal>
  );
};

export default EditQualityReports;
