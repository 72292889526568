import React from 'react';
import { EditClicksDTO, QualityEntries, QualityEntry } from '../types';

import useAffiliate from '../../../useAffiliate';
import EditQualityReportsEntries from '../EditQualityReportsEntries';
import EditQualityReportsEntriesClicks from '../EditQualityReportsEntriesClicks';
import SimpleToolbar from '../../../../../components/SimpleToolbar';
import EditIcon from '@mui/icons-material/Edit';
import DisplayOriginalSwitch from '../../../../../components/DisplayOriginalSwitch';

type Props = {
  onEditQualityReportsEntryDone: (
    newQualityReportEntry: QualityEntries[]
  ) => any;
  onEditQualityReportsEntryClicksDone: (
    newQualityReportEntry: QualityEntries[]
  ) => any;
  selectedQualityReportEntry?: QualityEntries;
  selectedQualityReportEntryArray: QualityEntries[];
  qualityEntry?: QualityEntry;
  currentData: any[];
  originalToggleChecked: boolean;
  handleToggleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const QualityReportsEntriesToolbar = ({
  onEditQualityReportsEntryDone,
  onEditQualityReportsEntryClicksDone,
  selectedQualityReportEntry,
  selectedQualityReportEntryArray,
  qualityEntry,
  currentData,
  originalToggleChecked,
  handleToggleClick,
}: Props) => {
  const { editSingleQualityEntry, editSingleQualityEntryClicks } =
    useAffiliate();

  const onEditQualityReportsEntry = async (
    qualityEntryId: string,
    qualityReportEntryId: string,
    dto: QualityEntries
  ) => {
    const newQualityReportEntry = await editSingleQualityEntry().call(
      qualityEntryId,
      qualityReportEntryId,
      dto
    );
    onEditQualityReportsEntryDone(newQualityReportEntry);
  };

  const onEditQualityReportsEntryClicks = async (
    qualityEntryId: string,
    dto: EditClicksDTO
  ) => {
    const newQualityReportEntry = await editSingleQualityEntryClicks().call(
      qualityEntryId,
      dto
    );
    onEditQualityReportsEntryClicksDone(newQualityReportEntry);
  };

  const modalButtons = [
    {
      name: 'edit',
      label: 'Edit',
      renderIf:
        Boolean(selectedQualityReportEntry) &&
        !Array.isArray(selectedQualityReportEntry),
      icon: <EditIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <EditQualityReportsEntries
            open={open}
            handleClose={handleClose}
            onEditQualityReportsEntries={onEditQualityReportsEntry}
            initialValues={selectedQualityReportEntry!}
            qualityEntry={qualityEntry!}
          />
        );
      },
    },
    {
      name: 'bulkUpdate',
      label: 'Bulk Update',
      renderIf: Boolean(selectedQualityReportEntryArray),
      icon: <EditIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <EditQualityReportsEntriesClicks
            open={open}
            handleClose={handleClose}
            onEditQualityReportsEntriesClicks={onEditQualityReportsEntryClicks}
            initialValues={selectedQualityReportEntryArray!}
            qualityEntry={qualityEntry!}
          />
        );
      },
    },
  ];

  const exportOptions = {
    merchantId: 'Merchant ID',
    merchantName: 'Merchant Name',
    placementId: 'Placement ID',
    clicks: 'Clicks',
    originalClicks: 'Original Clicks',
    costOfSale: 'Cost of Sale',
    originalCostOfSale: 'Original Cost of Sale',
    targetCostOfSale: 'Target Cost of Sale',
    originalTargetCostOfSale: 'Original Target Cost of Sale',
    conversionRate: 'Conversion Rate',
    originalConversionRate: 'Original Conversion Rate',
    cpc: 'Cost per Click',
    originalCpc: 'Original Cost per Click',
    revenue: 'Revenue',
    originalRevenue: 'Original Revenue',
    salesCount: 'Sales Count',
    originalSalesCount: 'Original Sales Count',
    sales: 'Sales',
    originalSales: 'Original Sales',
  };

  return (
    <>
      <SimpleToolbar
        modalButtons={modalButtons}
        allowExport
        exportOptions={exportOptions}
        rawExportData={currentData}
        exportFileName="qualityEntries.csv"
      />
      <DisplayOriginalSwitch
        originalToggleChecked={originalToggleChecked}
        handleToggleClick={handleToggleClick}
      />
    </>
  );
};

export default QualityReportsEntriesToolbar;
