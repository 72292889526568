import React, { useContext, useState, useMemo } from 'react';
import SimpleModal from '../../../../../components/SimpleModal';
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  MenuItem,
} from '@mui/material';

import { SingleTrafficContext } from '../../context';

function EditRedirect({ initialData, open, handleClose, onSubmit, currentTableData }) {
  const [loading, setLoading] = useState(false);

  const { trafficData } = useContext(SingleTrafficContext);

  const [value, setValue] = useState(initialData.generatedValue);
  const [manualEdit, setManualEdit] = useState(false);

  const onChange = e => {
    const { value } = e.target;
    setValue(value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit(value);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getTagAutogenerateRule = tag => {
    if (!trafficData) return 0;

    const matchTag = trafficData.parameterOptions.find(item => item.tag === tag);
    return matchTag?.autogenerateRule ?? 0;
  };

  const changeEditMode = e => {
    const { checked } = e.target;
    setManualEdit(checked);
  };

  const { tag, originalValue } = initialData;

  const helperText = useMemo(() => {
    const helperTexts = ['', 'Letters only', 'Numbers only', 'Alphanumeric'];
    const rule = getTagAutogenerateRule(tag);

    return helperTexts[rule];
  }, [tag, trafficData]);

  const menuItems = useMemo(() => {
    const filtered = currentTableData.filter(item => {
      const matchTag = item.tag === initialData.tag;
      return matchTag;
    });

    return filtered.map(item => (
      <MenuItem value={item.generatedValue} key={item.originalValue}>
        {`${item.tag} - ${item.originalValue}`}
      </MenuItem>
    ));
  }, [currentTableData, initialData]);

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit generated value</Typography>
      <Typography variant="body2">
        Future links that have the values{' '}
        <b>{`${initialData.tag} - ${initialData.originalValue}`}</b> will use the new value in the
        text field below
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box fontWeight="500">Edit mode</Box>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={manualEdit} onChange={changeEditMode} />}
            label="Manual Edit"
          />
        </FormGroup>
      </Box>
      <Box fontWeight="500">Details</Box>
      <TextField label="Tag" value={tag} disabled />
      <TextField label="Original value" value={originalValue} disabled />
      {manualEdit ? (
        <TextField
          label="Generated value"
          value={value}
          onChange={onChange}
          helperText={helperText}
        />
      ) : (
        <>
          <TextField label="Parent original value" value={value} onChange={onChange} select>
            {menuItems}
          </TextField>
          <TextField value={value} label="Generated value to copy" disabled />
        </>
      )}
      <Button variant="contained" onClick={handleSubmit} disabled={loading}>
        {loading ? <CircularProgress size={22} /> : 'Edit'}
      </Button>
    </SimpleModal>
  );
}

export default EditRedirect;
