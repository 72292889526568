import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Typography, Box, TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import useHpReports from './useHpReports';

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD'),
    renderCell: (params) => {
      const destination = params.row._id; // Adjust the destination path
      return <Link to={destination}>{moment(params.value).format('YYYY-MM-DD')}</Link>;
    },
  },
];

const HPReportsPage = () => {
  const { getData } = useHpReports();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);

  const init = () => {
    setLoading(true);
    const apiCall = getData();

    (async function () {
      try {
        const res = await apiCall.call();
        const sortedRes = res.sort((a: any, b: any) => moment(b.date).diff(moment(a.date)));
        setRows(sortedRes);
        setFilteredRows(sortedRes);
      } catch (e) {
        console.log('Fail fetching data', e);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    init();
  }, []);

  const handleStartDateChange = (e: any) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: any) => {
    setEndDate(e.target.value);
  };

  const handleFilter = () => {
    if (startDate && endDate && moment(endDate).isBefore(startDate)) {
      alert('End date must be greater than or equal to start date');
      return;
    }

    const filtered = rows.filter((row) => {
      const date = moment(row.date);
      const start = startDate ? moment(startDate) : null;
      const end = endDate ? moment(endDate) : null;

      if (start && end) {
        return date.isBetween(start, end, 'days', '[]');
      }
      if (start) {
        return date.isSameOrAfter(start, 'day');
      }
      if (end) {
        return date.isSameOrBefore(end, 'day');
      }
      return true;
    });

    setFilteredRows(filtered);
  };

  const handleClearFilter = () => {
    setStartDate('');
    setEndDate('');
    setFilteredRows(rows); 
  };

  return (
    <>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          HP Reports
        </Typography>
      </Box>
      <Box my={4} display="flex" alignItems="center">
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={handleStartDateChange}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={handleEndDateChange}
          style={{ marginLeft: '16px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          style={{ marginLeft: '16px' }}
        >
          Filter
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClearFilter}
          style={{ marginLeft: '16px' }}
        >
          Clear Filter
        </Button>
      </Box>
      <Box my={4} style={{ height: '100%', width: '100%' }}>
        <DataGrid getRowId={(r) => r._id} rows={filteredRows} columns={columns} loading={loading} />
      </Box>
    </>
  );
};

export default HPReportsPage;
