import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { Button, TextField, Typography } from '@mui/material';
import { AddAffiliateDto } from '../types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onAddAffiliate: (newAffiliate: AddAffiliateDto) => any;
};

const AddAffiliate = ({ open, handleClose, onAddAffiliate }: Props) => {
  const [values, setValues] = useState({
    name: '',
    prefix: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues(() => ({
      ...values,
      [name]: value,
    }));
  };

  const handlePrefixChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      const { name, value } = e.target;
      setValues(() => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const onSubmit = async () => {
    await onAddAffiliate(values);
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add affiliate</Typography>
      <TextField
        fullWidth
        name="name"
        onChange={handleChange}
        label="Name"
        value={values.name}
      />
      <TextField
        fullWidth
        name="prefix"
        onChange={handlePrefixChange}
        label="Affiliate Tracking Prefix"
        value={values.prefix}
        inputProps={{ maxLength: 3, inputMode: 'numeric', pattern: '[0-9]*' }}
        helperText="Maximum of 3 digits only"
      />
      <Button
        color="primary"
        variant="contained"
        onClick={onSubmit}
        disabled={!values.prefix || !values.name}
        fullWidth
      >
        Submit
      </Button>
    </SimpleModal>
  );
};

export default AddAffiliate;
