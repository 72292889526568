import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import SimpleModal from './SimpleModal';
import TooltipIconButton from './TooltipIconButton';

function ConfirmModal({ iconButton = true, icon, title, children, onConfirm, modalProps = {} }) {
  const [open, setOpen] = useState(false);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  }

  return (
    <>
      {iconButton ? (
        <TooltipIconButton title={title} onClick={openModal} icon={icon} />
      ) : (
        <Button onClick={openModal}>{title}</Button>
      )}
      <SimpleModal open={open} handleClose={closeModal} {...modalProps}>
        {children}
        <Box>
          <Button variant="contained" onClick={handleConfirm} fullWidth>
            Confirm
          </Button>
        </Box>
      </SimpleModal>
    </>
  );
}

export default ConfirmModal;
