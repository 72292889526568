import React, { useState } from 'react';
import SimpleToolbar from '../../../components/SimpleToolbar';
import DateFilter from '../../../components/DateFilter';
import { Range } from 'react-date-range';
import Download from '@mui/icons-material/Download';
import { GridFilterAltIcon } from '@mui/x-data-grid';
import moment from 'moment';

type Props = {
  onDateFilterSubmit: (range: Range) => void;
  initialDateRange: Range;
  actualClicks: any[];
};

const OfferGroupClicksTableToolbar: React.FC<Props> = ({
  onDateFilterSubmit,
  initialDateRange,
  actualClicks,
}) => {
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [dateRange, setDateRange] = useState<Range>(initialDateRange);

  const handleDateFilterSubmit = (selectedRange: Range) => {
    setDateRange(selectedRange);
    onDateFilterSubmit(selectedRange);
    setDateFilterOpen(false);
  };

  const handleDownloadReport = () => {
    const csvHeaders = ['Date', 'Click Id', 'Commission'];
    
    const csvRows = actualClicks.map((row) => {
      const date = moment(row.date).format('YYYY-MM-DD HH:mm:ss');
      const clickId = row.clickid || ''; 
      const commission = row.commission || 0;

      return [date, clickId, commission];
    });

    const csvContent = [
      csvHeaders,
      ...csvRows,
    ]
      .map((e) => e.join(',')) 
      .join('\n'); 

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'actual_report.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const modalButtons = [
    {
      name: 'filter',
      label: 'Date Filter',
      renderIf: true,
      icon: <GridFilterAltIcon />,
      render: (open: boolean, handleClose: () => any) => (
        <DateFilter
          open={open}
          handleClose={handleClose}
          onSubmit={handleDateFilterSubmit}
          initialData={dateRange}
        />
      ),
    },
    {
      name: 'download',
      label: 'Download Actual Report',
      renderIf: true,
      icon: <Download />,
      onClick: handleDownloadReport,
      type: 'button',
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
};

export default OfferGroupClicksTableToolbar;
