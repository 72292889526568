import SimpleToolbar from '../../../components/SimpleToolbar';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ExtensionIcon from '@mui/icons-material/Extension';
import AddSovrn from '../AddSovrn';
import useSovrn from '../useSovrn';
import { AddSovrnDto, SovrnReport } from '../types';
import OverwriteGenerated from '../OverwriteGenerated';
import PatchReport from '../PatchReport';

type Props = {
  onAddSovrnReportDone: (newSovrnReport: SovrnReport) => any;
  selectedRows: string[];
  onFillRevenueDone: (newSovrnReports: SovrnReport[]) => any;
  setLoading: (val: boolean) => any;
};

const SovrnToolbar = ({
  onAddSovrnReportDone,
  selectedRows,
  onFillRevenueDone,
  setLoading,
}: Props) => {
  const { createEmptyReport, fillReports } = useSovrn();

  const onAddSovrnReport = async (values: AddSovrnDto) => {
    const sovrnreport = await createEmptyReport().call(values);
    onAddSovrnReportDone(sovrnreport);
  };

  const onFillRevenue = async (mode: 'fill-zero' | 'fill-all') => {
    setLoading(true);
    try {
      const sovrnreports = await fillReports().call(selectedRows, mode);
      onFillRevenueDone(sovrnreports);
    } catch (error) {}
    setLoading(false);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add empty report',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <AddSovrn
            open={open}
            handleClose={handleClose}
            onSubmit={onAddSovrnReport}
          />
        );
      },
    },
    {
      name: 'overwrite-generated',
      label: 'Overwrite (using generated values)',
      renderIf: selectedRows.length === 1,
      icon: <FileUploadIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <OverwriteGenerated
            open={open}
            handleClose={handleClose}
            id={selectedRows[0]}
          />
        );
      },
    },
    {
      name: 'patch-report',
      label: 'Replace values',
      renderIf: selectedRows.length === 1,
      icon: <ExtensionIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <PatchReport
            open={open}
            handleClose={handleClose}
            id={selectedRows[0]}
          />
        );
      },
    },
    {
      name: 'fill-zero',
      label: 'Fill 0 revenue with random',
      renderIf: selectedRows.length > 0,
      icon: <ShuffleIcon />,
      type: 'button',
      onClick: () => {
        onFillRevenue('fill-zero');
      },
    },
    {
      name: 'fill-all',
      label: 'Fill all revenue with random',
      renderIf: selectedRows.length > 0,
      icon: <AddIcon />,
      type: 'button',
      onClick: () => {
        onFillRevenue('fill-all');
      },
    },
  ];
  return <SimpleToolbar modalButtons={modalButtons}></SimpleToolbar>;
};

export default SovrnToolbar;
