import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { TextField, Typography, Button, MenuItem } from '@mui/material';

function EditTraffic({ initialData, open, handleClose, onSubmit }) {
  const [values, setValues] = useState({
    name: initialData.name,
    network: initialData.network || 'default',
    connectedDsp: initialData.connectedDsp || '',
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit traffic source</Typography>
      <TextField
        value={values.name}
        onChange={onChange}
        fullWidth
        placeholder="Name"
        name="name"
        label="Name"
      />
      <TextField
        select
        name="network"
        placeholder="Network"
        label="Network"
        helperText="This affects how redirect works"
        value={values.network}
        onChange={onChange}
      >
        <MenuItem value="default">Default</MenuItem>
        <MenuItem value="adnet">Adnet</MenuItem>
      </TextField>
      <TextField
        select
        name="connectedDsp"
        placeholder="Connected DSP"
        label="Connected DSP"
        onChange={onChange}
        helperText="This affects where the wins will be recorded"
        value={values.connectedDsp}
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value="dsp24">DSP 24</MenuItem>
        <MenuItem value="rapid">Rapid</MenuItem>
      </TextField>
      <Button variant="contained" onClick={() => onSubmit(values)}>
        Edit
      </Button>
    </SimpleModal>
  );
}

export default EditTraffic;
