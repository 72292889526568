import { TextField, Typography, Button, MenuItem } from '@mui/material';
import SimpleModal from '../../../components/SimpleModal';
import { useState } from 'react';

export default function AddTraffic({ open, handleClose, onSubmit }) {
  const [values, setValues] = useState({
    name: '',
    network: 'default',
    connectedDsp: '',
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add traffic source</Typography>
      <TextField
        name="name"
        value={values.name}
        onChange={onChange}
        fullWidth
        placeholder="Traffic source name"
        label="Name"
      />
      <TextField
        select
        name="network"
        placeholder="Network"
        label="Network"
        helperText="This affects how redirect works"
        onChange={onChange}
        value={values.network}
      >
        <MenuItem value="default">Default</MenuItem>
        <MenuItem value="adnet">Adnet</MenuItem>
      </TextField>
      <TextField
        select
        name="connectedDsp"
        placeholder="Connected DSP"
        label="Connected DSP"
        onChange={onChange}
        helperText="This affects where the wins will be recorded"
        value={values.connectedDsp}
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value="dsp24">DSP 24</MenuItem>
        <MenuItem value="rapid">Rapid</MenuItem>
      </TextField>
      <Button variant="contained" onClick={() => onSubmit(values)}>
        Add
      </Button>
    </SimpleModal>
  );
}
