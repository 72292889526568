import { useState, useEffect, useMemo, useContext } from 'react';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { Box } from '@mui/material';

import { QualityEntries, QualityEntry } from '../types';
import ConfirmModal from '../../../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import useAffiliate from '../../../useAffiliate';
import QualityReportsEntriesToolbar from '../QualityReportsEntriesToolbar';
import { useLocation } from 'react-router-dom';
import useOriginalReports from '../useOriginalReports';

type Props = {
  qualityEntry: QualityEntry | null;
};

const TOTAL_ROWS = {
  _id: 'total_row',
  clicks: 0,
  originalClicks: 0,
  revenue: 0,
  originalRevenue: 0,
};

const QualityReportsEntriesTable = ({ qualityEntry }: Props) => {
  const location = useLocation();
  const { prefix } = location.state ?? {};

  const { deleteSingleQualityEntry } = useAffiliate();
  const { getOriginalReports } = useOriginalReports();

  const [originalColumns, setOriginalColumns] = useState({
    originalClicks: false,
    originalCostOfSale: false,
    originalTargetCostOfSale: false,
    originalConversionRate: false,
    originalCpc: false,
    originalRevenue: false,
    originalSales: false,
    originalSalesCount: false,
  });
  const [originalToggleChecked, setOriginalToggleChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [hasOriginalReports, setHasOriginalReports] = useState(false);
  const [affiliatePrefix, setAffiliatePrefix] = useState<string | null>('');
  const [rows, setRows] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(TOTAL_ROWS);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const generateRows = () => {
    return (
      qualityEntry?.qualityEntries.map((entry) => ({
        _id: entry._id,
        merchantId: entry.merchantId,
        merchantName: entry.merchantName,
        placementId: entry.placementId,
        clicks: entry.clicks,
        costOfSale: entry.costOfSale,
        targetCostOfSale: entry.targetCostOfSale,
        conversionRate: entry.conversionRate,
        cpc: entry.cpc,
        revenue: entry.revenue,
        sales: entry.sales,
        salesCount: entry.salesCount,
      })) || []
    );
  };

  const initializeOriginalReports = () => {
    (async function () {
      try {
        setLoading(true);
        if (!affiliatePrefix) return;

        const originalRows = await getOriginalReports().call(
          qualityEntry?.date,
          affiliatePrefix
        );

        if (originalRows !== null) {
          setRows((prevRows) => {
            const updatedRows = prevRows.map((existingRow) => {
              const matchingOriginalRow = originalRows?.find(
                (originalRow: any) =>
                  originalRow.placementId === existingRow.placementId &&
                  originalRow.merchantId === existingRow.merchantId
              );

              return matchingOriginalRow
                ? { ...existingRow, ...matchingOriginalRow }
                : existingRow;
            });

            return updatedRows;
          });
        }
      } catch (error) {
        console.log('error initializing original rows');
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    if (originalToggleChecked && !hasOriginalReports) {
      initializeOriginalReports();
      setHasOriginalReports(true);
    }
  }, [originalToggleChecked, hasOriginalReports]);

  useEffect(() => {
    setRows(generateRows());
  }, [qualityEntry]);

  useEffect(() => {
    if (prefix !== undefined) {
      localStorage.setItem(`${qualityEntry?._id}`, prefix);
    }
    setRows(generateRows());
  }, [qualityEntry]);

  useEffect(() => {
    const affiliatePrefix = localStorage.getItem(`${qualityEntry?._id}`);
    setAffiliatePrefix(affiliatePrefix);
  }, []);

  const calculateTotalRows = () => {
    const totalRow = rows.reduce(
      (acc, entry) => {
        acc.clicks += entry.clicks;
        acc.revenue += entry.revenue;
        acc.originalClicks += entry.originalClicks || 0;
        acc.originalRevenue += entry.originalRevenue || 0;
        return acc;
      },
      { ...TOTAL_ROWS }
    );

    totalRow.revenue = parseFloat(totalRow.revenue.toFixed(2));
    totalRow.originalRevenue = parseFloat(totalRow.originalRevenue.toFixed(2));

    return totalRow;
  };

  useEffect(() => {
    setTotalRows(calculateTotalRows());
  }, [rows]);

  const selectedQualityReportEntry = useMemo(() => {
    if (selectedRow.length <= 1) {
      return rows.find((item) => item._id === selectedRow[0]);
    } else if (selectedRow.length > 1) {
      return rows.filter((item) => selectedRow.includes(item._id));
    }
  }, [rows, selectedRow]);

  const onDeleteQualityEntry = async (
    qualityEntriesId: QualityEntries['_id']
  ) => {
    try {
      setLoading(true);
      const newQualityEntries = await deleteSingleQualityEntry().call(
        qualityEntry?._id,
        qualityEntriesId
      );

      setRows(
        rows.filter((report) =>
          newQualityEntries.some(
            (newReport: any) => newReport._id === report._id
          )
        )
      );
    } catch (error) {
      console.log('Error deleting quality entry: ', error);
    } finally {
      setLoading(false);
    }
  };

  const onEditQualityReportsEntryDone = (
    newQualityReportEntry: QualityEntries[]
  ) => {
    const newQualityReportEntries = rows.map((item) => {
      const matchingEntry = newQualityReportEntry.find(
        (updatedEntry) => updatedEntry._id === item._id
      );

      return matchingEntry ? { ...item, ...matchingEntry } : item;
    });
    setRows(newQualityReportEntries);
  };

  const handleToggleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('switch Event >>>', event);

    setOriginalToggleChecked(event.target.checked);
    setOriginalColumns({
      originalClicks: event.target.checked,
      originalCostOfSale: event.target.checked,
      originalTargetCostOfSale: event.target.checked,
      originalConversionRate: event.target.checked,
      originalCpc: event.target.checked,
      originalRevenue: event.target.checked,
      originalSales: event.target.checked,
      originalSalesCount: event.target.checked,
    });
  };

  const tableCols = generateTableCols(onDeleteQualityEntry);

  return (
    <Box>
      <DataGrid
        autoHeight
        checkboxSelection
        disableRowSelectionOnClick
        isRowSelectable={(params) => params.row._id !== 'total_row'}
        rows={[totalRows, ...rows]}
        columns={tableCols}
        columnVisibilityModel={originalColumns}
        getRowId={(r) => r._id}
        onRowSelectionModelChange={setSelectedRow}
        loading={loading}
        slots={{
          toolbar: () => (
            <>
              <Box display="flex">
                <QualityReportsEntriesToolbar
                  onEditQualityReportsEntryDone={onEditQualityReportsEntryDone}
                  onEditQualityReportsEntryClicksDone={
                    onEditQualityReportsEntryDone
                  }
                  selectedQualityReportEntry={selectedQualityReportEntry}
                  selectedQualityReportEntryArray={selectedQualityReportEntry}
                  qualityEntry={qualityEntry!}
                  currentData={rows}
                  originalToggleChecked={originalToggleChecked}
                  handleToggleClick={handleToggleClick}
                />
              </Box>
            </>
          ),
        }}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            overflow: 'unset',
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
          },
        })}
      />
    </Box>
  );
};

const generateTableCols = (onDelete: Function) => {
  const tableCols: GridColDef[] = [
    {
      headerName: 'Merchant ID',
      field: 'merchantId',
      flex: 1,
    },
    {
      headerName: 'Merchant Name',
      field: 'merchantName',
      flex: 1,
    },
    {
      headerName: 'Placement ID',
      field: 'placementId',
      flex: 1,
    },
    {
      headerName: 'Clicks',
      field: 'clicks',
      flex: 1,
    },
    {
      headerName: 'Original Clicks',
      field: 'originalClicks',
      flex: 1,
    },
    {
      headerName: 'Cost of Sale',
      field: 'costOfSale',
      flex: 1,
    },
    {
      headerName: 'Original Cost of Sale',
      field: 'originalCostOfSale',
      flex: 1,
    },
    {
      headerName: 'Target Cost of Sale',
      field: 'targetCostOfSale',
      flex: 1,
    },
    {
      headerName: 'Original Target Cost of Sale',
      field: 'originalTargetCostOfSale',
      flex: 1,
    },
    {
      headerName: 'Conversion Rate',
      field: 'conversionRate',
      flex: 1,
    },
    {
      headerName: 'Original Conversion Rate',
      field: 'originalConversionRate',
      flex: 1,
    },
    {
      headerName: 'Sales Count',
      field: 'salesCount',
      flex: 1,
    },
    {
      headerName: 'Original Sales Count',
      field: 'originalSalesCount',
      flex: 1,
    },
    {
      headerName: 'Sales',
      field: 'sales',
      flex: 1,
    },
    {
      headerName: 'Original Sales',
      field: 'originalSales',
      flex: 1,
    },
    {
      headerName: 'Cost per Click',
      field: 'cpc',
      flex: 1,
    },
    {
      headerName: 'Original Cost per Click',
      field: 'originalCpc',
      flex: 1,
    },
    {
      headerName: 'Revenue',
      field: 'revenue',
      flex: 1,
    },
    {
      headerName: 'Original Revenue',
      field: 'originalRevenue',
      flex: 1,
    },
    {
      headerName: 'Action',
      field: '_id',
      renderCell: (params) => {
        if (params.row._id === 'total_row') {
          return null;
        }

        return (
          <ConfirmModal
            iconButton
            icon={<DeleteIcon />}
            title="Delete quality report entry"
            onConfirm={() => {
              onDelete(params.row._id);
            }}
          >
            Are you sure you want to delete this quality report entry? This
            process is irreversible
          </ConfirmModal>
        );
      },
    },
  ];

  return tableCols;
};

export default QualityReportsEntriesTable;
