import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { MerchantFeedData } from '../types';

import useFeeds from '../useFeeds';

import { Box, Button, Typography, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MerchantDetails from './MerchantDetails';
import RegenerationHistory from '../../../components/RegenerationHistory';

export default function MerchantFeeds() {
  const [merchant, setMerchant] = useState<MerchantFeedData>();
  const [loading, setLoading] = useState(true);
  const { getFeedDetails } = useFeeds();

  const params = useParams();

  const init = () => {
    const apiCall = getFeedDetails();

    (async function () {
      try {
        const res = await apiCall.call(params.merchantId);
        setMerchant(res);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  const onRefresh = async () => {
    const apiCall = getFeedDetails();

    try {
      const res = await apiCall.call(params.merchantId);
      setMerchant(res);
    } catch (error) {}
  };

  useEffect(() => {
    return init();
  }, []);

  const handleDownload = () => {
    const downloadLink = merchant?.downloadLink;

    if (!downloadLink) return;

    const link = document.createElement('a');
    link.href = downloadLink;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <Box mt={4}>
      <Box mb={3}>
        <Link to="/merchants">
          <Button startIcon={<ArrowBackIcon />}>Back</Button>
        </Link>
      </Box>
      <Typography variant="h4" gutterBottom>
        {merchant?.merchantName}
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <MerchantDetails
            merchant={merchant}
            handleDownload={handleDownload}
            onRefresh={onRefresh}
          />
          <RegenerationHistory />
        </>
      )}
    </Box>
  );
}
