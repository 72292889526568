import React from 'react';
import CommissionDeduction from '../CommissionDeduction';

type Props = {};

const OfferGroupSettings = (props: Props) => {
  return <CommissionDeduction />;
};

export default OfferGroupSettings;
