import { Box, Button, Typography } from '@mui/material';

import SingleSovrnTable from './SingleSovrnTable';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SingleSovrnProvider, { SingleSovrnContext } from './context';
import { useContext } from 'react';

const MainSection = () => {
  const { report } = useContext(SingleSovrnContext);
  const navigate = useNavigate();

  const back = () => {
    navigate('/sovrn');
  };

  const label = report ? `${report.tag} > ${report.date}` : '';

  return (
    <Box mt={2}>
      <Box mb={2}>
        <Button onClick={back} startIcon={<ArrowBackIcon />}>
          Back
        </Button>
      </Box>
      <Typography variant="h4" gutterBottom>
        {label}
      </Typography>
      <SingleSovrnTable />
    </Box>
  );
};

const SingleSovrnReportPage = () => {
  return (
    <SingleSovrnProvider>
      <MainSection />
    </SingleSovrnProvider>
  );
};

export default SingleSovrnReportPage;
