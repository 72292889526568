import React, { ChangeEvent, useContext, useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { DatePicker } from '@mui/x-date-pickers';
import { Alert, Box, MenuItem, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import LoadingButton from '../../../components/LoadingButton';
import { SovrnContext } from '../context';
import { AddSovrnDto } from '../types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (dateString: AddSovrnDto) => any;
};

const AddSovrn = ({ open, handleClose, onSubmit }: Props) => {
  const [values, setValues] = useState({
    reportTag: '',
    trafficId: '',
    filterTag: '',
    account: '',
    endpoint: '',
  });
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [loading, setLoading] = useState(false);

  const { trafficSources } = useContext(SovrnContext);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const month = (date.get('month') + 1).toString().padStart(2, '0');
      const day = date.get('date').toString().padStart(2, '0');
      const year = date.get('year').toString().padStart(2, '0');

      const utcstring = `${year}-${month}-${day}`;

      await onSubmit({ ...values, date: utcstring });
    } catch (error) {}
    setLoading(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const selectedTraffic = trafficSources.find(
    (t) => t._id === values.trafficId
  );

  const selectedTrafficOptions = selectedTraffic
    ? selectedTraffic.parameterOptions.map((p: any) => (
        <MenuItem value={p.tag}>{p.tag}</MenuItem>
      ))
    : [];

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6" gutterBottom>
        Add empty report
      </Typography>
      <TextField
        name="account"
        label="Account"
        select
        onChange={onChange}
        value={values.account}
      >
        <MenuItem value="sovrn">Sovrn (Sovrn CNX account)</MenuItem>
        <MenuItem value="sean">Sean (Sean@ CNX account)</MenuItem>
      </TextField>
      <TextField
        name="reportTag"
        label="Label/Tag"
        onChange={onChange}
        value={values.reportTag}
      />
      <TextField
        name="trafficId"
        label="Traffic Source"
        select
        value={values.trafficId}
        onChange={onChange}
      >
        {trafficSources.map((t) => (
          <MenuItem value={t._id}>{t.name}</MenuItem>
        ))}
      </TextField>

      <TextField
        name="filterTag"
        label="Prefix"
        select
        helperText="This is the prefix to filter the correct campaign Ids"
        disabled={!values.trafficId}
        value={values.filterTag}
        onChange={onChange}
      >
        {selectedTrafficOptions}
      </TextField>
      <TextField
        name="endpoint"
        label="Endpoint"
        select
        helperText="The reporting endpoint to send this report"
        value={values.endpoint}
        onChange={onChange}
      >
        <MenuItem value="xmptrk">XMPTRK</MenuItem>
        <MenuItem value="eagleview">Eagleview</MenuItem>
      </TextField>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DatePicker
          label="Date"
          value={date}
          onChange={(d) => setDate(d as any)}
        />
      </LocalizationProvider>
      <Alert severity="info">The datepicker timezone is PST (UTC - 7)</Alert>
      <LoadingButton
        variant="contained"
        onClick={handleSubmit}
        loading={loading}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default AddSovrn;
