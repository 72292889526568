import { Button } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { useState } from 'react';
import { CSVLink } from 'react-csv';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function SimpleToolbar({
  modalButtons = [],
  allowExport = false,
  exportOptions = {},
  rawExportData = [],
  exportFileName = 'tableData.csv',
}) {
  const [modalStates, setModalStates] = useState(init(modalButtons));
  const [exportData, setExportData] = useState([]);

  const handleModalVisibility = (name, isVisible) => {
    const matchModal = modalStates.find((item) => item.name === name);

    matchModal.open = isVisible;

    setModalStates(modalStates.slice());
  };

  const handleOpenModal = (name) => () => {
    handleModalVisibility(name, true);
  };

  const handleCloseModal = (name) => () => handleModalVisibility(name, false);

  const formatExportData = (headerMap, data) => {
    const headerEntries = Object.entries(headerMap);

    const headerLabels = headerEntries.map(([key, options]) => {
      if (typeof options === 'string') return options;
      return options.label;
    });

    const formattedData = data.map((item) => {
      const row = headerEntries.map(([key, options]) => {
        const itemValue = item[key];

        const toTransform = Boolean(options.formatter);

        if (toTransform) return options.formatter(itemValue, item);

        return itemValue;
      });

      return row;
    });

    formattedData.unshift(headerLabels);
    return formattedData;
  };

  const handleExport = (e, done) => {
    const formattedData = formatExportData(exportOptions, rawExportData);
    setExportData(formattedData);
    done(true);
  };

  return (
    <>
      <GridToolbarContainer p={1} display="flex" gap={1}>
        {modalStates.map((item) => {
          const toRender = Boolean(item.renderIf);

          if (!toRender) return null;

          const buttonProps = {
            onClick: (e) => {
              if (item.onClick) return item.onClick(e);
              return handleOpenModal(item.name)();
            },
            startIcon: item.icon,
          };

          return <Button {...buttonProps}>{item.label}</Button>;
        })}
        {allowExport && (
          <CSVLink
            data={exportData}
            filename={exportFileName}
            asyncOnClick
            onClick={handleExport}
          >
            <Button startIcon={<FileDownloadIcon />}>Export</Button>
          </CSVLink>
        )}
      </GridToolbarContainer>
      {modalStates.map((item) => {
        const handleClose = handleCloseModal(item.name);

        const type = item.type || 'modal';
        const toRender = Boolean(item.renderIf) && type === 'modal';

        if (!toRender) return null;

        return item.render(item.open, handleClose);
      })}
    </>
  );
}

const init = (requestedButtons) => {
  return requestedButtons.map((item) => {
    return {
      ...item,
      open: false,
    };
  });
};
