import { Modal, Box } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  minWidth: 450,  
  bgcolor: 'background.paper',
  boxShadow: 4,
  p: 4,
  maxHeight: '100%',
  overflow: 'scroll'
};

export default function SimpleModal({
  open,
  handleClose,
  containerStyle = {},
  children,
  modalProps = {},
  ...props
}) {
  return (
    <Modal open={open} onClose={handleClose} {...modalProps}>
      <Box
        sx={{ ...style, ...containerStyle }}
        display="flex"
        gap={2}
        flexDirection="column"
        {...props}
      >
        {children}
      </Box>
    </Modal>
  );
}
