import React, { useState, useEffect, useMemo } from 'react';
import { Box, Card, Alert } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { BlacklistedProduct } from '../types';
import ProductToolbar from '../ProductToolbar';
import ConfirmModal from '../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import useBlacklistProducts from '../useBlacklistProducts';

function BlacklistedProductsTable() {
  const { getBlacklistedProducts, deleteBlacklistedProduct } =
    useBlacklistProducts();
  const [products, setProducts] = useState<BlacklistedProduct[]>([]);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const selectedProduct = useMemo(() => {
    return products.find((product) => product._id === selectedRow[0]);
  }, [products, selectedRow]);

  const init = () => {
    const apiCall = getBlacklistedProducts();
    setLoading(true);

    (async function () {
      try {
        const products = await apiCall.call();
        setProducts(products);
      } catch (error) {
        console.log('Fail fetching products', error);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  const onAddProductDone = (newProduct: BlacklistedProduct) => {
    setProducts([newProduct, ...products]);
  };

  const onEditProductDone = (newProduct: BlacklistedProduct) => {
    const newProducts = products.map((product) => {
      if (product._id === newProduct._id) return newProduct;
      return product;
    });

    setProducts(newProducts);
  };

  const onDeleteProduct = async (productId: BlacklistedProduct['_id']) => {
    try {
      setLoading(true);
      await deleteBlacklistedProduct().call(productId);
      const newProducts = products.filter(
        (product) => product._id !== productId
      );
      setProducts(newProducts);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return init();
  }, []);

  const tableCols = generateTableCols(onDeleteProduct);

  return (
    <Card>
      <DataGrid
        autoHeight
        rows={products}
        columns={tableCols}
        getRowId={(r) => r._id}
        onRowSelectionModelChange={setSelectedRow}
        loading={loading}
        slots={{
          toolbar: () => (
            <ProductToolbar
              onAddProductDone={onAddProductDone}
              onEditProductDone={onEditProductDone}
              selectedProduct={selectedProduct}
            />
          ),
        }}
      />
    </Card>
  );
}

const generateTableCols = (onDelete: Function) => {
  const tableCols: GridColDef[] = [
    {
      headerName: 'Product Id',
      field: 'productId',
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <ConfirmModal
              iconButton
              icon={<DeleteIcon />}
              title="Delete product id"
              onConfirm={() => {
                onDelete(params.row._id);
              }}
            >
              <Alert severity="warning">
                Are you sure you want to delete this product id? This process is
                irreversible
              </Alert>
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  return tableCols;
};

export default BlacklistedProductsTable;
