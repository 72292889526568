import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RedirectTable from './RedirectTable';
import ParameterTable from './ParameterTable';
import SingleTrafficProvider from './context';
import { DisabledByDefault } from '@mui/icons-material';

function SingleTraffic() {
  const params = useParams();

  return (
    <SingleTrafficProvider sourceId={params.sourceId}>
      <Box mt={4}>
        <Box mb={3} display="flex" gap={3} alignItems="center">
          <Link to="/traffic">
            <Button startIcon={<ArrowBackIcon />}>Back</Button>
          </Link>
          <Link to="block">
            <Button startIcon={<DisabledByDefault />}>Block Rules</Button>
          </Link>
        </Box>
        <ParameterTable />
        <RedirectTable />
      </Box>
    </SingleTrafficProvider>
  );
}

export default SingleTraffic;
