import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import SimpleToolbar from '../../../components/SimpleToolbar';
import AddProduct from '../AddProduct';
import EditProduct from '../EditProduct';
import useBlacklistProducts from '../useBlacklistProducts';
import { BlacklistedProduct } from '../types';

type Props = {
  onAddProductDone: (newBlacklistedProduct: BlacklistedProduct) => any;
  onEditProductDone: (newBlacklistedProduct: BlacklistedProduct) => any;
  selectedProduct?: BlacklistedProduct;
};

const ProductToolbar = ({
  onAddProductDone,
  onEditProductDone,
  selectedProduct,
}: Props) => {
  const { addBlacklistedProduct, editBlacklistedProduct } =
    useBlacklistProducts();

  const onAddProduct = async (productDto: BlacklistedProduct) => {
    const newProduct = await addBlacklistedProduct().call(productDto);
    onAddProductDone(newProduct);
  };

  const onEditProduct = async (
    productId: BlacklistedProduct['_id'],
    productDto: BlacklistedProduct
  ) => {
    const newProduct = await editBlacklistedProduct().call(
      productId,
      productDto
    );
    onEditProductDone(newProduct);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <AddProduct
            open={open}
            handleClose={handleClose}
            onAddProduct={onAddProduct}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: !!selectedProduct,
      icon: <EditIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <EditProduct
            open={open}
            handleClose={handleClose}
            onEditProduct={onEditProduct}
            initialValues={selectedProduct!}
          />
        );
      },
    },
  ];
  return <SimpleToolbar modalButtons={modalButtons} />;
};

export default ProductToolbar;
