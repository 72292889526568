import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

type Props = {
  value: string | boolean | number;
  onChange: (newValue: string) => any;
  fields: { value: any; label: string }[];
  label: string;
  helperText?: string;
  horizontal?: boolean;
};

const SimpleRadioGroup = ({
  helperText,
  onChange,
  value,
  fields,
  label,
  horizontal = false,
}: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = e.target;
    onChange(newValue);
  };

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      {helperText && (
        <Typography variant="caption" gutterBottom>
          {helperText}
        </Typography>
      )}
      <RadioGroup value={value} onChange={handleChange} row={horizontal}>
        {fields.map((f) => {
          return (
            <FormControlLabel
              key={f.value}
              value={f.value}
              control={<Radio />}
              label={f.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default SimpleRadioGroup;
