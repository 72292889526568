import { ArrowBack } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  navigation: {
    location: string;
    label: string;
    startIcon?: React.ReactNode;
  }[];
  relative?: 'path' | 'route';
};

const NavWithBack = ({ navigation, relative = 'route' }: Props) => {
  const navigate = useNavigate();

  const back = () => navigate('..', { relative });

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      <Button startIcon={<ArrowBack />} onClick={back}>
        Back
      </Button>
      {navigation.map((n) => {
        const props = {
          onClick: () => navigate(n.location),
          startIcon: n.startIcon,
        };
        return <Button {...props}>{n.label}</Button>;
      })}
    </Box>
  );
};

export default NavWithBack;
