import { Box } from '@mui/material';
import OfferGroupTable from './OfferGroupTable';

const OfferGroupPage = () => {
  return (
    <Box mt={4}>
      <OfferGroupTable />
    </Box>
  );
};

export default OfferGroupPage;
