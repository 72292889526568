import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

const DialogBox = ({ openDialog, handleClose }) => {
  return (
    <div>
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          Feed regeneration request was successful. A notification will be
          displayed once feed regeneration is done
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogBox;
