import React from 'react';
import SimpleToolbar from '../../../components/SimpleToolbar';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import AddDomain from '../AddDomain';
import EditDomain from '../EditDomain';
import useDomain from '../useDomain';

type Props = {
  selectedRowData: any;
  onAddDomainDone: (newDomain: any) => any;
  onEditDomainDone: (newDomain: any) => any;
};

function DomainToolbar({ selectedRowData, onAddDomainDone, onEditDomainDone }: Props) {
  const { addDomain, editDomain } = useDomain();

  const onAddDomain = async (newDomain: any) => {
    const domain = await addDomain().call(newDomain);
    onAddDomainDone(domain);
  };

  const onEditDomain = async (newDomain: any) => {
    const domain = await editDomain().call(selectedRowData._id, {
      ...newDomain,
      active: Boolean(newDomain.active),
    });
    onEditDomainDone(domain);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: any) => {
        return <AddDomain open={open} handleClose={handleClose} onSubmit={onAddDomain} />;
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRowData),
      icon: <EditIcon />,
      render: (open: boolean, handleClose: any) => {
        return (
          <EditDomain
            open={open}
            handleClose={handleClose}
            onSubmit={onEditDomain}
            initialData={selectedRowData}
          />
        );
      },
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
}

export default DomainToolbar;
