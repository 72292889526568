import SimpleModal from '../../../components/SimpleModal';
import { Button, Box } from '@mui/material';
export default function DeleteTraffic({
  open,
  initialData,
  handleClose,
  onSubmit,
}) {
  return (
    <>
      <SimpleModal open={open} handleClose={handleClose}>
        <Box>
          Do you want to delete this traffic source?
          <Button
            variant="contained"
            onClick={() => onSubmit(initialData, handleClose)}
            fullWidth
          >
            Confirm
          </Button>
        </Box>
      </SimpleModal>
    </>
  );
}
