import React from 'react';
import OfferGroupSources from '../OfferGroupSources';
import SingleOfferGroupTable from '../SingleOfferGroupTable';

const CustomOffer = () => {
  return (
    <>
      <OfferGroupSources />
      <SingleOfferGroupTable />
    </>
  );
};

export default CustomOffer;
