import SimpleToolbar from '../../../components/SimpleToolbar';
import AddIcon from '@mui/icons-material/Add';
import Download from '@mui/icons-material/Download';
import useCustomOffer from '../useCustomOffers';
import ImportCustomOffer from '../ImportCustomOffer';
import { useContext } from 'react';
import { OfferGroupContext } from '../context';
import { AppRegistration } from '@mui/icons-material';

type Props = {
  onDownloadTemplate: () => any;
  onImportDone: (dto: any) => any;
  onExport: () => any;
  selectedRows?: any[];
  onBulkEdit: () => any;
};

const SingleOfferGroupToolbar = ({
  onDownloadTemplate,
  onImportDone,
  onExport,
  selectedRows = [],
  onBulkEdit,
}: Props) => {
  const api = useCustomOffer();
  const { offerGroup } = useContext(OfferGroupContext);

  const onSubmitCSV = async (dto: any) => {
    const result = await api.importCustomOffers().call(offerGroup?._id, dto);
    onImportDone(result);
  };

  const buttons = [
    {
      name: 'template',
      label: 'Download template',
      renderIf: true,
      icon: <AddIcon />,
      onClick: onDownloadTemplate,
      type: 'button',
    },
    {
      name: 'import',
      label: 'Import',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: any) => {
        return (
          <ImportCustomOffer
            open={open}
            handleClose={handleClose}
            onSubmit={onSubmitCSV}
          />
        );
      },
    },
    {
      name: 'export',
      label: 'Export',
      renderIf: true,
      icon: <Download />,
      onClick: onExport,
      type: 'button',
    },
    {
      name: 'bulkEdit',
      label: 'Bulk Edit',
      renderIf: selectedRows.length > 0 ? true : false,
      icon: <AppRegistration />,
      onClick: onBulkEdit,
      type: 'button',
    },
  ];
  return <SimpleToolbar modalButtons={buttons}></SimpleToolbar>;
};

export default SingleOfferGroupToolbar;
