import React from 'react';
import { Box } from '@mui/material';
import TrafficTable from './TrafficTable';

function TrafficSourcePage() {
  return (
    <Box mt={4}>
      <TrafficTable />
    </Box>
  );
}

export default TrafficSourcePage;
