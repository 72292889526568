import useApi from "../../hooks/useApi";

const API_URL = '/api/postback';

export default function usePostback() {
  const { api:apiClient, createApiCall } = useApi();

  const getPostbacks = createApiCall(async ({ signal }) => {
    const {
      data: { postbacks }
    } = await apiClient.get(API_URL, { signal });

    return postbacks;
  });

  const addPostback = createApiCall(async ({ signal }, newPostback) => {
    const {
      data: { postback }
    } = await apiClient.post(API_URL, newPostback, { signal });

    return postback;
  });

  const deletePostback = createApiCall(async ({ signal }, postbackId) => {
    await apiClient.delete(`${API_URL}/${postbackId}`, { signal });
  });

  const editPostback = createApiCall(async ({ signal }, postbackId, newPostback) => {
    const {
      data: { postback },
    } = await apiClient.put(`${API_URL}/${postbackId}`, newPostback, { signal });

    return postback;
  });

  return { getPostbacks, addPostback, deletePostback, editPostback };
};