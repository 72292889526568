


import { Box } from '@mui/material'
import AffiliateTable from './AffiliateTable'

type Props = {}

const AffiliatePage = (props: Props) => {
  return (
    <Box mt={4}><AffiliateTable /></Box>
  )
}

export default AffiliatePage