import { Button } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';

import React from 'react';

type Props = {
  handleOpenDateRangePicker: () => any;
};

function MonthlyReportDateFilter({ handleOpenDateRangePicker }: Props) {
  return (
    <>
      <GridToolbarContainer>
        <Button
          startIcon={<FilterListIcon />}
          onClick={handleOpenDateRangePicker}
        >
          Filters
        </Button>
      </GridToolbarContainer>
    </>
  );
}

export default MonthlyReportDateFilter;
