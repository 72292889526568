import { useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Box,
} from '@mui/material';
import CellFilter from './FilterCell';
import { ArrowUpward, ArrowDownward, Search } from '@mui/icons-material';

function DynamicTable({
  columns,
  originalData,
  filteredData,
  renderCell,
  selectedRows,
  setSelectedRows,
  onAdd,
  onEdit,
  onExport,
  filters,
  setFilters,
}) {
  const [sorting, setSorting] = useState({
    column: '',
    order: 'asc',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of items to display per page

  const handleSortClick = (column) => {
    console.log('handleSortClick > column: ', column);

    if (column === '_id' || column === 'actions') {
      // Disable sorting for the "action" column
      return;
    }
    setSorting((prevSorting) => ({
      column,
      order:
        prevSorting.column === column && prevSorting.order === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const handleRowClick = (_id) => {
    const selectedIndex = selectedRows.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedRows, _id];
    } else {
      newSelected = selectedRows.filter((id) => id !== _id);
    }

    setSelectedRows(newSelected);
  };

  const sortedOffers = [...filteredData].sort((a, b) => {
    // console.log('sortedOffers > sorting: ', sorting);
    // console.log('sortedOffers > a: ', a);
    // console.log('sortedOffers > b: ', b);
    const column = sorting.column;
    const order = sorting.order === 'asc' ? 1 : -1;

    if (a[column] < b[column]) return -1 * order;
    if (a[column] > b[column]) return 1 * order;
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    console.log('changed');
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the start and end indices for the current page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const displayedItems = sortedOffers.slice(startIndex, endIndex);

  // console.log('DynamicTable > sortedOffers: ', sortedOffers);
  // console.log('DynamicTable > displayedItems: ', displayedItems);

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '75vh' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ verticalAlign: 'initial' }}>
                <TableCell key="select-all" sx={{ minWidth: 50, maxWidth: 50 }}>
                  <Checkbox
                    onChange={() => {
                      if (
                        selectedRows.length ===
                        filteredData.map((item) => item._id).length
                      ) {
                        setSelectedRows([]);
                      } else {
                        setSelectedRows(filteredData.map((item) => item._id));
                      }
                    }}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length <
                        filteredData.map((item) => item._id).length
                    }
                    checked={
                      selectedRows.length ===
                      filteredData.map((item) => item._id).length
                    }
                  />
                </TableCell>
                {columns.map((column) => {
                  const isSortedColumn = sorting.column === column.field;
                  const isSortAscending = sorting.order === 'asc';

                  return (
                    <TableCell key={column.field}>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap={2}
                          onClick={() => handleSortClick(column.field)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <Box sx={{ minWidth: '105px', maxWidth: '120px' }}>
                            {column.name}
                          </Box>
                          <Box
                            visibility={isSortedColumn ? 'visible' : 'hidden'}
                          >
                            {isSortAscending ? (
                              <ArrowUpward fontSize="small" />
                            ) : (
                              <ArrowDownward fontSize="small" />
                            )}
                          </Box>
                        </Box>
                        <div className="header-options">
                          {column.filter ? (
                            <CellFilter
                              filters={filters}
                              setFilters={setFilters}
                              column={{
                                field: column.field,
                                name: column.name,
                              }}
                              rows={Array.from(
                                new Set(
                                  originalData
                                    .filter(
                                      (c) =>
                                        c[column.field] !== null &&
                                        c[column.field] !== undefined
                                    )
                                    .map((e) => {
                                      if (column.renderFilter) {
                                        return column.renderFilter(
                                          e[column.field]
                                        );
                                      } else {
                                        return e[column.field];
                                      }
                                    })
                                )
                              )}
                            />
                          ) : null}
                        </div>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedItems.map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell key="select" sx={{ minWidth: 50, maxWidth: 50 }}>
                    <Checkbox
                      onChange={() => handleRowClick(item._id)}
                      checked={selectedRows.indexOf(item._id) !== -1}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.field}
                      sx={{ minWidth: column.width, maxWidth: column.width }}
                    >
                      {renderCell(column.field, item)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={sortedOffers.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default DynamicTable;
