import { createContext, useEffect, useState } from 'react';
import useTraffic from '../traffic/useTraffic';

export type SovrnContextValues = {
  trafficSources: any[];
  loading: boolean;
};

export const SovrnContext = createContext<SovrnContextValues>({} as any);

type Props = {
  children: React.ReactNode;
};

const SovrnProvider = ({ children }: Props) => {
  const [trafficSources, setTrafficSources] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { getTraffic } = useTraffic();

  const init = () => {
    const api = getTraffic();

    (async () => {
      setLoading(true);
      try {
        const res = await api.call();
        setTrafficSources(res);
      } catch (error) {}
      setLoading(false);
    })();

    return api.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const vals = {
    trafficSources,
    loading,
  };

  return <SovrnContext.Provider value={vals}>{children}</SovrnContext.Provider>;
};

export default SovrnProvider;
