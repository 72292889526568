import { Box } from '@mui/material';
import OfferGroupProvider from '../context';
import NavWithBack from '../../../components/NavWithBack';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Outlet } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from '@mui/icons-material/Settings';
import { MouseSharp } from '@mui/icons-material';

type Props = {};

const SingleOfferGroup = (props: Props) => {
  const nav = [
    {
      location: '',
      label: 'Offers',
      startIcon: <InventoryIcon />,
    },
    {
      label: 'Reports',
      location: 'reports',
      startIcon: <AssessmentIcon />,
    },
    {
      label: 'Settings',
      location: 'settings',
      startIcon: <SettingsIcon />,
    },
    {
      label: 'Clicks',
      location: 'clicks',
      startIcon: <MouseSharp />,
    },
  ];

  return (
    <OfferGroupProvider>
      <Box mt={4} display="flex" flexDirection="column" gap={2}>
        <NavWithBack navigation={nav} relative="path" />
        <Outlet />
      </Box>
    </OfferGroupProvider>
  );
};

export default SingleOfferGroup;
