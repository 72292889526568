import useApi from '../../hooks/useApi';

const API_URL =
  '/api/affiliate/merchants?apiKey=mt1emmmpqkv9diu6s7p0soj19qbtnkaq';

export default function useMerchants() {
  const { api: apiClient, createApiCall } = useApi();

  const getActiveMerchants = createApiCall(async ({ signal }) => {
    const {
      data: { activeMerchants },
    } = await apiClient.get(API_URL, { signal });

    return activeMerchants;
  });

  return { getActiveMerchants };
}
