import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import LoadingButton from '../../../components/LoadingButton';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (hideOffer: boolean) => any;
};

const BulkEditCustomOffer = ({ handleClose, open, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [hideOffer, setHideOffer] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideOffer(event.target.value === 'true');
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(hideOffer);
    setLoading(false);
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Bulk Edit Custom Offer</Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend">Show or Hide Offer</FormLabel>
        <RadioGroup
          aria-label="hideOffer"
          name="hideOffer"
          value={hideOffer}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="false" control={<Radio />} label="Show" />
          <FormControlLabel value="true" control={<Radio />} label="Hide" />
        </RadioGroup>
      </FormControl>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default BulkEditCustomOffer;
