import { Box, TextField } from '@mui/material';
import SimpleRadioGroup from '../../../components/RadioGroup';
import SimpleCheckboxGroup from '../../../components/Checkboxes';
import { useEffect, useState } from 'react';
import LoadingButton from '../../../components/LoadingButton';
import CountrySelector from '../CountrySelector';
import { TrafficSource } from '../types';

type Props = {
  blockRules?: TrafficSource['blockRules'];
  onSubmit: (rules: TrafficSource['blockRules']) => any;
  isOfferLevel?: boolean;
};

const initialValue: TrafficSource['blockRules'] = {
  blockAnon: true,
  blockDataCenter: true,
  blockBadIps: [],
  blockLocations: {
    type: 'blacklist',
    locations: [],
  },
  globalFallback: '',
};

const TrafficBlockForm = ({
  blockRules: initialBlock,
  onSubmit,
  isOfferLevel = false,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [blockRules, setBlockRules] = useState({ ...initialValue });

  useEffect(() => {
    setBlockRules(initialBlock ?? { ...initialValue });
  }, [initialBlock]);

  const blockFields = [
    { value: true, label: 'Block' },
    { value: false, label: 'Allow' },
  ];

  const maliciousFields = [
    {
      value: 'gen',
      label: 'General Malicious IPs',
    },
    {
      value: 'ddos',
      label: 'DDOS',
    },
    {
      value: 'recon',
      label: 'Reconnaisance/Crawlers',
    },
  ];

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit(blockRules);
    } catch (error) {}
    setLoading(false);
  };

  const onChange = (key: string, newValue: any) => {
    setBlockRules({
      ...blockRules,
      [key]: newValue,
    });
  };

  const onBooleanChange = (key: string, newValue: any) => {
    const value = newValue === 'true';
    onChange(key, value);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3} mb={2}>
        <TextField
          label="Global fallback"
          helperText="(Optional) This fallback will be used if the offer does not have a fallback link"
          value={blockRules.globalFallback}
          onChange={(v) => onChange('globalFallback', v.target.value)}
          disabled={isOfferLevel}
        ></TextField>
        <Box display="flex" gap={4} flexDirection="row">
          <SimpleRadioGroup
            fields={blockFields}
            label="Block anonymous"
            onChange={(v: any) => onBooleanChange('blockAnon', v)}
            value={blockRules.blockAnon}
            helperText="Tor and proxies identified by AWS"
          ></SimpleRadioGroup>
          <SimpleRadioGroup
            fields={blockFields}
            label="Block Data center & VPNs"
            onChange={(v: any) => onBooleanChange('blockDataCenter', v)}
            value={blockRules.blockDataCenter}
          ></SimpleRadioGroup>
          <SimpleCheckboxGroup
            fields={maliciousFields}
            label="Block Malicious IP"
            onChange={(v: any) => onChange('blockBadIps', v)}
            value={blockRules.blockBadIps}
          ></SimpleCheckboxGroup>
        </Box>
        <CountrySelector
          value={blockRules.blockLocations}
          onChange={(v: any) => onChange('blockLocations', v)}
        />
      </Box>
      <LoadingButton
        loading={loading}
        variant="contained"
        disabled={loading}
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </>
  );
};

export default TrafficBlockForm;
