import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

function GuardedRoute({ children }) {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  if (!isAuthenticated)
    return (
      <Navigate
        to={`/login?r=${encodeURIComponent(location.pathname)}`}
      />
    );

  return children;
}

export default GuardedRoute;
