import React, { useEffect, useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { Box, Divider, MenuItem, TextField, Typography } from '@mui/material';
import LoadingButton from '../../../components/LoadingButton';
import useTraffic from '../../traffic/useTraffic';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (newTraffic: any) => any;
};

const AddCustomOfferGroupTrafficSource = ({
  open,
  handleClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [traffic, setTraffic] = useState([]);
  const [selectedTraffic, setSelectedTraffic] = useState('');
  const { getTraffic } = useTraffic();

  const init = () => {
    setLoading(true);
    const apiCall = getTraffic();

    (async function () {
      try {
        const traffic = await apiCall.call();
        setTraffic(traffic);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTraffic(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit({ id: selectedTraffic, clickId: '' });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Box display="flex" justifyContent="space-between" alignContent="center">
        <Typography variant="h6">Add traffic source data</Typography>
      </Box>
      <Divider />
      <TextField
        label="Traffic"
        onChange={onChange}
        name="traffic"
        select
        value={selectedTraffic}
      >
        {traffic.map((p: any) => (
          <MenuItem key={p._id} value={p._id}>
            {p.name}
          </MenuItem>
        ))}
      </TextField>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default AddCustomOfferGroupTrafficSource;
