import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import React from 'react';

type Props = {
  fields: { label: string; value: any }[];
  label: string;
  onChange: any;
  value: any[];
  helperText?: string;
};

const SimpleCheckboxGroup = ({
  value,
  onChange,
  label,
  fields,
  helperText,
}: Props) => {
  const isValChecked = (toCheck: any) => {
    return value.includes(toCheck);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    let newValues = [];

    if (checked) {
      newValues = Array.from(new Set([...value, name]));
    } else {
      newValues = value.filter((v) => v !== name);
    }

    onChange(newValues);
  };

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {fields.map((f) => (
        <FormControlLabel
          label={f.label}
          key={f.value}
          control={
            <Checkbox
              checked={isValChecked(f.value)}
              onChange={handleChange}
              name={f.value}
            />
          }
        />
      ))}
    </FormGroup>
  );
};

export default SimpleCheckboxGroup;
