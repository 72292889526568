import { Typography, TextField, Button, MenuItem } from '@mui/material';
import { OfferContext } from '../context';
import { useContext, useState } from 'react';

import SimpleModal from '../../../components/SimpleModal';

export default function AddOffer({ open, handleClose, onSubmit }) {
  const offerContext = useContext(OfferContext);

  const { traffic, domains } = offerContext;

  const [values, setValues] = useState({
    offerLink: '',
    trafficSourceId: '',
    name: '',
    fallBackUrl: '',
    redirectDomainId: '',
  });

  const onChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const activeDomains = domains.filter((item) => item.active);

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add offer</Typography>
      <Typography>
        Adding an offer will generate a redirect link for it
      </Typography>

      <TextField
        name="name"
        onChange={onChange}
        label="Name"
        value={values.name}
        fullWidth
      />
      <TextField
        onChange={onChange}
        fullWidth
        label="Offer link"
        value={values.offerLink}
        name="offerLink"
      />
            <TextField
        onChange={onChange}
        fullWidth
        label="Fallback Url"
        value={values.fallBackUrl}
        name="fallBackUrl"
      />
      <TextField
        select
        onChange={onChange}
        fullWidth
        label="Traffic source"
        name="trafficSourceId"
        value={values.trafficSourceId}
        InputLabelProps={{ shrink: true }}
        SelectProps={{ displayEmpty: true }}
      >
        <MenuItem value="">None</MenuItem>
        {traffic.map((item) => {
          return (
            <MenuItem value={item._id} key={item._id}>
              {item.name}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        select
        onChange={onChange}
        fullWidth
        label="Redirect domain"
        name="redirectDomainId"
        value={values.redirectDomainId}
        helperText="Only includes active domains"
      >
        {activeDomains.map((item) => {
          return (
            <MenuItem value={item._id} key={item._id}>
              {item.domainName}
            </MenuItem>
          );
        })}
      </TextField>
      <Button variant="contained" onClick={() => onSubmit(values, handleClose)}>
        Add
      </Button>
    </SimpleModal>
  );
}
