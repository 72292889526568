import React, { useState } from 'react';
import { Typography, TextField, Button } from '@mui/material';
import SimpleModal from '../../../components/SimpleModal';
import { BlacklistedProduct } from '../types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onAddProduct: (newProduct: BlacklistedProduct) => any;
};

const AddProduct = ({ open, handleClose, onAddProduct }: Props) => {
  const [values, setValues] = useState({
    productId: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (!isNaN(value)) {
      setValues(() => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const onSubmit = async () => {
    await onAddProduct(values);
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add Blacklisted Product</Typography>
      <TextField
        fullWidth
        name="productId"
        onChange={handleChange}
        label="Product Id"
        value={values.productId}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={onSubmit}
        disabled={!values.productId}
        fullWidth
      >
        Submit
      </Button>
    </SimpleModal>
  );
};

export default AddProduct;
