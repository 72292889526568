import useApi from '../../hooks/useApi';
import {
  AddSovrnDto,
  SovrnReport,
  SovrnReportRow,
  SovrnReportWithRows,
} from './types';

const BASE_URL = '/api/sovrnreports';

const useSovrn = () => {
  const { api, createApiCall } = useApi();

  const getSovrnReports = createApiCall(async ({ signal }) => {
    const {
      data: { sovrnReports },
    } = await api.get(BASE_URL, { signal });

    return sovrnReports as SovrnReport[];
  });

  const getSovrnReport = createApiCall(async ({ signal }, id: string) => {
    const {
      data: { sovrnreport },
    } = await api.get(`${BASE_URL}/${id}`, { signal });

    return sovrnreport as SovrnReportWithRows;
  });

  const overwriteReport = createApiCall(
    async ({ signal }, id: SovrnReport['_id'], rows: SovrnReportRow[]) => {
      await api.put(`${BASE_URL}/${id}/rows`, { rows }, { signal });
    }
  );

  const overwriteReportWithGenerated = createApiCall(
    async ({ signal }, id: SovrnReport['_id'], rows: SovrnReportRow[]) => {
      await api.put(
        `${BASE_URL}/${id}/rows-with-generated`,
        { rows },
        { signal }
      );
    }
  );

  const patchReport = createApiCall(
    async ({ signal }, id: SovrnReport['_id'], rows: SovrnReportRow[]) => {
      await api.patch(`${BASE_URL}/${id}/rows`, { rows }, { signal });
    }
  );

  const createEmptyReport = createApiCall(
    async ({ signal }, values: AddSovrnDto) => {
      const {
        data: { sovrnreport },
      } = await api.post(`${BASE_URL}`, values, { signal });
      return sovrnreport;
    }
  );

  const deleteReport = createApiCall(
    async ({ signal }, id: SovrnReport['_id']) => {
      await api.delete(`${BASE_URL}/${id}`, { signal });
      return true;
    }
  );

  const populateReport = createApiCall(
    async ({ signal }, id: SovrnReport['_id']) => {
      await api.post(`${BASE_URL}/${id}/populate`, { signal });
      return true;
    }
  );

  const fillReports = createApiCall(
    async (
      { signal },
      ids: SovrnReport['_id'][],
      mode: 'fill-zero' | 'fill-all'
    ) => {
      const {
        data: { sovrnreports },
      } = await api.post(`${BASE_URL}/fill-reports`, { ids, mode }, { signal });
      return sovrnreports as SovrnReport[];
    }
  );

  return {
    getSovrnReports,
    getSovrnReport,
    overwriteReport,
    createEmptyReport,
    deleteReport,
    populateReport,
    overwriteReportWithGenerated,
    fillReports,
    patchReport,
  };
};

export default useSovrn;
