import React, { memo, useState, useMemo, useContext } from 'react';
import useTraffic from '../../useTraffic';
import { Card, Box, Chip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import SingleTrafficToolbar from './Toolbar';
import ConfirmModal from '../../../../components/ConfirmModal';
import { SingleTrafficContext } from '../context';

function ParameterTable() {
  const { deleteTrafficParams } = useTraffic();

  const { setTrafficData, trafficData, loading } =
    useContext(SingleTrafficContext);

  const [selectedRow, setSelectedRow] = useState('');

  const selectedRowData = useMemo(() => {
    return trafficData?.parameterOptions?.find(
      item => item._id === selectedRow[0]
    );
  }, [selectedRow, trafficData]);

  const onAddParamsDone = newTraffic => {
    trafficData.parameterOptions = newTraffic.parameterOptions;
    setTrafficData({ ...trafficData });
  };

  const onDeleteParams = async paramsId => {
    await deleteTrafficParams().call(trafficData._id, paramsId);

    const filtered = trafficData.parameterOptions.filter(
      item => item.id !== paramsId
    );

    trafficData.parameterOptions = filtered;

    setTrafficData({ ...trafficData });
  };

  const rows = trafficData?.parameterOptions ?? [];
  const cols = generateTableCols(onDeleteParams);

  return (
    <Box display="flex" flexDirection="column" gap={3} mb={4}>
      <Typography variant="h4">{trafficData?.name}</Typography>
      <Typography variant="h6">Parameters</Typography>
      <Card>
        <DataGrid
          autoHeight
          rows={rows}
          columns={cols}
          getRowId={r => r._id}
          onRowSelectionModelChange={setSelectedRow}
          loading={loading}
          slots={{
            toolbar: () => {
              return (
                <SingleTrafficToolbar
                  selectedRow={selectedRowData}
                  onAddParamsDone={onAddParamsDone}
                  onEditParamsDone={onAddParamsDone}
                />
              );
            },
          }}
        />
      </Card>
    </Box>
  );
}

const generateTableCols = onDelete => {
  const tableCols = [
    {
      headerName: 'Tag name',
      field: 'tag',
      flex: 1,
    },
    {
      headerName: 'Autogenerate Rule',
      field: 'autogenerateRule',
      flex: 1,
      renderCell: params => {
        const autoMap = [
          'None',
          'Letters only',
          'Numbers only',
          'Alphanumeric',
        ];
        return <Chip label={autoMap[params.row.autogenerateRule]} />;
      },
    },
    {
      headerName: 'Length',
      field: 'length',
      flex: 1,
    },
    {
      headerName: 'Conditions',
      field: 'conditionRegex',
      flex: 1,
      renderCell: ({ row }) => {
        const { conditionRegex } = row;

        const hasNoValue = !conditionRegex?.value;
        const hasNoType = !conditionRegex?.type;

        if (hasNoValue || hasNoType) return <Chip label="No conditions" />;

        const { value, type } = conditionRegex;

        const typeLabels = ['', 'Starts with', 'Ends with', 'Contains'];

        const label = `${typeLabels[type]} ${value}`;

        return <Chip label={label} />;
      },
    },
    {
      headerName: 'Replacement method',
      field: 'replacementMethod',
      flex: 1,
      renderCell: ({ row }) => {
        const methodMap = {
          urlParameter: 'URL parameter',
          dynamicParameter: 'Dynamic parameter',
          urlAndDynamicParameter: 'URL and dynamic parameter',
        };

        const label =
          methodMap[row.replacementMethod] ?? methodMap.urlParameter;

        return <Chip label={label} />;
      },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      renderCell: params => {
        return (
          <Box>
            <ConfirmModal
              title="Delete"
              icon={<DeleteIcon />}
              onConfirm={() => onDelete(params.row.id)}
            >
              Do you want to delete this tag?
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  return tableCols;
};

export default memo(ParameterTable);
