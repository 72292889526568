import React from 'react';
import { Card, Box } from '@mui/material';
import PostbackTable from './PostbackTable';
import PostbackProvider from './context';

function PostbackPage() {
  return (
    <PostbackProvider>
      <Box mt={4}>
        <PostbackTable />
      </Box>
    </PostbackProvider>
  );
}

export default PostbackPage;
 