import React, { useState, useMemo, useEffect, memo } from 'react';
import { Card, Box, Chip } from '@mui/material';
import useTraffic from '../useTraffic';
import TrafficToolbar from './Toolbar';
import { useNavigate, Link } from 'react-router-dom';

import ConfirmModal from '../../../components/ConfirmModal';
import TooltipIconButton from '../../../components/TooltipIconButton';

import SettingsIcon from '@mui/icons-material/Settings';
import DynamicTable from '../../../components/DynamicTable';
import { DisabledByDefault } from '@mui/icons-material';
import QuickFilter from '../../../components/QuickFilter';

function TrafficTable() {
  const [traffic, setTraffic] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quickFilter, setQuickFilter] = useState('');
  const { getTraffic, deleteTraffic } = useTraffic();

  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState('');

  const init = () => {
    setLoading(true);
    const apiCall = getTraffic();

    (async function () {
      try {
        const traffic = await apiCall.call();
        setTraffic(traffic);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  const renderCell = (type, data) => {
    switch (type) {
      case 'name':
        return <Link to={data._id}>{data.name}</Link>;

      case 'parameterOptions':
        const parameterOptions = data.parameterOptions;

        const hasNoParameters = parameterOptions.length < 1;

        if (hasNoParameters) return <Chip label="No parameters" />;

        return data.parameterOptions.map((item) => {
          return <Chip label={item.tag} sx={{ mr: 1 }} />;
        });

      case 'actions':
        return (
          <Box>
            <TooltipIconButton
              onClick={() => navigate(data._id)}
              title="View"
              icon={<SettingsIcon />}
            />
            <TooltipIconButton
              onClick={() => navigate(`${data._id}/block`)}
              title="Block rules"
              icon={<DisabledByDefault />}
            />
          </Box>
        );
    }
  };

  const onAddTrafficDone = (newTraffic) => {
    setTraffic([...traffic, newTraffic]);
  };

  const onEditTrafficDone = (newTraffic) => {
    const newTraffics = traffic.map((item) => {
      if (item._id === selectedRows[0]) return newTraffic;
      return item;
    });

    setTraffic(newTraffics);
  };

  const onDeleteTrafficDone = (id) => {
    const newTraffic = traffic.filter((item) => !id.includes(item._id));
    setTraffic(newTraffic);
  };

  const handleDelete = async (sourceId) => {
    try {
      setLoading(true);
      await deleteTraffic().call(sourceId);
      const newTraffic = traffic.filter((item) => item._id !== sourceId);

      setTraffic(newTraffic);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return init();
  }, []);

  const cols = generateTableCols(handleDelete, navigate);

  const selectedRowData = useMemo(() => {
    if (selectedRows.length > 1) {
      return undefined;
    } else {
      return traffic.find((item) => item._id === selectedRows[0]);
    }
  }, [selectedRows, traffic]);

  useEffect(() => {
    setSelectedRows([]);
  }, [traffic]);

  const filteredTraffic = useMemo(() => {
    return traffic.filter((item) => {
      const lowercasedFilter = quickFilter.toLowerCase();

      const nameMatches = item.name.toLowerCase().includes(lowercasedFilter);

      const parametersMatch = item.parameterOptions.some((param) =>
        param.tag.toLowerCase().includes(lowercasedFilter)
      );

      return nameMatches || parametersMatch;
    });
  }, [traffic, quickFilter]);

  return (
    <Box mt={4}>
      <Card>
        <QuickFilter
          quickFilter={quickFilter}
          setQuickFilter={setQuickFilter}
        />
        <TrafficToolbar
          selectedRow={selectedRowData}
          onAddTrafficDone={onAddTrafficDone}
          onEditTrafficDone={onEditTrafficDone}
          onDeleteTrafficDone={onDeleteTrafficDone}
          rowToDelete={selectedRows}
        />
        <DynamicTable
          columns={cols}
          filteredData={filteredTraffic}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          renderCell={renderCell}
        />
      </Card>
    </Box>
  );
}

const generateTableCols = (onDelete, onView) => {
  const tableCols = [
    {
      name: 'Name',
      field: 'name',
      flex: 1,
      filter: false,
    },
    {
      name: 'Parameters',
      field: 'parameterOptions',
      flex: 2,
      filter: false,
    },
    {
      name: 'Actions',
      field: 'actions',
      flex: 1,
      filter: false,
    },
  ];

  return tableCols;
};
export default memo(TrafficTable);
