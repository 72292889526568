import { Box } from '@mui/material';
import SovrnTable from './SovrnTable';
import SovrnProvider from './context';

type Props = {};

const SovrnPage = (props: Props) => {
  return (
    <Box mt={4}>
      <SovrnProvider>
        <SovrnTable />
      </SovrnProvider>
    </Box>
  );
};

export default SovrnPage;
