import { createContext, useState, useEffect } from 'react';
import axios from '../api';

export const DEFAULT_CONTEXT_VALUES = { AccessToken: '', IdToken: '', RefreshToken: '' };

export const AuthContext = createContext<any>(DEFAULT_CONTEXT_VALUES);

function AuthProvider({ children }: any) {
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState(DEFAULT_CONTEXT_VALUES);

  const isAuthenticated = tokens.AccessToken && tokens.IdToken && tokens.RefreshToken;

  const saveCredentials = (loginResponseData: any) => {
    const {
      AuthenticationResult: { AccessToken, IdToken, RefreshToken },
    } = loginResponseData;

    localStorage.setItem('AT', AccessToken);
    localStorage.setItem('IT', IdToken);

    if (RefreshToken) localStorage.setItem('RT', RefreshToken);

    setTokens({
      AccessToken,
      IdToken,
      RefreshToken,
    });

    return { AccessToken, IdToken, RefreshToken };
  };

  const getCredentials = () => {
    const AccessToken = localStorage.getItem('AT');
    const IdToken = localStorage.getItem('IT');
    const RefreshToken = localStorage.getItem('RT');

    return { RefreshToken, IdToken, AccessToken };
  };

  const clearCredentials = () => {
    localStorage.removeItem('AT');
    localStorage.removeItem('IT');
    localStorage.removeItem('RT');

    setTokens(DEFAULT_CONTEXT_VALUES);
  };

  const logout = async () => {
    try {
      await axios.post('/api/auth/logout', { accessToken: tokens.AccessToken });
    } finally {
      clearCredentials();
    }
  };

  const refreshToken = async (token: string) => {
    try {
      setLoading(true);
      const { data } = await axios.post('/api/auth/refresh', { refreshToken: token });
      data.AuthenticationResult.RefreshToken = token;

      saveCredentials(data);
    } catch (error) {
      clearCredentials();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { RefreshToken } = getCredentials();

    if (RefreshToken) refreshToken(RefreshToken);
  }, []);

  const props = { tokens, clearCredentials, saveCredentials, isAuthenticated, logout };

  return <AuthContext.Provider value={props}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
