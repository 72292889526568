import { DataGrid, GridColDef } from '@mui/x-data-grid';
import SingleOfferGroupToolbar from '../SingleOfferGroupToolbar';
import { CustomOffer } from '../types';
import useCustomOffer from '../useCustomOffers';
import { useContext, useState } from 'react';
import { OfferGroupContext } from '../context';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { Delete, Edit } from '@mui/icons-material';
import { Alert, Box, Chip } from '@mui/material';
import ConfirmModal from '../../../components/ConfirmModal';
import EditCustomOffer from '../EditCustomOffer';
import BulkEditCustomOffer from '../BulkEditCustomOffer';

type Props = {};

const SingleOfferGroupTable = (props: Props) => {
  const client = useCustomOffer();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [bulkOpen, setBulkOpen] = useState(false);

  const { offerGroup, customOffers, loading, setLoading, setCustomOffers } =
    useContext(OfferGroupContext);

  const [rowToEdit, setRowToEdit] = useState<CustomOffer | null>(null);

  const deleteRow = async (_id: CustomOffer['_id']) => {
    setLoading(true);
    await client.deleteCustomOffer().call(_id);

    const newRows = customOffers.filter((r) => r._id !== _id);
    setCustomOffers(newRows);
    setLoading(false);
  };

  const closeEditModal = () => setRowToEdit(null);

  const onEditCustomOffer = async (
    id: string,
    row: Pick<CustomOffer, 'data' | 'api'>
  ) => {
    await client.updateCustomOffer().call(id, row);

    const newOffer = customOffers.map((c) => {
      if (c._id === id)
        return {
          ...c,
          api: row.api,
          data: row.data,
        };

      return c;
    });

    setCustomOffers(newOffer);
  };

  const handleBulkSubmit = async (hideOffer: boolean) => {
    setLoading(true);

    const updatedOffers = customOffers.map((offer) => {
      if (selectedRows.includes(offer._id)) {
        return {
          ...offer,
          api: {
            ...offer.api,
            hideOffer: hideOffer,
          },
          data: {
            ...offer.data,
            status: hideOffer === true ? 'inactive' : 'active',
          },
        };
      }
      return offer;
    });

    setCustomOffers(updatedOffers);

    await Promise.all(
      selectedRows.map((id) => {
        const offer = customOffers.find((offer) => offer._id === id);
        if (offer) {
          return client.updateCustomOffer().call(id, {
            ...offer,
            api: { ...offer.api, hideOffer },
            data: {
              ...offer.data,
              status: hideOffer === true ? 'inactive' : 'active',
            },
          });
        }
        return Promise.resolve();
      })
    );

    setLoading(false);
  };

  const getCols = () => {
    if (!offerGroup) return [];

    const fieldcols = offerGroup.fields.map((f) => {
      const col: GridColDef<CustomOffer> = {
        headerName: f.label,
        field: f.key,
        flex: 1,
        valueGetter: (params) => {
          return params.row.data[f.key];
        },
        ...(f.key === 'status' && {
          renderCell: (params) => (
            <Chip
              label={params.value}
              color={params.value === 'inactive' ? 'error' : 'default'}
            />
          ),
        }),
      };
      return col;
    });

    const actioncol: GridColDef<CustomOffer> = {
      headerName: 'Actions',
      field: '_id',
      renderCell: ({ row }) => {
        return (
          <Box>
            <TooltipIconButton
              title="Update"
              icon={<Edit />}
              onClick={() => setRowToEdit(row)}
            />
            <ConfirmModal
              iconButton
              title="Delete"
              icon={<Delete />}
              onConfirm={() => deleteRow(row._id)}
            >
              <Alert severity="error">
                Are you sure you want to delete this offer?
              </Alert>
            </ConfirmModal>
          </Box>
        );
      },
    };

    return [...fieldcols, actioncol];
  };

  const cols = getCols();

  const onImportDone = (results: CustomOffer[]) => {
    setCustomOffers([...customOffers, ...results]);
  };

  const onDownloadTemplate = () => {
    if (!offerGroup) return;

    const fields = offerGroup.fields.map((f) => f.key).join(',');
    const blob = new Blob([fields], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = `${offerGroup.label}-template.csv`;
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const onExport = () => {
    if (!offerGroup) return;

    const csvData = [
      offerGroup.fields.map((f) => f.label).join(','),
      ...customOffers.map((offer) =>
        offerGroup.fields.map((f) => offer.data[f.key] || '').join(',')
      ),
    ].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = `${offerGroup.label}-offers.csv`;
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <>
      <DataGrid
        columns={cols}
        rows={customOffers}
        loading={loading}
        autoHeight
        getRowId={(r) => r._id}
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        components={{
          Toolbar: () => (
            <SingleOfferGroupToolbar
              onDownloadTemplate={onDownloadTemplate}
              onImportDone={onImportDone}
              onExport={onExport}
              selectedRows={selectedRows}
              onBulkEdit={() => setBulkOpen(true)}
            />
          ),
        }}
      />
      <BulkEditCustomOffer
        open={bulkOpen}
        handleClose={() => setBulkOpen(false)}
        onSubmit={handleBulkSubmit}
      />
      {rowToEdit && (
        <EditCustomOffer
          row={rowToEdit}
          open={true}
          handleClose={closeEditModal}
          onSubmit={onEditCustomOffer}
          group={offerGroup!}
        />
      )}
    </>
  );
};

export default SingleOfferGroupTable;
