import React from 'react';
import { Card, Box } from '@mui/material';

function Dashboard() {
  return (
    <Box mt={4}>
      <Card sx={{ p: 2 }}>Nothing here yet</Card>
    </Box>
  );
}

export default Dashboard;
