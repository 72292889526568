import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import SimpleToolbar from '../../../../components/SimpleToolbar';
import AddPostback from "../../AddPostback";
import EditPostback from "../../EditPostback";

import usePostback from "../../usePostback";


export default function PostbackToolbar({ selectedRow, onAddPostbackDone, onEditPostbackDone }) {
    const { addPostback, editPostback } = usePostback();

    const onAddPostback = async newPostback => {
        const postback = await addPostback().call(newPostback);
        onAddPostbackDone(postback);
    }

    const onEditPostback = async newPostback => {
        const postback = await editPostback().call(selectedRow._id, newPostback);
        onEditPostbackDone(postback);
    }

    const modalButtons = [
        {
            name: 'add',
            label: 'Add', 
            renderIf: true,
            icon: <AddIcon />,
            render: (open, handleClose) => {
                return <AddPostback open={open} handleClose={handleClose} onSubmit={onAddPostback} />;
            }
        },
        {
            name: 'edit',
            label: 'Edit',
            renderIf: Boolean(selectedRow),
            icon: <EditIcon />,
            render: (open, handleClose) => {
                return <EditPostback open={open} handleClose={handleClose} initialData={selectedRow} onSubmit={onEditPostback} />;
            }
        }
    ];

    return <SimpleToolbar modalButtons={modalButtons} />
}