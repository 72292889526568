import useApi from '../../hooks/useApi';
import { Merchant, MerchantDTO } from './types';

const API_URL = '/api/feeds';

export default function useFeeds() {
  const { api: apiClient, createApiCall } = useApi();

  const getFeedDetails = createApiCall(
    async ({ signal }, merchantId: Merchant['mid']) => {
      const { data } = await apiClient.get(`${API_URL}/${merchantId}`);

      return data;
    }
  );

  const regenerateFeed = createApiCall(
    async ({ signal }, merchantData: MerchantDTO) => {
      const { status } = await apiClient.post(API_URL, merchantData);

      return status;
    }
  );

  const getRegenerationHistory = createApiCall(
    async ({ signal }, merchantId: Merchant['mid']) => {
      const {
        data: { regenerationHistory },
      } = await apiClient.get(`${API_URL}/regenerate/history/${merchantId}`, {
        signal,
      });
      return regenerationHistory;
    }
  );

  const getHistory = createApiCall(async ({ signal }) => {
    const {
      data: { uploadDates },
    } = await apiClient.get(`${API_URL}/regenerate/uploaddates`, { signal });

    return uploadDates;
  });

  return { getFeedDetails, regenerateFeed, getRegenerationHistory, getHistory };
}
