import useApi from '../../hooks/useApi';

const API_URL = '/api/offers';
const API_URL_BULK = '/api/offers/bulk';

export default function useOffer() {
  const { api: apiClient, createApiCall } = useApi();

  const getOffers = createApiCall(async ({ signal }) => {
    const {
      data: { offers },
    } = await apiClient.get(API_URL, { signal });

    return offers;
  });

  const editOffer = createApiCall(async ({ signal }, offerId, newOffer) => {
    const {
      data: { offer },
    } = await apiClient.put(`${API_URL}/${offerId}`, newOffer, { signal });

    return offer;
  });

  const deleteOffer = createApiCall(async ({ signal }, offerId) => {
    await apiClient.delete(`${API_URL}/${offerId}`, { signal });
  });

  const addOffer = createApiCall(async ({ signal }, newOffer) => {
    const {
      data: { offer },
    } = await apiClient.post(API_URL, newOffer, { signal });
    return offer;
  });

  const importOffer = createApiCall(async ({ signal }, newOffer) => {
    const {
      data: { offers },
    } = await apiClient.post(API_URL_BULK, { offers: newOffer }, { signal });
    return offers;
  });

  return { addOffer, getOffers, editOffer, deleteOffer, importOffer };
}
