import { Box, Chip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ConfirmModal from '../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useMemo, useState } from 'react';
import DomainToolbar from '../Toolbar';
import useDomain from '../useDomain';

type Domain = {
  domainName: string;
  domainLink: string;
  active: boolean;
  _id: string;
  createdAt: string;
  updateAt: string;
};

function DomainTable() {
  const [domains, setDomains] = useState<Domain[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const { getDomains, deleteDomain } = useDomain();

  const init = () => {
    setLoading(true);
    const instance = getDomains();

    (async function () {
      try {
        const domains = await instance.call();
        setDomains(domains);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();

    return instance.abort;
  };

  const onAddDomainDone = (newDomain: Domain) => {
    const newDomains = domains.slice();
    newDomains.push(newDomain);

    setDomains(newDomains);
  };

  const onEditDomainDone = (newDomain: Domain) => {
    const newDomains = domains.map((item) => {
      if (newDomain._id === item._id) return newDomain;
      return item;
    });

    setDomains(newDomains);
  };

  const onDelete = async (id: string) => {
    try {
      setLoading(true);
      await deleteDomain().call(id);
      const newDomains = domains.filter((domain) => domain._id !== id);
      setDomains(newDomains);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const tableCols: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'domainName',
      flex: 1,
    },
    {
      headerName: 'Link to domain',
      field: 'domainLink',
      flex: 2,
    },
    {
      headerName: 'Active',
      field: 'active',
      flex: 1,
      renderCell: (params) => {
        const chipLabel = params.row.active ? 'Active' : 'Inactive';
        const chipColor = params.row.active ? 'primary' : 'default';

        return <Chip label={chipLabel} color={chipColor} />;
      },
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <ConfirmModal
              onConfirm={() => {
                onDelete(params.row._id);
              }}
              title="Delete"
              icon={<DeleteIcon />}
            >
              Do you want to delete this domain?
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    return init();
  }, []);

  const selectedRowData = useMemo(() => {
    if (!selectedRow[0]) return null;

    return domains.find((item) => item._id === selectedRow[0]);
  }, [domains, selectedRow]);

  return (
    <DataGrid
      autoHeight
      rows={domains}
      columns={tableCols}
      getRowId={(r) => r._id}
      onRowSelectionModelChange={setSelectedRow as any}
      loading={loading}
      slots={{
        toolbar: () => {
          return (
            <DomainToolbar
              selectedRowData={selectedRowData}
              onAddDomainDone={onAddDomainDone}
              onEditDomainDone={onEditDomainDone}
            />
          );
        },
      }}
    />
  );
}

export default DomainTable;
