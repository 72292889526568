import React, { memo, useContext, useMemo } from 'react';
import DynamicToolbar from '../../../../components/DynamicToolbar';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';

import AddOffer from '../../AddOffer';
import EditOffer from '../../EditOffer';
import DeleteOffer from '../../DeleteOffer';
import TestOffer from '../../TestOffer';
import useOffer from '../../useOffer';

import { OfferContext } from '../../context';

function OfferToolbar({
  selectedRow,
  onAddOfferDone,
  onEditOfferDone,
  onDeleteOfferDone,
  onImportOfferDone,
  rowToDelete,
  currentData,
  getTrafficSourceId,
  getRedirectDomainId,
}) {
  const { addOffer, editOffer, deleteOffer, importOffer } = useOffer();
  const { traffic, domains } = useContext(OfferContext);
  const trafficMap = useMemo(() => {
    return traffic.reduce((acc, item) => {
      acc[item._id] = item.name;
      return acc;
    }, []);
  }, [traffic]);

  const domainMap = useMemo(() => {
    return domains.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});
  }, [domains]);

  const onAddOffer = async (newOffer, handleClose) => {
    const offer = await addOffer().call(newOffer);
    onAddOfferDone(offer);
    handleClose(true);
  };

  const onEditOffer = async (newOffer) => {
    const offer = await editOffer().call(selectedRow._id, newOffer);
    onEditOfferDone(offer);
  };

  const onDeleteOffer = async (id, handleClose) => {
    await deleteOffer().call(id);
    onDeleteOfferDone(id);
    handleClose(true);
  };

  const onImportOffer = async (data) => {
    // Define the expected header structure

    const expectedHeaders = [
      'Name',
      'Offer link',
      'Redirect link',
      'Traffic source',
    ];

    const headers = data[0];

    const formattedData = [];

    for (let i = 1; i < data.length; i++) {
      const row = data[i];

      const formattedRow = {};

      formattedRow['name'] = row[headers.indexOf('Name')];
      formattedRow['offerLink'] = row[headers.indexOf('Offer link')];
      formattedRow['redirectDomainId'] = getRedirectDomainId(
        row[headers.indexOf('Redirect link')]
      );
      formattedRow['trafficSourceId'] = getTrafficSourceId(
        row[headers.indexOf('Traffic source')]
      );

      formattedData.push(formattedRow);
    }
    const res = await importOffer().call(formattedData);
    onImportOfferDone(res);
    window.alert('CSV data successfully processed.');
  };

  const modalButtons = useMemo(() => {
    const res = [
      {
        name: 'add',
        label: 'Add',
        renderIf: true,
        icon: <AddIcon />,
        render: (open, handleClose) => {
          return (
            <AddOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onAddOffer}
            />
          );
        },
      },
      {
        name: 'edit',
        label: 'Edit',
        renderIf: Boolean(selectedRow),
        icon: <EditIcon />,
        render: (open, handleClose) => {
          return (
            <EditOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onEditOffer}
              initialData={selectedRow}
            />
          );
        },
      },
      {
        name: 'test',
        label: 'Test',
        renderIf: true,
        icon: <BuildIcon />,
        render: (open, handleClose) => {
          return <TestOffer open={open} handleClose={handleClose} />;
        },
      },
      {
        name: 'delete',
        label: 'Delete',
        renderIf: Boolean(rowToDelete.length > 0),
        icon: <DeleteIcon />,
        render: (open, handleClose) => {
          return (
            <DeleteOffer
              open={open}
              handleClose={handleClose}
              onSubmit={onDeleteOffer}
              initialData={rowToDelete}
            />
          );
        },
      },
    ];

    return res;
  }, [selectedRow, rowToDelete]);

  const exportOptions = {
    name: 'Name',
    offerLink: 'Offer link',
    uniqueId: {
      label: 'Redirect link',
      formatter: (value, row) => {
        if (!value) return '';

        const { redirectDomainId } = row;
        const domainName = domainMap[redirectDomainId]?.domainLink;

        if (!domainName) return '';

        return `${domainName}/r/${value}`;
      },
    },
    trafficSourceId: {
      label: 'Traffic source',
      formatter: (value) => {
        return trafficMap[value] ?? '';
      },
    },
  };

  return (
    <DynamicToolbar
      modalButtons={modalButtons}
      allowExport
      allowImport
      exportFileName="offerTable.csv"
      rawExportData={currentData}
      exportOptions={exportOptions}
      onImportOffer={onImportOffer}
    />
  );
}

export default memo(OfferToolbar);
