import React from 'react';
import SimpleToolbar from '../../../../../components/SimpleToolbar';
import AddIcon from '@mui/icons-material/Add';
import AddSingleTraffic from '../../AddSingleTraffic';
import useTraffic from '../../../useTraffic';
import EditSingleTraffic from '../../EditSingleTraffic';

import EditIcon from '@mui/icons-material/Edit';
import { useContext } from 'react';
import { SingleTrafficContext } from '../../context';

function SingleTrafficToolbar({ selectedRow, onAddParamsDone, onEditParamsDone }) {
  const { addTrafficParams, editTrafficParams } = useTraffic();
  const { trafficData } = useContext(SingleTrafficContext);

  const onAddParams = async newParams => {
    if (!trafficData) return;

    const newTraffic = await addTrafficParams().call(trafficData._id, newParams);
    onAddParamsDone(newTraffic);
  };

  const onEditParams = async newParams => {
    if (!trafficData) return;

    const newTraffic = await editTrafficParams().call(trafficData._id, selectedRow.id, newParams);
    onEditParamsDone(newTraffic);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open, handleClose) => {
        return <AddSingleTraffic open={open} handleClose={handleClose} onSubmit={onAddParams} />;
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      icon: <EditIcon />,
      renderIf: selectedRow,
      render: (open, handleClose) => {
        return (
          <EditSingleTraffic
            open={open}
            handleClose={handleClose}
            onSubmit={onEditParams}
            initialData={selectedRow}
          />
        );
      },
    },
  ];
  return <SimpleToolbar modalButtons={modalButtons} />;
}

export default SingleTrafficToolbar;
