import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Card, Chip, Typography, IconButton, Box } from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';
import useLogs from '../useLogs';

function LogsTable() {
  const { getLogs } = useLogs();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const init = () => {
    setLoading(true);
    const apiCall = getLogs();

    (async function () {
      try {
        const logs = await apiCall.call();
        setLogs(logs);
      } catch (e) {
        console.log('Fail fetching logs', e);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const tableCols = generateTableCols();

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2} mb={1}>
        <Typography variant="h6">Postback Logs</Typography>
        <IconButton size="small" color="primary" onClick={init}>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Card>
        <DataGrid
          autoHeight
          rows={logs}
          columns={tableCols}
          getRowId={(r) => r._id}
          getRowHeight={() => 'auto'}
          loading={loading}
          initialState={{
            sorting: {
              sortModel: [{ field: 'timestamp', sort: 'desc' }],
            },
          }}
        />
      </Card>
    </Box>
  );
}

const generateTableCols = () => {
  const tableCols = [
    {
      headerName: 'Timestamp',
      field: 'timestamp',
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        const timestamp = row && row.timestamp;
        const formattedTimestamp = timestamp
          ? moment(timestamp).format('YYYY-MM-DD HH:mm [GMT]Z')
          : '';

        return <span>{formattedTimestamp}</span>;
      },
    },
    {
      headerName: 'Level',
      field: 'level',
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        const level = row && row.level;

        if (level === 'info') {
          return <Chip label="Info" color="info" />;
        } else if (level === 'success') {
          return <Chip label="Success" color="success" />;
        }
        return <Chip label="Error" color="error" />;
      },
    },
    {
      headerName: 'Parameters',
      field: 'meta',
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        const meta = row && row.meta;

        if (!meta) return <div>No parameters used</div>;

        const metaKeys = Object.keys(meta);

        return (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {metaKeys.map(
              (key) =>
                key !== 'url' && (
                  <div key={key}>
                    <strong>{key}:</strong> {meta[key]}
                  </div>
                )
            )}
          </div>
        );
      },
    },
    {
      headerName: 'Called URL',
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        const meta = row && row.meta;

        if (!meta || !meta.url)
          return <Typography variant="caption">No URL Available</Typography>;

        const mainUrl = window.location.origin;

        return (
          <Typography
            variant="caption"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >{`${mainUrl}${meta.url}`}</Typography>
        );
      },
    },
    {
      headerName: 'Message',
      field: 'message',
      flex: 3,
    },
  ];

  return tableCols;
};

export default LogsTable;
