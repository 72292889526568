import { useState, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

import SimpleModal from '../../../../components/SimpleModal';
import { SingleAffiliateContext } from '../context';
import { AddQualityReportsDTO } from '../SingleQualityReport/types';
import { Typography, Button, CircularProgress } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type Props = {
  open: boolean;
  handleClose: () => any;
  onAddQualityReports: (newQualityReport: AddQualityReportsDTO) => any;
};

const AddQualityReports = ({
  open,
  handleClose,
  onAddQualityReports,
}: Props) => {
  const { affiliate } = useContext(SingleAffiliateContext);
  const [values, setValues] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const handleDateChange = (newDate: Dayjs | null) => {
    const affiliateId = affiliate?._id;

    setValues((prevValues: any) => ({
      ...prevValues,
      affiliateId: affiliateId,
      date: newDate?.format('YYYY-MM-DD'),
    }));
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await onAddQualityReports(values);
      handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add Merchant Quality Report</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <DatePicker
          label="Pick reporting date"
          value={values}
          onChange={handleDateChange}
          maxDate={dayjs()}
        />
      </LocalizationProvider>
      <Button
        color="primary"
        variant="contained"
        onClick={onSubmit}
        fullWidth
        disabled={!values.date || loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
      </Button>
    </SimpleModal>
  );
};

export default AddQualityReports;
