import { CourierProvider } from '@trycourier/react-provider';
import { Toast } from '@trycourier/react-toast';
import { Inbox } from '@trycourier/react-inbox';

const CLIENT_KEY = 'ZjNiMTdjYTEtMzU3OC00MDU3LWEzNDAtN2Y2NmVmOWQ2ZWI5';
const USER_ID = 'Google_107096620446483178028';

const InboxApp = () => {
  return (
    <CourierProvider clientKey={CLIENT_KEY} userId={USER_ID}>
      <Toast />
      <Inbox />
    </CourierProvider>
  );
};

export default InboxApp;
