import useApi from '../../hooks/useApi';
const API_URL = '/api/merchants';

export default function useMerchants() {
  const { api: apiClient, createApiCall } = useApi();

  const getMerchants = createApiCall(async ({ signal }) => {
    const data = await apiClient.get(API_URL, { signal });

    return data;
  });

  const getMerchantDetails = createApiCall(async ({ signal }, mid) => {
    const data = await apiClient.get(`${API_URL}/details/${mid}`, { signal });

    return data;
  });

  const addMerchant = createApiCall(async ({ signal }, newMerchant) => {
    const {
      data: { merchant },
    } = await apiClient.post(API_URL, newMerchant);

    return merchant;
  });

  const editMerchant = createApiCall(async ({ signal }, id, newMerchant) => {
    const {
      data: { merchant },
    } = await apiClient.put(`${API_URL}/${id}`, newMerchant);

    return merchant;
  });

  return { getMerchants, getMerchantDetails, addMerchant, editMerchant };
}
