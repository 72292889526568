import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import React from 'react';

type Props = {
  originalToggleChecked: boolean;
  handleToggleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function DisplayOriginalSwitch({
  originalToggleChecked,
  handleToggleClick,
}: Props) {
  return (
    <>
      <GridToolbarContainer>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={originalToggleChecked}
                onChange={handleToggleClick}
              />
            }
            label={
              <Typography variant="button" color="#1976d2">
                Display Original Data
              </Typography>
            }
          />
        </FormGroup>
      </GridToolbarContainer>
    </>
  );
}

export default DisplayOriginalSwitch;
