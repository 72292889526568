import { AppBar } from '@mui/material';
import { Container, Toolbar, Typography, Button } from '@mui/material';
import { Outlet, Link } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { useContext } from 'react';
import { AuthContext } from './context/authContext';

import InboxApp from './components/Inbox';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './index.css';

function App() {
  const { logout, isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <CssBaseline />
      <AppBar position="static">
        <Container sx={{ minWidth: '100%' }}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Admin panel
            </Typography>
            {isAuthenticated && (
              <>
                <InboxApp />
                <Link to="/offer-group" className="navigation">
                  <Button color="inherit">Offer group</Button>
                </Link>
                <Link to="/logs" className="navigation">
                  <Button color="inherit">Logs</Button>
                </Link>
                <Link to="/postback" className="navigation">
                  <Button color="inherit">Postback</Button>
                </Link>
                <Link to="/offers" className="navigation">
                  <Button color="inherit">Offers</Button>
                </Link>
                <Link to="/domains" className="navigation">
                  <Button color="inherit">Domains</Button>
                </Link>
                <Link to="/traffic" className="navigation">
                  <Button color="inherit">Traffic Sources</Button>
                </Link>
                <Link to="/merchants" className="navigation">
                  <Button color="inherit">Merchants</Button>
                </Link>
                <Link to="/affiliates" className="navigation">
                  <Button color="inherit">Affiliates</Button>
                </Link>
                <Link to="/products" className="navigation">
                  <Button color="inherit">Products</Button>
                </Link>
                <Link to="/sovrn" className="navigation">
                  <Button color="inherit">Sovrn</Button>
                </Link>
                <Link to="/hp-reports" className="navigation">
                  <Button color="inherit">Hyprmotion</Button>
                </Link>
                <Link to="/iron-dome" className="navigation">
                  <Button color="inherit">Iron Dome</Button>
                </Link>
                <Button color="inherit" onClick={logout}>
                  Log out
                </Button>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Container sx={{ minWidth: '100%' }}>
        <Outlet></Outlet>
      </Container>
    </>
  );
}

export default App;
