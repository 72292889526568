import React from 'react';
import SimpleToolbar from '../../../../components/SimpleToolbar';
import { SovrnReportWithRows } from '../../types';

type Props = {
  report: SovrnReportWithRows | null;
};

const SingleSovrnToolbar = ({ report }: Props) => {
  const exportOptions: any = {
    xmptrk: {
      date: 'date',
      merchantId: 'merchantId',
      merchantName: 'merchantName',
      clickId: 'clickId',
      generatedValue: 'generatedValue',
      clicks: 'clicks',
      cpc: 'cpc',
      revenue: 'revenue',
      conversion: 'conversion',
    },
    eagleview: {
      date: 'date',
      SID: 'SID',
      generatedValue: 'generatedValue',
      conversion: 'conversion',
      payout: 'payout',
    },
  };

  const {
    account = '',
    tag = '',
    date = '',
    endpoint = 'xmptrk',
  } = report ?? {};

  const exportName = `${account}_${tag}_${date}`;
  const options = exportOptions[endpoint];

  return (
    <SimpleToolbar
      allowExport
      rawExportData={report?.rows ?? []}
      exportOptions={options}
      exportFileName={exportName}
    />
  );
};

export default SingleSovrnToolbar;
