import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { MenuItem, TextField, Typography } from '@mui/material';
import { OfferGroupTraffic } from '../types';
import LoadingButton from '../../../components/LoadingButton';
import { TrafficSource } from '../../traffic/types';

type Props = {
  handleClose: () => any;
  onSubmit: (newData: OfferGroupTraffic) => any;
  trafficData: OfferGroupTraffic;
  trafficSource: TrafficSource;
};

const EditGroupTraffic = ({
  handleClose,
  trafficData,
  onSubmit,
  trafficSource,
}: Props) => {
  const [values, setValues] = useState(trafficData);

  const [loading, setLoading] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit({ id: values.id, clickId: values.clickId });
    setLoading(false);
  };

  return (
    <SimpleModal open={true} handleClose={handleClose}>
      <Typography variant="h6">Edit traffic source data</Typography>
      <TextField
        value={values.clickId}
        label="Click Id"
        onChange={onChange}
        name="clickId"
        select
      >
        {trafficSource.parameterOptions.map((p) => (
          <MenuItem key={p.tag} value={p.tag}>
            {p.tag}
          </MenuItem>
        ))}
      </TextField>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default EditGroupTraffic;
