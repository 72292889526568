import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useCustomOffer from '../../offerGroup/useCustomOffers';
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const SingleCustomReport = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const client = useCustomOffer();

  const init = () => {
    const apiCall = client.getReportRows();
    (async () => {
      setLoading(true);
      try {
        const res = await apiCall.call(params.id);
        setRows(res);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    })();

    return apiCall.abort;
  };

  useEffect(() => init(), [params.id]);

  const cols: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
    },
    {
      field: 'merchantName',
      headerName: 'M. Name',
      flex: 1,
    },
    {
      field: 'clickId',
      headerName: 'Click Id',
      flex: 2,
    },
    {
      field: 'revenue',
      headerName: 'Revenue',
      flex: 1,
    },
  ];

  return (
    <Box mt={2}>
      <DataGrid
        rows={rows}
        loading={loading}
        autoHeight
        columns={cols}
        getRowId={(r) => r.clickId}
      />
    </Box>
  );
};

export default SingleCustomReport;
