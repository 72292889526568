import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import SimpleModal from '../../../../../components/SimpleModal';
import { Typography, Button, Box } from '@mui/material';

function DateFilter({ open, handleClose, onSubmit, initialData }) {
  const [dateRange, setDateRange] = useState([initialData]);

  const handleChange = newValue => {
    setDateRange(newValue);
  };

  const clearDateFilter = () => {
    const clearedDate = [
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ];
    setDateRange(clearedDate);
    onSubmit(clearedDate[0]);
  };

  const saveDateFilter = () => {
    onSubmit(dateRange[0]);
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Filter table by date</Typography>
      <Typography variant="body2">Export feature is also affected by this date range </Typography>

      <DateRange
        editableDateInputs={true}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        onChange={item => handleChange([item.selection])}
      />
      <Box display="flex" alignItems="center" gap={2}>
        <Button variant="contained" onClick={saveDateFilter} sx={{ flex: 1 }}>
          Save
        </Button>
        <Button variant="outlined" onClick={clearDateFilter} sx={{ flex: 1 }}>
          Clear selection
        </Button>
      </Box>
    </SimpleModal>
  );
}

export default DateFilter;
