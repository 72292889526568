import React, { useState } from 'react';
import { SovrnReport, SovrnReportRow } from '../types';
import useSovrn from '../useSovrn';
import { Alert, Button, Typography } from '@mui/material';
import CSVUploader, { CSVUploadResult } from '../../../components/CsvUploader';
import SimpleModal from '../../../components/SimpleModal';

type Props = {
  id: SovrnReport['_id'];
  open: boolean;
  handleClose: () => any;
};

const OverwriteGenerated = ({ id, open, handleClose }: Props) => {
  const [formattedCsv, setFormattedCsv] = useState<SovrnReportRow[]>([]);
  const { overwriteReportWithGenerated } = useSovrn();

  const onSubmit = async () => {
    await overwriteReportWithGenerated().call(id, formattedCsv);
    handleClose();
  };

  const onChange = (csvResult: CSVUploadResult) => {
    const res = formatCsv(csvResult.data);
    setFormattedCsv(res);
  };

  const formatCsv = (data: CSVUploadResult['data']) => {
    const header = data[0];
    const rows = data.slice(1);

    const zip = rows.map((row) => {
      const entries = header.map((h, id) => [h, row[id] ?? '']);
      const withoutEmptyCols = entries.filter((e) => e[0]);

      return Object.fromEntries(withoutEmptyCols);
    });

    const filteredZip = zip.filter((ob) => Object.values(ob).some(Boolean));

    return filteredZip;
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6" gutterBottom>
        Overwrite Report with Generated Values
      </Typography>
      <Alert severity="warning">
        As this is an overwrite, review the uploaded CSV before submitting
      </Alert>
      <CSVUploader onChange={onChange} />
      <Button variant="contained" onClick={onSubmit}>
        Submit
      </Button>
    </SimpleModal>
  );
};

export default OverwriteGenerated;
