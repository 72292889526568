import React, { useState } from 'react';
import { Card, Typography, List, ListItem, Divider } from '@mui/material';
import { MerchantFeedData } from '../../types';

import DownloadIcon from '@mui/icons-material/Download';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import moment from 'moment';

import TooltipIconButton from '../../../../components/TooltipIconButton';
import DialogBox from '../../../../components/DialogBox';
import useFeeds from '../../useFeeds';

import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingOverlay from '../../../../components/LoadingOverlay';

type Props = {
  merchant?: MerchantFeedData;
  handleDownload: Function;
  onRefresh: () => Promise<any>;
};

const MerchantDetails = ({ merchant, handleDownload, onRefresh }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { regenerateFeed } = useFeeds();

  const formatDate = (date?: Date) => {
    if (!date) return;

    return moment(date).format('DD/MM/YYYY HH:mm Z');
  };

  const formatSizeToMb = (size?: number) => {
    if (!size) return;

    return (size = size / (1024 * 1024)).toFixed(2);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegenerateFeeds = async () => {
    const merchantData = {
      merchantId: merchant?.mid,
      merchantName: merchant?.merchantName,
    };

    const res = await regenerateFeed().call(merchantData);

    if (res === 200) {
      console.log('res >>>', res);
      setOpen(true);
    }
  };

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await onRefresh();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ position: 'relative' }}>
      <LoadingOverlay loading={loading} loaderSize={40}/>
      <List>
        <ListItem>
          <Typography variant="body1">
            Updated at: {formatDate(merchant?.updatedAt)}
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            File Size: {formatSizeToMb(merchant?.size)} MB
          </Typography>
        </ListItem>
      </List>

      <Divider />

      <ListItem>
        <TooltipIconButton
          title="Download feed file"
          icon={<DownloadIcon />}
          onClick={handleDownload}
        />
        <Typography variant="body2">Download File</Typography>
        <TooltipIconButton
          title="Regenerate feed file"
          icon={<ManageHistoryIcon />}
          onClick={handleRegenerateFeeds}
        />
        <Typography variant="body2">Regenerate feed file</Typography>
        <TooltipIconButton
          title="Refresh"
          icon={<RefreshIcon />}
          onClick={handleRefresh}
        />
        <Typography variant="body2">Refresh</Typography>
      </ListItem>
      <DialogBox openDialog={open} handleClose={handleClose} />
    </Card>
  );
};

export default MerchantDetails;
