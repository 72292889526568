import useApi from '../../hooks/useApi';
import {
  AddQualityReportsDTO,
  EditClicksDTO,
  QualityEntry,
  QualityEntries,
} from './SingleAffiliate/SingleQualityReport/types';
import { AddAffiliateDto, Affiliate } from './types';

const BASE_URL = '/api/affiliate';

const useAffiliate = () => {
  const { api, createApiCall } = useApi();

  const getAffiliates = createApiCall(async ({ signal }) => {
    const {
      data: { affiliates },
    } = await api.get(BASE_URL, { signal });

    return affiliates;
  });

  const addAffiliate = createApiCall(
    async ({ signal }, newAffiliate: AddAffiliateDto) => {
      const {
        data: { affiliate },
      } = await api.post(BASE_URL, newAffiliate);
      return affiliate;
    }
  );

  const editAffiliate = createApiCall(
    async (
      { signal },
      affiliateId: Affiliate['_id'],
      newAffiliate: Affiliate
    ) => {
      const {
        data: { affiliate },
      } = await api.put(`${BASE_URL}/${affiliateId}`, newAffiliate);

      return affiliate;
    }
  );

  const deleteAffiliate = createApiCall(
    async ({ signal }, affiliateId: Affiliate['_id']) => {
      await api.delete(`${BASE_URL}/${affiliateId}`);
    }
  );

  const getSingleAffiliate = createApiCall(
    async ({ signal }, affiliateId: Affiliate['_id']) => {
      const {
        data: { affiliate },
      } = await api.get(`${BASE_URL}/${affiliateId}`, { signal });
      return affiliate;
    }
  );

  const getAffiliateStats = createApiCall(
    async ({ signal }, affiliateId: QualityEntry['affiliateId']) => {
      const {
        data: { qualityEntries },
      } = await api.get(`${BASE_URL}/${affiliateId}/stats`);
      return qualityEntries;
    }
  );

  const addAffiliateStats = createApiCall(
    async ({ signal }, newQualityReport: AddQualityReportsDTO) => {
      const {
        data: { qualityEntry },
      } = await api.post(`${BASE_URL}/stats`, newQualityReport);
      return qualityEntry;
    }
  );

  const editAffiliateStats = createApiCall(
    async (
      { signal },
      qualityEntryId: QualityEntry['_id'],
      newQualityEntry: QualityEntry
    ) => {
      const {
        data: { qualityEntry },
      } = await api.put(`${BASE_URL}/${qualityEntryId}/stats`, newQualityEntry);

      return qualityEntry;
    }
  );

  const deleteAffiliateStats = createApiCall(
    async ({ signal }, qualityEntryId: QualityEntry['_id']) => {
      await api.delete(`${BASE_URL}/${qualityEntryId}/stats`);
    }
  );

  const getSingleQualityEntry = createApiCall(
    async ({ signal }, qualityEntryId: QualityEntry['_id']) => {
      const {
        data: { qualityEntry },
      } = await api.get(`${BASE_URL}/${qualityEntryId}/qualityEntry`);
      return qualityEntry;
    }
  );

  const deleteSingleQualityEntry = createApiCall(
    async (
      { signal },
      qualityEntryId: QualityEntry['_id'],
      qualityEntriesId: QualityEntries['_id']
    ) => {
      const {
        data: { qualityEntries },
      } = await api.delete(
        `${BASE_URL}/${qualityEntryId}/qualityEntry/${qualityEntriesId}`
      );
      return qualityEntries;
    }
  );

  const editSingleQualityEntry = createApiCall(
    async (
      { signal },
      qualityEntryId: QualityEntry['_id'],
      qualityEntriesId: QualityEntries['_id'],
      newQualityEntryReport: QualityEntries
    ) => {
      const {
        data: { qualityEntries },
      } = await api.put(
        `${BASE_URL}/${qualityEntryId}/qualityEntry/${qualityEntriesId}`,
        newQualityEntryReport
      );

      return qualityEntries;
    }
  );

  const editSingleQualityEntryClicks = createApiCall(
    async (
      { signal },
      qualityEntryId: QualityEntry['_id'],
      editClicksParams: EditClicksDTO
    ) => {
      const {
        data: { qualityEntries },
      } = await api.put(
        `${BASE_URL}/${qualityEntryId}/clicks`,
        editClicksParams
      );

      return qualityEntries;
    }
  );

  return {
    getAffiliates,
    addAffiliate,
    editAffiliate,
    deleteAffiliate,
    getSingleAffiliate,
    getAffiliateStats,
    addAffiliateStats,
    editAffiliateStats,
    deleteAffiliateStats,
    getSingleQualityEntry,
    deleteSingleQualityEntry,
    editSingleQualityEntry,
    editSingleQualityEntryClicks,
  };
};

export default useAffiliate;
