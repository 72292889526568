import React, { useEffect, useState } from 'react';
import useSovrn from '../useSovrn';
import { SovrnReport } from '../types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Alert, Box, Chip } from '@mui/material';
import moment from 'moment';
import TooltipIconButton from '../../../components/TooltipIconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OverwriteReport from '../SingleSovrn/OverwriteReport';
import UploadIcon from '@mui/icons-material/Upload';
import SovrnToolbar from '../SovrnToolbar';
import ConfirmModal from '../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';

type Props = {};

const SovrnTable = (props: Props) => {
  const [reports, setReports] = useState<SovrnReport[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [idToOverwrite, setIdToOverwrite] = useState<SovrnReport['_id']>('');

  const { getSovrnReports, deleteReport, populateReport } = useSovrn();

  const init = () => {
    const api = getSovrnReports();

    (async () => {
      setLoading(true);
      try {
        const res = await api.call();
        setReports(res);
      } catch (error) {}
      setLoading(false);
    })();

    return api.abort;
  };

  const closeModal = () => {
    setOpen(false);
    setIdToOverwrite('');
  };

  const openModal = (id: string) => {
    setOpen(true);
    setIdToOverwrite(id);
  };

  const onDelete = async (id: SovrnReport['_id']) => {
    setLoading(true);
    await deleteReport().call(id);
    const newReports = reports.filter((r) => r._id !== id);
    setReports(newReports);
    setLoading(false);
  };

  const onPopulate = async (id: SovrnReport['_id']) => {
    setLoading(true);
    await populateReport().call(id);
    const newReports = reports.map((r) => {
      if (r._id === id) return { ...r, populated: true };
      return r;
    });
    setReports(newReports);
    setLoading(false);
  };

  const onAddSovrnReportDone = (newReport: SovrnReport) => {
    const newReports = [newReport, ...reports];
    setReports(newReports);
  };

  const onFillRevenueDone = (newReports: SovrnReport[]) => {
    const map = Object.fromEntries(newReports.map((n) => [n._id, n]));
    const arr = reports.map((r) => {
      const newval = map[r._id];

      if (newval) return newval;
      return r;
    });

    setReports(arr);
  };

  useEffect(() => {
    return init();
  }, []);

  const cols: GridColDef<SovrnReport>[] = [
    {
      headerName: 'Report date',
      field: 'date',
      flex: 1,
      renderCell: (params) => {
        const destination = params.row._id;
        return <Link to={destination}>{params.row.date}</Link>;
      },
    },
    {
      headerName: 'Tag',
      field: 'tag',
      flex: 1,
    },
    {
      headerName: 'Account',
      field: 'account',
      flex: 1,
      renderCell: (params) => {
        return <Chip label={params.row.account || 'sovrn'} />;
      },
    },
    {
      headerName: 'Endpoint',
      field: 'endpoint',
      flex: 1,
      valueFormatter: (params) => {
        const labels: any = {
          xmptrk: 'xmptrk.com',
          eagleview: 'eagleview.report',
        };
        return labels[params.value] ?? '';
      },
    },
    {
      headerName: 'Populated?',
      field: 'populated',
      flex: 1,
      renderCell: (params) => {
        const { populated } = params.row;

        const color = populated ? 'success' : 'default';
        const label = populated ? 'Yes' : 'No';

        return <Chip label={label} color={color} />;
      },
    },
    {
      headerName: 'Created at',
      field: 'createdAt',
      flex: 1,
      valueFormatter: (row) => {
        return moment.utc(row.value).format('YYYY-MM-DD HH:mm') + ' UTC';
      },
    },
    {
      headerName: 'Updated at',
      field: 'updatedAt',
      flex: 1,
      valueFormatter: (row) => {
        return moment.utc(row.value).format('YYYY-MM-DD HH:mm') + ' UTC';
      },
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      renderCell: (params) => {
        const openReport = () => {
          const urlMap: any = {
            xmptrk: process.env.REACT_APP_SOVRN_REPORT_URL!,
            eagleview: process.env.REACT_APP_EAGLE_REPORT_URL!,
          };

          const baseUrl = urlMap[params.row.endpoint] ?? urlMap.xmptrk;

          const url = new URL(baseUrl);
          url.searchParams.set('date', params.row.date);

          const link = document.createElement('a');

          link.href = url.toString();
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
        };

        return (
          <Box display="flex" flexDirection="row">
            <TooltipIconButton
              title="Open report"
              icon={<OpenInNewIcon />}
              onClick={openReport}
            />
            <TooltipIconButton
              title="Overwrite"
              icon={<UploadIcon />}
              onClick={() => {
                openModal(params.row._id);
              }}
            />
            <TooltipIconButton
              title="Populate"
              icon={<RefreshIcon />}
              onClick={() => {
                onPopulate(params.row._id);
              }}
            />
            <ConfirmModal
              icon={<DeleteIcon />}
              title="Delete"
              onConfirm={() => onDelete(params.row._id)}
            >
              <Alert severity="error">
                Are you sure you want to delete this report
              </Alert>
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <DataGrid
        rows={reports}
        loading={loading}
        columns={cols}
        autoHeight
        checkboxSelection
        onRowSelectionModelChange={(e) => setSelectedRows(e as string[])}
        getRowId={(r) => r._id}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        slots={{
          toolbar: () => {
            return (
              <SovrnToolbar
                onAddSovrnReportDone={onAddSovrnReportDone}
                onFillRevenueDone={onFillRevenueDone}
                selectedRows={selectedRows}
                setLoading={setLoading}
              ></SovrnToolbar>
            );
          },
        }}
      ></DataGrid>
      <OverwriteReport
        id={idToOverwrite}
        open={open}
        handleClose={closeModal}
      />
    </Box>
  );
};

export default SovrnTable;
