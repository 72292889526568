import React from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Button } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';
import DisplayOriginalSwitch from '../../../../../components/DisplayOriginalSwitch';
import MonthlyReportDateFilter from '../../../../../components/MonthlyReportDateFilter';

type Props = {
  handleOpenDateRangePicker: () => any;
  originalToggleChecked: boolean;
  handleToggleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function MonthlyReportsToolbar({
  handleOpenDateRangePicker,
  originalToggleChecked,
  handleToggleClick,
}: Props) {
  return (
    <>
      <MonthlyReportDateFilter
        handleOpenDateRangePicker={handleOpenDateRangePicker}
      />
      {/* <DisplayOriginalSwitch
        originalToggleChecked={originalToggleChecked}
        handleToggleClick={handleToggleClick}
      /> */}
    </>
  );
}
