import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import useMerchants from '../pages/merchants/useMerchants';
import moment from 'moment';
import { Box, Button, Chip } from '@mui/material';

const RegenerationHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState('false');
  const { getMerchantDetails } = useMerchants();

  const params = useParams();

  const init = async () => {
    setLoading(true);
    const instance = getMerchantDetails();

    (async function () {
      try {
        const res = await instance.call(params.merchantId);
        setData(res.data.details);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();

    return instance.abort;
  };

  const handleRefresh = async () => {
    await init();
  };

  const tableCols = [
    {
      headerName: 'Merchant Name',
      field: 'merchantName',
      flex: 1,
    },
    {
      headerName: 'Regenerate Date',
      field: 'regenerateRequestDate',
      flex: 1,
      renderCell: (params) => {
        return moment(params.row.regenerateRequestDate).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      },
      valueGetter: ({ row }) => {
        return new Date(row.regenerateRequestDate);
      },
    },
    {
      headerName: 'Status',
      field: 'regenerateStatus',
      flex: 1,
      renderCell: ({ row }) => {
        const colors = {
          done: 'success',
          pending: 'default',
          uploading: 'primary',
          regenerating: 'warning',
        };
        
        const { regenerateStatus } = row
        const color = colors[regenerateStatus];

        return <Chip label={regenerateStatus} color={color} />;
      },
    },
  ];

  useEffect(() => {
    init();
  }, []);

  return (
    <Box mt={4}>
      <Button onClick={handleRefresh} color="primary">
        REFRESH
      </Button>
      <DataGrid
        autoHeight
        rows={data}
        columns={tableCols}
        getRowId={(r) => r._id}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'regenerateRequestDate', sort: 'desc' }],
          },
        }}
      />
    </Box>
  );
};

export default RegenerationHistory;
