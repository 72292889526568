import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { Button, MenuItem, TextField, Typography } from '@mui/material';
import { Affiliate } from '../types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onEditAffiliate: (
    affiliateId: Affiliate['_id'],
    newAffiliate: Affiliate
  ) => any;
  initialValues: Affiliate;
};

const EditAffiliate = ({
  initialValues,
  open,
  handleClose,
  onEditAffiliate,
}: Props) => {
  const [values, setValues] = useState(initialValues);

  console.log('editAffiliateValues >>>', values);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues(() => ({
      ...values,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    await onEditAffiliate(initialValues._id, {
      ...values,
      isActive: !!values.isActive,
    });
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit affiliate</Typography>
      <TextField
        fullWidth
        name="name"
        onChange={handleChange}
        label="Name"
        value={values.name}
      />
      <TextField
        select
        fullWidth
        name="isActive"
        onChange={handleChange}
        label="Active status"
        value={+values.isActive}
      >
        <MenuItem value={1}>Active</MenuItem>
        <MenuItem value={0}>Inactive</MenuItem>
      </TextField>
      <TextField
        fullWidth
        name="prefix"
        onChange={handleChange}
        label="Affiliate Tracking Prefix"
        value={values.prefix}
        inputProps={{ maxLength: 3 }}
        helperText="Maximum of 3 characters only"
      />
      <Button color="primary" variant="contained" onClick={onSubmit} fullWidth>
        Submit
      </Button>
    </SimpleModal>
  );
};

export default EditAffiliate;
