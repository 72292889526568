import { create } from 'domain';
import useApi from '../../hooks/useApi';
import { BlacklistedProduct } from './types';

const BASE_URL = '/api/blacklistedProducts';

const useBlacklistProducts = () => {
  const { api, createApiCall } = useApi();

  const getBlacklistedProducts = createApiCall(async ({ signal }) => {
    const {
      data: { blacklistedProducts },
    } = await api.get(BASE_URL, { signal });

    return blacklistedProducts;
  });

  const addBlacklistedProduct = createApiCall(
    async ({ signal }, newBlacklistedProduct: BlacklistedProduct) => {
      const {
        data: { blacklistedProduct },
      } = await api.post(BASE_URL, newBlacklistedProduct);

      return blacklistedProduct;
    }
  );

  const editBlacklistedProduct = createApiCall(
    async (
      { signal },
      blacklistedProductId: BlacklistedProduct['_id'],
      newBlacklistedProduct: BlacklistedProduct
    ) => {
      const {
        data: { blacklistedProduct },
      } = await api.put(
        `${BASE_URL}/${blacklistedProductId}`,
        newBlacklistedProduct
      );

      return blacklistedProduct;
    }
  );

  const deleteBlacklistedProduct = createApiCall(
    async ({ signal }, blacklistedProductId: BlacklistedProduct['_id']) => {
      await api.delete(`${BASE_URL}/${blacklistedProductId}`);
    }
  );

  return {
    getBlacklistedProducts,
    addBlacklistedProduct,
    editBlacklistedProduct,
    deleteBlacklistedProduct,
  };
};

export default useBlacklistProducts;
