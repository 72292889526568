import { useEffect, useState, useMemo, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Card, Chip, Box, Typography } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { Link } from 'react-router-dom';

import usePostback from '../usePostback';
import { PostbackContext } from '../context';
import PostbackTableToolbar from './Toolbar';
import ConfirmModal from '../../../components/ConfirmModal';

function PostbackTable() {
  const { getPostbacks, deletePostback } = usePostback();

  const [postbacks, setPostbacks] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');
  const [loading, setLoading] = useState(false);
  const { traffic } = useContext(PostbackContext);

  const onAddPostbackDone = (newPostback) => {
    const postbackArray = [newPostback].flat();
    setPostbacks([...postbacks, ...postbackArray]);
  };

  const onEditPostbackDone = (newPostback) => {
    const newPostbacks = postbacks.map((item) => {
      if (newPostback._id === item._id) return newPostback;
      return item;
    });
    setPostbacks(newPostbacks);
  };

  const onDeletePostback = async (id) => {
    console.log(id);
    try {
      setLoading(true);
      await deletePostback().call(id);
      const newPostbacks = postbacks.filter((item) => item._id !== id);
      setPostbacks(newPostbacks);
    } finally {
      setLoading(false);
    }
  };

  const init = () => {
    const apiCall = getPostbacks();

    (async function () {
      try {
        const postbacks = await apiCall.call();
        setPostbacks(postbacks);
      } catch (e) {
        console.log('Fail fetching postbacks', e);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const trafficMap = useMemo(() => {
    return traffic.reduce((acc, item) => {
      acc[item._id] = item;
      return acc;
    }, {});
  }, [traffic]);

  const selectedRowData = useMemo(() => {
    return postbacks.find((item) => item._id === selectedRow[0]);
  }, [selectedRow, postbacks]);

  const tableCols = generateTableCols(trafficMap, onDeletePostback);

  console.log('postbacks >>>', postbacks);

  return (
    <Card>
      <DataGrid
        autoHeight
        rows={postbacks}
        columns={tableCols}
        getRowId={(r) => r._id}
        getRowHeight={() => 'auto'}
        onRowSelectionModelChange={setSelectedRow}
        slots={{
          toolbar: () => (
            <PostbackTableToolbar
              selectedRow={selectedRowData}
              onAddPostbackDone={onAddPostbackDone}
              onEditPostbackDone={onEditPostbackDone}
            />
          ),
        }}
        loading={loading}
      />
    </Card>
  );
}

const generateTableCols = (trafficMap, onDelete) => {
  const tableCols = [
    {
      headerName: 'Destination ID',
      field: 'destinationId',
      flex: 1,
    },
    {
      headerName: 'Destination Name',
      field: 'destinationName',
      flex: 2,
    },
    {
      headerName: 'Conditions',
      field: 'conditionRegex',
      flex: 1.5,
      renderCell: ({ row }) => {
        const { conditionRegex } = row;

        return conditionRegex.map(({ parameter, value, type }, index) => {
          const typeLabels = ['', 'equals', 'does not contain', 'contains'];

          if (type === 0 || parameter === '' || value === '') {
            return <Chip label="No conditions" />;
          }

          const label = `${parameter} ${typeLabels[type]} ${value}`;

          return <Chip key={index} label={label} sx={{ mr: 1 }} />;
        });
      },
    },
    {
      headerName: 'Destination URL',
      field: 'destinationUrl',
      flex: 3,
      renderCell: (params) => {
        const { destinationUrl } = params.row;
        return (
          <div>
              {destinationUrl.map((destination,index) => {
                return <Box sx={{mb: 1 , mt: 1}}>{index + 1}.{destination}</Box>
              })}
          </div>
        );
      },
    },
    {
      headerName: 'Traffic source',
      field: 'trafficSourceId',
      flex: 1,
      renderCell: (params) => {
        const { trafficSourceId } = params.row;
        if (trafficSourceId.length < 1)
          return (
        <>
            <Typography sx={{ mt:0.7 , mr: 1}}>1. {" "} </Typography>
          <Chip label="No traffic source" color="warning" /></>)
    
        return (
          <div>
              {trafficSourceId.map((traffic, index) => {
                const label = trafficMap[traffic]?.name;
    
                if (!label) return null;
    
                return (
                  <Link
                    key={index}
                    to={`/traffic/${traffic}`}
                    style={{ display: 'flex', width: '100%', textDecoration: 'none' }}
                  >
                    <Typography sx={{ mt:0.7 , mr: 1}}>{index + 1}. {" "} </Typography>
                   <Chip
                      key={index}
                      label={label}
                      color="info"
                      sx={{ cursor: 'pointer', marginRight: '8px', marginBottom: '8px' }}
                    />
                  </Link>
                );
              })}
          </div>
        );
      },
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      renderCell: (params) => {
        return (
          <ConfirmModal
            onConfirm={() => {
              onDelete(params.row._id);
            }}
            title="Delete"
            icon={<DeleteIcon />}
          >
            Do you want to delete this postback?
          </ConfirmModal>
        );
      },
    },
  ];

  return tableCols;
};

export default PostbackTable;
