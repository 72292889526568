import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import CSVUploader, { CSVUploadResult } from '../../../components/CsvUploader';
import { Typography } from '@mui/material';
import LoadingButton from '../../../components/LoadingButton';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (data: Record<string, any>[]) => any;
};

const ImportCustomOffer = ({ open, handleClose, onSubmit }: Props) => {
  const [data, setData] = useState<CSVUploadResult | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!data) return;

    try {
      setLoading(true);
      const [head, ...body] = data.data as string[][];

      const headers = head.map((h) => h.trim());

      const filteredbody = body.filter((b) =>
        b.some((i) => i != null && i !== '')
      );

      const parsedbody = filteredbody.map((b: string[]) => {
        const entries = b.map((value, i) => {
          const currenthead = headers[i];
          return [currenthead, value.trim()];
        });

        return Object.fromEntries(entries);
      });

      await onSubmit(parsedbody);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Import custom offers</Typography>
      <CSVUploader onChange={setData} />
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default ImportCustomOffer;
