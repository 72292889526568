import { useContext, useState, useEffect, useMemo } from 'react';
import { SingleAffiliateContext } from '../context';
import { useSharedState } from '../sharedContext';
import { Box, Card, Chip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { QualityEntry } from '../SingleQualityReport/types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import SingleAffiliateToolbar from '../SingleAffiliateToolbar';
import useAffiliate from '../../useAffiliate';
import ConfirmModal from '../../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TooltipIconButton from '../../../../components/TooltipIconButton';
import { Affiliate } from '../../types';

type Props = {
  affiliate: Affiliate;
};

function AffiliateStatsTable({ affiliate }: Props) {
  const { qualityEntries, setQualityEntries } = useSharedState();
  const { getAffiliateStats, deleteAffiliateStats, editAffiliateStats } =
    useAffiliate();

  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const selectedQualityEntry = useMemo(() => {
    return qualityEntries.find((item) => item._id === selectedRow[0]);
  }, [qualityEntries, selectedRow]);

  const init = () => {
    const apiCall = getAffiliateStats();

    (async function () {
      try {
        setLoading(true);
        const qualityEntries = await apiCall.call(affiliate?._id);
        setQualityEntries(qualityEntries);
      } catch (error) {
        console.log('error getting quality entries');
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  const onAddQualityReportsDone = (newQualityEntry: QualityEntry) => {
    const newQualityEntryArray = [newQualityEntry].flat();
    setQualityEntries([...qualityEntries, ...newQualityEntryArray]);
  };

  const onEditQualityReportsDone = (newQualityEntry: QualityEntry) => {
    const newQualityEntries = qualityEntries.map((item) => {
      if (item._id === newQualityEntry._id) return newQualityEntry;
      return item;
    });

    setQualityEntries(newQualityEntries);
  };

  const onDeleteQualityEntry = async (qualityEntryId: QualityEntry['_id']) => {
    try {
      setLoading(true);
      await deleteAffiliateStats().call(qualityEntryId);
      const newQualityEntries = qualityEntries.filter(
        (item) => item._id !== qualityEntryId
      );
      setQualityEntries(newQualityEntries);
    } catch (error) {
      console.log('Error deleting quality entry: ', error);
    } finally {
      setLoading(false);
    }
  };

  const onPublishQualityEntry = async (qualityEntryId: QualityEntry['_id']) => {
    try {
      setLoading(true);
      const publishedEntries = await editAffiliateStats().call(qualityEntryId, {
        isPublished: true,
      });
      const newQualityEntries = qualityEntries.map((item) => {
        if (item._id === publishedEntries._id) return publishedEntries;
        return item;
      });
      setQualityEntries(newQualityEntries);
    } catch (error) {
      console.log('Error publishing status');
    } finally {
      setLoading(false);
    }
  };

  const onOpenReportUrl = (qualityEntry: QualityEntry) => {
    const { apiKey } = affiliate!;
    const { date } = qualityEntry;

    const dateString = new Date(date).toISOString().split('T')[0];

    const baseUrl = process.env.REACT_APP_API_URL ?? '';
    const reportUrl = `${baseUrl}/api/affiliate/reporting/?apiKey=${apiKey}&startDate=${dateString}&endDate=${dateString}`;

    const link = document.createElement('a');
    link.href = reportUrl;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    return init();
  }, []);

  const tableCols = generateTableCols(
    onDeleteQualityEntry,
    onPublishQualityEntry,
    onOpenReportUrl,
    affiliate
  );

  return (
    <Card>
      <DataGrid
        autoHeight
        rows={qualityEntries}
        columns={tableCols}
        getRowId={(r) => r._id}
        onRowSelectionModelChange={setSelectedRow}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        loading={loading}
        slots={{
          toolbar: () => (
            <SingleAffiliateToolbar
              onAddQualityReportsDone={onAddQualityReportsDone}
              onEditQualityReportsDone={onEditQualityReportsDone}
              selectedQualityReport={selectedQualityEntry}
            />
          ),
        }}
      />
    </Card>
  );
}

const generateTableCols = (
  onDelete: Function,
  onPublish: Function,
  onOpenReportUrl: Function,
  affiliate: Affiliate | null
) => {
  const tableCols: GridColDef[] = [
    {
      headerName: 'Date',
      field: 'date',
      flex: 1,
      renderCell: (params) => {
        const affiliatePrefix = affiliate?.prefix;
        const destination = `../qualityEntry/${params.row._id}`;
        const formatted = moment(params.row.date).utc().format('DD-MM-YYYY');

        return (
          <Link to={destination} state={{ prefix: affiliatePrefix }}>
            {formatted}
          </Link>
        );
      },
    },
    {
      headerName: 'Published Status',
      field: 'isPublished',
      flex: 1,
      renderCell: (params) => {
        const statusData = [
          {
            label: 'Not Published',
            color: 'default',
          },
          {
            label: 'Published',
            color: 'primary',
          },
        ] as const;

        const currentStatusData = statusData[+Boolean(params?.value)];

        return (
          <Chip
            label={currentStatusData.label}
            color={currentStatusData.color}
          />
        );
      },
    },
    {
      headerName: 'Action',
      field: '_id',
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            {params.row.isPublished === false && (
              <ConfirmModal
                iconButton
                icon={<PublishIcon />}
                title="Publish Quality Entry"
                onConfirm={() => {
                  onPublish(params.row._id);
                }}
              >
                Are you sure you want to publish this entry?
              </ConfirmModal>
            )}
            <TooltipIconButton
              title="Open report URL"
              icon={<OpenInNewIcon />}
              onClick={() => onOpenReportUrl(params.row)}
            />
            <ConfirmModal
              iconButton
              icon={<DeleteIcon />}
              title="Delete Quality Entry"
              onConfirm={() => {
                onDelete(params.row._id);
              }}
            >
              Are you sure you want to delete this entry? This process is
              irreversible
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  return tableCols;
};

export default AffiliateStatsTable;
