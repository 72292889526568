import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import AffiliateStatsTable from './AffiliateStatsTable';
import MonthlyReportsTable from './MonthlyReportsTable';
import SingleAffiliateProvider, { SingleAffiliateContext } from './context';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

function SingleAffiliateMainSection() {
  const { loading, affiliate } = useContext(SingleAffiliateContext);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" pt={5}>
        <CircularProgress size={36}></CircularProgress>
      </Box>
    );
  }

  if (!affiliate) {
    return (
      <Box mt={4}>
        <Alert severity="error">Unable to display this affiliate's data</Alert>
      </Box>
    );
  }

  const { name } = affiliate;

  return (
    <Box mt={4}>
      <Box mb={3}>
        <Link to="/affiliates">
          <Button startIcon={<ArrowBackIcon />}>Back</Button>
        </Link>
      </Box>
      <Typography variant="h4" gutterBottom>
        {name}
      </Typography>
      <MonthlyReportsTable affiliate={affiliate} />
      <AffiliateStatsTable affiliate={affiliate} />
    </Box>
  );
}

function SingleAffiliatePage() {
  return (
    <SingleAffiliateProvider>
      <SingleAffiliateMainSection />
    </SingleAffiliateProvider>
  );
}

export default SingleAffiliatePage;
