import React from 'react';
import { Button, CircularProgress } from '@mui/material';

function LoadingButton({ loading, children, ...rest }) {
  return (
    <Button disabled={loading} {...rest}>
      {loading ? <CircularProgress size={22} /> : children}
    </Button>
  );
}

export default LoadingButton;
