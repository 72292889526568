import { Grid, TextField, Box, MenuItem, Button, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useTraffic from '../useTraffic';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TooltipIconButton from '../../../components/TooltipIconButton';
import { Delete } from '@mui/icons-material';
import SimpleRadioGroup from '../../../components/RadioGroup';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import { Country, State, City, Location } from '../types';

type Props = {
  onChange: (param: { locations: Location[] }) => any;
  value: {
    type?: 'blacklist' | 'whitelist';
    locations: Location[];
  };
};

const CountrySelector = ({ onChange, value }: Props) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  const [selectedType, setSelectedType] =
    useState<Location['type']>('blacklist');
  const [selectedCountry, setSelectedCountry] = useState<Country | 'all'>(
    'all'
  );
  const [selectedState, setSelectedState] = useState<State | 'all'>('all');
  const [selectedCity, setSelectedCity] = useState<City | 'all'>('all');

  const locations = value.locations;

  const client = useTraffic();

  const initCountries = async () => {
    const res = await client.getCountries().call();
    setCountries(res);
  };

  const initStates = async (countryId: number) => {
    const res = await client.getStates().call(countryId);
    setStates(res);
  };

  const initCities = async (countryId: number, stateId: number) => {
    const res = await client.getCities().call(countryId, stateId);
    setCities(res);
  };

  const onCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === 'all') {
      setSelectedState('all');
      setSelectedCity('all');
      return setSelectedCountry(value);
    }

    const res = countries.find((c) => c.id === +value);
    setSelectedCountry(res!);

    initStates(+value);
  };

  const onStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === 'all') {
      setSelectedCity('all');
      return setSelectedState(value);
    }

    const res = states.find((c) => c.id === +value);
    setSelectedState(res!);

    initCities((selectedCountry as Country).id, +value);
  };

  const onCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === 'all') return setSelectedCity(value);

    const res = cities.find((c) => c.id === +value);
    setSelectedCity(res!);
  };

  const onTypeChange = (newValue: any) => {
    setSelectedType(newValue);
  };

  const addLocation = () => {
    const id = nanoid();
    const val = {
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      type: selectedType,
      id,
    };
    const newLocations = cloneDeep([...locations, val]);

    onChange({ locations: newLocations });
  };

  const deleteLocation = (id: string) => {
    const res = locations.filter((l) => l.id !== id);

    onChange({ locations: res });
  };

  const getRowId = (r: Location) => `${r.id}`;

  const cols: GridColDef<Location>[] = [
    {
      field: 'country',
      headerName: 'Country',
      flex: 1,
      valueGetter: ({ row }) => {
        const { country } = row;
        if (country === 'all') return 'All countries';
        return country.name;
      },
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1,
      valueGetter: ({ row }) => {
        const { state } = row;
        if (state === 'all') return 'All states';
        return state.name;
      },
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 1,
      valueGetter: ({ row }) => {
        const { city } = row;
        if (city === 'all') return 'All cities';
        return city.name;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) => {
        const val = params.row.type;

        if (val) return <Chip label={val} />;

        return <Chip label="None" color="error" />;
      },
    },
    {
      field: '',
      headerName: 'Actions',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <TooltipIconButton
            title="Delete"
            icon={<Delete />}
            onClick={() => deleteLocation(row.id)}
          ></TooltipIconButton>
        );
      },
    },
  ];

  const fields = [
    {
      value: 'whitelist',
      label: 'Whitelist',
    },
    {
      value: 'blacklist',
      label: 'Blacklist',
    },
  ];

  useEffect(() => {
    initCountries();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <DataGrid
            columns={cols}
            rows={locations}
            getRowId={getRowId}
            sx={{ height: 500 }}
          />
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Box mb={2}>
              <SimpleRadioGroup
                horizontal
                fields={fields}
                label="Block type"
                onChange={onTypeChange}
                value={selectedType!}
              ></SimpleRadioGroup>
            </Box>
            <TextField
              select
              fullWidth
              label="Country"
              value={selectedCountry === 'all' ? 'all' : selectedCountry.id}
              onChange={onCountryChange}
            >
              <MenuItem value="all">All countries</MenuItem>
              {countries.map((c) => {
                return <MenuItem value={c.id}>{c.name}</MenuItem>;
              })}
            </TextField>
            <TextField
              select
              fullWidth
              label="State"
              value={selectedState === 'all' ? 'all' : selectedState.id}
              onChange={onStateChange}
              disabled={!states.length || selectedCountry === 'all'}
            >
              <MenuItem value="all">All states</MenuItem>
              {states.map((c) => {
                return <MenuItem value={c.id}>{c.name}</MenuItem>;
              })}
            </TextField>
            <TextField
              select
              fullWidth
              label="City"
              value={selectedCity === 'all' ? 'all' : selectedCity.id}
              onChange={onCityChange}
              disabled={!cities.length || selectedState === 'all'}
            >
              <MenuItem value="all">All cities</MenuItem>
              {cities.map((c) => {
                return <MenuItem value={c.id}>{c.name}</MenuItem>;
              })}
            </TextField>
            <Button variant="contained" onClick={addLocation}>
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CountrySelector;
