


import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';


import SimpleToolbar from '../../../components/SimpleToolbar'
import AddAffiliate from '../AddAffiliate';
import useAffiliate from '../useAffiliate';
import { AddAffiliateDto, Affiliate } from '../types';
import EditAffiliate from '../EditAffiliate';

type Props = {
    onAddAffiliateDone: (newAffiliate: Affiliate) => any
    onEditAffiliateDone: (newAffiliate: Affiliate) => any
    selectedAffiliate?: Affiliate
}

const AffiliateToolbar = ({ selectedAffiliate, onEditAffiliateDone, onAddAffiliateDone }: Props) => {

    const { addAffiliate, editAffiliate } = useAffiliate()

    const onAddAffiliate = async (dto: AddAffiliateDto) => {
        const newAffiliate = await addAffiliate().call(dto)
        onAddAffiliateDone(newAffiliate)
    }

    const onEditAffiliate = async (affiliateId: string, dto: Affiliate) => {
        const newAffiliate = await editAffiliate().call(affiliateId, dto)
        onEditAffiliateDone(newAffiliate)
    }

    const modalButtons = [
        {
            name: 'add',
            label: 'Add',
            renderIf: true,
            icon: <AddIcon />,
            render: (open: boolean, handleClose: () => any) => {
                return <AddAffiliate open={open} handleClose={handleClose} onAddAffiliate={onAddAffiliate} />
            }
        },
        {
            name: 'edit',
            label: 'Edit',
            renderIf: !!selectedAffiliate,
            icon: <EditIcon />,
            render: (open: boolean, handleClose: () => any) => {
                return <EditAffiliate open={open} handleClose={handleClose} onEditAffiliate={onEditAffiliate} initialValues={selectedAffiliate!} />
            }
        }
    ]
    return (
        <SimpleToolbar modalButtons={modalButtons} />
    )
}

export default AffiliateToolbar