import useApi from "../../hooks/useApi";

const API_URL = '/api/hyprmotion';

export default function useHpReports() {
  const { api:apiClient, createApiCall } = useApi();

  const getData = createApiCall(async ({ signal }) => {
    const {
      data: { hpReports }
    } = await apiClient.get(API_URL, { signal });

    return hpReports;
  });

  const getReportById = createApiCall(async ({ signal } , id) => {
    const {
      data: { report }
    } = await apiClient.get(`${API_URL}/${id}`, { signal });

    return report;
  });


  return { getData, getReportById };
}

