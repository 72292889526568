import React, { useState, useEffect, useContext } from 'react';
import { SingleAffiliateContext } from '../context';
import { useSharedState } from '../sharedContext';
import {
  Card,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import ReportingDateFilter from './ReportingDateFilter';
import SimpleToolbar from '../../../../components/SimpleToolbar';
import MonthlyReportsToolbar from './MonthlyReportsToolbar';
import { Affiliate } from '../../types';

import useOriginalReports from '../SingleQualityReport/useOriginalReports';

type Props = {
  affiliate: Affiliate | null;
};

const MonthlyReportsTable = ({ affiliate }: Props) => {
  const { qualityEntries } = useSharedState();
  const { getOriginalReports } = useOriginalReports();

  const [monthlyReports, setMonthlyReports] = useState<any>([]);
  const [dates, setDates] = useState<any>();
  const [reportedDates, setReportedDates] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [originalToggleChecked, setOriginalToggleChecked] = useState(false);
  const [originalColumns, setOriginalColumns] = useState({
    originalTotalClicks: false,
    originalTotalRevenue: false,
  });

  const init = () => {
    try {
      const storedStartDate = localStorage.getItem(
        `startDate-${affiliate?._id}`
      );
      const storedEndDate = localStorage.getItem(`endDate-${affiliate?._id}`);

      const currentDate = new Date();
      const startDate = storedStartDate
        ? new Date(storedStartDate)
        : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endDate = storedEndDate
        ? new Date(storedEndDate)
        : new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

      setDates({
        startDate,
        endDate,
      });

      setMonthlyReports(
        calculateTotalReports(qualityEntries, startDate, endDate)
      );
    } catch (error) {
      console.error('Error getting monthly reports:', error);
    }
  };

  useEffect(() => {
    return init();
  }, [qualityEntries]);

  const initializeOriginalReportsOnPublishedDates = async (date: any) => {
    try {
      const inputDate = new Date(date);
      if (inputDate < dates.startDate && inputDate > dates.endDate) {
        return null;
      }

      const originalReports = await getOriginalReports().call(
        date,
        affiliate?.prefix
      );

      return originalReports;
    } catch (error) {
      console.log('error getting original reports in monthly reports table');
    } finally {
      setLoading(false);
    }
  };

  const calculateOriginalTotals = (reports: any) => {
    let totalClicks = 0;
    let totalRevenue = 0;

    if (!reports) return { totalClicks, totalRevenue };

    reports.forEach((report: any) => {
      totalClicks += report.originalClicks;
      totalRevenue += report.originalRevenue;
    });

    return { totalClicks, totalRevenue };
  };

  const initializeOriginalReports = async () => {
    try {
      setLoading(true);
      let total = {
        totalOriginalClicks: 0,
        totalOriginalRevenue: 0,
      };

      for (const date of reportedDates) {
        const reports = await initializeOriginalReportsOnPublishedDates(date);
        const totalsObj = calculateOriginalTotals(reports);

        total.totalOriginalClicks += totalsObj.totalClicks;
        total.totalOriginalRevenue += totalsObj.totalRevenue;
      }

      const newMonthlyReports = [...monthlyReports];

      newMonthlyReports[0].originalTotalClicks = total.totalOriginalClicks;
      newMonthlyReports[0].originalTotalRevenue = parseFloat(
        total.totalOriginalRevenue.toFixed(2)
      );

      setMonthlyReports(newMonthlyReports);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (originalToggleChecked) {
      initializeOriginalReports();
    }
  }, [originalToggleChecked, dates, reportedDates]);

  const handleOpenDateRangePicker = () => {
    setShowDialog(true);
  };

  const handleCloseDateRangePicker = () => {
    setShowDialog(false);
  };

  const calculateTotalReports = (
    qualityEntries: any,
    startDate: Date,
    endDate: Date
  ) => {
    const reports = {
      _id: 'totalRow',
      startDate: startDate,
      endDate: endDate,
      totalClicks: 0,
      originalTotalClicks: 0,
      totalRevenue: 0,
      originalTotalRevenue: 0,
    };

    let reportedDates: any[] = [];

    const publishedReports = qualityEntries?.filter((entry: any) => {
      const entryDate = transformDate(entry.date);
      return (
        startDate <= entryDate && endDate >= entryDate && entry.isPublished
      );
    });

    if (publishedReports.length === 0) {
      setReportedDates(reportedDates);
      return [reports];
    }

    publishedReports.forEach((entry: any) => {
      reportedDates.push(entry.date);

      entry.qualityEntries.forEach((qualityEntry: any) => {
        if (qualityEntry.isTotalRow) return;

        reports.totalClicks += qualityEntry.clicks;
        reports.totalRevenue += qualityEntry.revenue;
      });
    });

    reports.totalClicks = Math.round(reports.totalClicks);
    reports.totalRevenue = +reports.totalRevenue.toFixed(2);

    setReportedDates(reportedDates);

    return [reports];
  };

  const transformDate = (date: any) => {
    const entryDate = new Date(date);
    entryDate.setTime(
      entryDate.getTime() + entryDate.getTimezoneOffset() * 60 * 1000
    );

    return entryDate;
  };

  const handleDateChange = (
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => {
    setDates({
      startDate: startDate,
      endDate: endDate,
    });
  };

  const onDateSelected = async () => {
    try {
      const totalReports = calculateTotalReports(
        qualityEntries,
        dates.startDate,
        dates.endDate
      );
      setMonthlyReports(totalReports);

      localStorage.setItem(
        `startDate-${affiliate?._id}`,
        dates.startDate.toISOString()
      );
      localStorage.setItem(
        `endDate-${affiliate?._id}`,
        dates.endDate.toISOString()
      );

      handleCloseDateRangePicker();
    } catch (error) {
      console.log('error updating monthly reports');
    }
  };

  const tableCols: GridColDef[] = [
    {
      headerName: 'Start Date',
      field: 'startDate',
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const formattedDate = moment(date).format('DD-MM-YYYY');
        return formattedDate;
      },
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const formattedDate = moment(date).format('DD-MM-YYYY');
        return formattedDate;
      },
    },
    {
      headerName: 'Total Clicks',
      field: 'totalClicks',
      flex: 1,
    },
    {
      headerName: 'Original Total Clicks',
      field: 'originalTotalClicks',
      flex: 1,
    },
    {
      headerName: 'Total Revenue',
      field: 'totalRevenue',
      flex: 1,
    },
    {
      headerName: 'Original Total Revenue',
      field: 'originalTotalRevenue',
      flex: 1,
    },
  ];

  const exportOptions = {
    startDate: {
      label: 'Start Date',
      formatter: (value: any) => {
        return moment(value).format('DD-MM-YYYY');
      },
    },
    endDate: {
      label: 'End Date',
      formatter: (value: any) => {
        return moment(value).format('DD-MM-YYYY');
      },
    },
    totalClicks: 'Total Clicks',
    originalTotalClicks: 'Original Total Clicks',
    totalRevenue: 'Total Revenue',
    originalTotalRevenue: 'Original Total Revenue',
  };

  const getReportFileName = () => {
    const { name } = affiliate!;

    const startString = moment(dates?.startDate).format('DD-MM-YYYY');
    const endString = moment(dates?.endDate).format('DD-MM-YYYY');

    return `${name}-report-${startString}-${endString}`;
  };

  const reportFileName = getReportFileName();

  const handleToggleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOriginalToggleChecked(event.target.checked);
    setOriginalColumns({
      originalTotalClicks: event.target.checked,
      originalTotalRevenue: event.target.checked,
    });
  };

  return (
    <>
      <Card sx={{ mb: '20px' }}>
        <DataGrid
          autoHeight
          rows={monthlyReports}
          columns={tableCols}
          columnVisibilityModel={originalColumns}
          getRowId={(r) => r._id}
          disableRowSelectionOnClick
          loading={loading}
          slots={{
            toolbar: () => (
              <>
                <Box display="flex">
                  <SimpleToolbar
                    allowExport
                    exportOptions={exportOptions}
                    rawExportData={monthlyReports}
                    exportFileName={reportFileName}
                  />
                  <MonthlyReportsToolbar
                    handleOpenDateRangePicker={handleOpenDateRangePicker}
                    originalToggleChecked={originalToggleChecked}
                    handleToggleClick={handleToggleClick}
                  />
                </Box>
              </>
            ),
          }}
        />
      </Card>

      <Dialog open={showDialog} onClose={handleCloseDateRangePicker}>
        <DialogTitle>Select date range</DialogTitle>
        <DialogContent>
          <ReportingDateFilter onChange={handleDateChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onDateSelected}>OK</Button>
          <Button onClick={handleCloseDateRangePicker}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MonthlyReportsTable;
