import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { Button, TextField, Typography } from '@mui/material';
import { AddMerchantDto } from '../types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onAddMerchant: (newMerchant: AddMerchantDto) => any;
};

const AddAffiliate = ({ open, handleClose, onAddMerchant }: Props) => {
  const [values, setValues] = useState({
    mid: '',
    merchantName: '',
    merchantUrl: '',
    normalizedMerchantUrl: ''
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues(() => ({
      ...values,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    setLoading(true);
    await onAddMerchant(values);
    handleClose();
    setLoading(false);
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add merchant</Typography>
      <TextField
        fullWidth
        name="mid"
        onChange={handleChange}
        label="Merchant Id"
        value={values.mid}
      />
      <TextField
        fullWidth
        name="merchantName"
        onChange={handleChange}
        label="Merchant Name"
        value={values.merchantName}
      />
      <TextField
        fullWidth
        name="merchantUrl"
        onChange={handleChange}
        label="Merchant Url"
        value={values.merchantUrl}
      />
      <TextField
        fullWidth
        name="normalizedMerchantUrl"
        onChange={handleChange}
        label="Normalized Merchant Url"
        value={values.normalizedMerchantUrl}
        helperText="URL without https://"
      />

      <Button
        color="primary"
        variant="contained"
        onClick={onSubmit}
        disabled={loading || !values.mid}
        fullWidth
      >
        Submit
      </Button>
    </SimpleModal>
  );
};

export default AddAffiliate;
