import { Box, CircularProgress } from '@mui/material';
import React from 'react';

type Props = {
  loading: boolean;
  loaderSize?: number;
};

const LoadingOverlay = ({ loading, loaderSize = 30 }: Props) => {
  const display = loading ? 'flex' : 'none';

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      display={display}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      bgcolor="white"
      height="100%"
      width="100%"
      zIndex="1"
      sx={{ opacity: 0.8 }}
    >
      <CircularProgress size={loaderSize} />
    </Box>
  );
};

export default LoadingOverlay;
