import { Box } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useCSVReader } from 'react-papaparse';

type Props = {
  onChange: (csvResult: CSVUploadResult) => any;
};

export type CSVUploadResult = {
  data: any[][];
  errors: any[];
  meta: any[];
};

export default function CSVUploader({ onChange }: Props) {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader onUploadAccepted={onChange}>
      {({ getRootProps, acceptedFile }: any) => (
        <Box display="flex" flexDirection="row">
          <Box
            variant="contained"
            bgcolor="rgb(25, 118, 210)"
            color="white"
            sx={{ cursor: 'pointer' }}
            display="flex"
            alignItems="center"
            p={1}
            {...getRootProps()}
          >
            <UploadIcon />
            Upload
          </Box>
          <Box border="1px solid #eee" p={1} px={2} flex={1}>
            {acceptedFile?.name ?? 'No uploaded file yet'}
          </Box>
        </Box>
      )}
    </CSVReader>
  );
}
