import { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { QualityEntries, QualityEntry, EditClicksDTO } from '../types';

import SimpleModal from '../../../../../components/SimpleModal';

type Props = {
  open: boolean;
  handleClose: () => any;
  onEditQualityReportsEntriesClicks: (
    qualityEntryId: QualityEntry['_id'],
    dto: EditClicksDTO
  ) => any;
  initialValues: QualityEntries[];
  qualityEntry: QualityEntry;
};

const EditQualityReportsEntriesClicks = ({
  open,
  handleClose,
  onEditQualityReportsEntriesClicks,
  initialValues,
  qualityEntry,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    type: 0,
    variation: 0,
    value: 0,
    rates: 0,
  });

  const onChange = (e: any) => {
    const regex = /^[0-9]*(\.[0-9]*)?$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      const { name, value } = e.target;
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await onEditQualityReportsEntriesClicks(qualityEntry._id, {
        entries: initialValues,
        ...values,
      });
      handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  console.log('initialValues >>>', initialValues);

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Update Clicks / Cost per Clicks</Typography>
      <TextField
        value={values.type}
        name="type"
        onChange={onChange}
        fullWidth
        label="Column to update"
        select
      >
        <MenuItem value={0}>Clicks</MenuItem>
        <MenuItem value={1}>Cost per Click</MenuItem>
      </TextField>
      <TextField
        value={values.variation}
        name="variation"
        onChange={onChange}
        fullWidth
        label="Variation"
        select
      >
        <MenuItem value={0}>Increase</MenuItem>
        <MenuItem value={1}>Decrease</MenuItem>
      </TextField>
      <Box display="flex" alignItems="center" gap={2}>
        <TextField
          value={values.value}
          name="value"
          onChange={onChange}
          label="Amount to change"
        />
        <TextField
          value={values.rates}
          name="rates"
          onChange={onChange}
          fullWidth
          label="Rates"
          select
        >
          <MenuItem value={0}>%</MenuItem>
          <MenuItem value={1}>Fixed</MenuItem>
        </TextField>
      </Box>
      <Box>
        <Typography variant="caption">
          {initialValues.length > 1
            ? `${initialValues.length} entries selected`
            : '1 entry selected'}
        </Typography>
      </Box>
      <Button color="primary" variant="contained" onClick={onSubmit} fullWidth>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
      </Button>
    </SimpleModal>
  );
};

export default EditQualityReportsEntriesClicks;
