import React, { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as nanoid from 'nanoid';
import LoadingButton from '../../../components/LoadingButton';
import { SelectChangeEvent } from '@mui/material';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (newOffer: any) => any;
};

type CustomOfferField = {
  id: string;
  key: string;
  label: string;
  type: string;
};

const defaultFields: CustomOfferField[] = [
  {
    id: nanoid.nanoid(),
    key: 'affiliateUrl',
    label: 'Aff. Url',
    type: 'string',
  },
  {
    id: nanoid.nanoid(),
    key: 'commission',
    label: 'Comm.',
    type: 'currency',
  },
];

const AddCustomOfferGroup = ({ open, handleClose, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    label: '',
    key: '',
  });

  const [fields, setFields] = useState<CustomOfferField[]>([...defaultFields]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const onChangeField = (
    id: string,
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;

    const newFields = fields.map((f) => {
      if (f.id !== id) return f;

      return {
        ...f,
        [name]: value,
      };
    });

    setFields(newFields);
  };

  const addField = () => {
    const newField = {
      id: nanoid.nanoid(),
      key: '',
      label: '',
      type: '',
    };

    setFields([...fields, newField]);
  };

  const deleteField = (id: string) => {
    const newFields = fields.filter((f) => f.id !== id);
    setFields(newFields);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit({
      ...values,
      fields,
    });
    setLoading(false);
  };

  return (
    <SimpleModal
      open={open}
      handleClose={handleClose}
      containerStyle={{ minWidth: 600 }}
    >
      <Typography variant="h6" gutterBottom>
        Add Custom Offer Group
      </Typography>
      <TextField
        label="Label"
        name="label"
        onChange={onChange}
        value={values.label}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Key"
        name="key"
        onChange={onChange}
        value={values.key}
        fullWidth
        margin="normal"
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6" gutterBottom>
          Fields
        </Typography>
        <Box>
          <IconButton onClick={addField}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      {fields.map((f) => {
        const isDefaultField = f.key === 'affiliateUrl' || f.key === 'commission';

        return (
          <Box key={f.id} display="flex" gap={1} alignItems="center">
            <TextField
              label="Key"
              value={f.key}
              onChange={(e) => onChangeField(f.id, e)}
              name="key"
              sx={{ flexGrow: 1 }}
              fullWidth
              margin="normal"
              disabled={isDefaultField}
            />
            <TextField
              label="Label"
              value={f.label}
              onChange={(e) => onChangeField(f.id, e)}
              name="label"
              sx={{ flexGrow: 1 }}
              fullWidth
              margin="normal"
            />
            <TextField
              select
              label="Type"
              value={f.type}
              onChange={(e) => onChangeField(f.id, e)}
              name="type"
              sx={{ flexGrow: 1 }}
              fullWidth
              margin="normal"
              disabled={isDefaultField} 
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="currency">Currency</MenuItem>
            </TextField>
            <IconButton
              onClick={() => deleteField(f.id)}
              disabled={isDefaultField} 
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      })}
      <LoadingButton
        onClick={handleSubmit}
        variant="contained"
        loading={loading}
        sx={{ marginTop: 2 }}
      >
        Submit
      </LoadingButton>
    </SimpleModal>
  );
};

export default AddCustomOfferGroup;
