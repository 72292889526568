import { useState, useEffect, useContext } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { Typography, TextField, Button } from '@mui/material';
// import { OfferContext } from '../context';

export default function TestOffer({ open, handleClose }) {
  // const offerContext = useContext(OfferContext);
  // const [originalUrl, setOriginalUrl] = useState('');
  // const [redirectLink, setRedirectLink] = useState('');
  // const [invalidUrl, setInvalidUrl] = useState(false);
  // const [noMatchedOffer, setNoMatchedOffer] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  // const { redirectTags, offers } = offerContext;
  // const activeRedirectTags = redirectTags;
  // const activeOffers = offers;
  // const onChange = (e) => {
  //   setRedirectLink(e.target.value.trim());
  // };
  // const resetStates = () => {
  //   setOriginalUrl('');
  //   setRedirectLink('');
  //   setInvalidUrl(false);
  //   setNoMatchedOffer(false);
  // };
  // useEffect(() => {
  //   if (open) {
  //     setIsOpen(true);
  //     resetStates();
  //   } else {
  //     setIsOpen(false);
  //   }
  // }, [open]);
  // const isValidURL = (url) => {
  //   const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  //   return urlPattern.test(url);
  // };
  // const generateOriginalUrl = (url, params) => {
  //   const random = 'random';
  //   const tags = activeRedirectTags.filter(
  //     (redirectTag) => redirectTag.redirectId === url.uniqueId
  //   );
  //   console.log('tags :', activeRedirectTags);
  //   if (tags.length === 0) {
  //     let newUrl = new URL(url.offerLink);
  //     for (const [key, value] of params) {
  //       newUrl.searchParams.append(key, value);
  //     }
  //     return newUrl.toString();
  //   }
  //   let newUrl = new URL(url.offerLink);
  //   for (const [key, value] of params) {
  //     let matched = false;
  //     for (const tag of tags) {
  //       if (tag.tag === key) {
  //         if (tag.originalValue === value) {
  //           newUrl.searchParams.append(key, tag.generatedValue);
  //         } else {
  //           newUrl.searchParams.append(key, random);
  //         }
  //         matched = true;
  //         break;
  //       }
  //     }
  //     if (!matched) {
  //       newUrl.searchParams.append(key, value);
  //     }
  //   }
  //   return newUrl.toString();
  // };
  // const getOriginalUrl = (url) => {
  //   if (!url) return;
  //   if (!isValidURL(url)) {
  //     setInvalidUrl(true);
  //     return;
  //   }
  //   const newUrl = new URL(url);
  //   const pathName = newUrl.pathname;
  //   const params = new URLSearchParams(newUrl.search);
  //   console.log(params);
  //   const uniqueId = pathName.substring(pathName.lastIndexOf('/') + 1);
  //   const matchedOffer = activeOffers.find(
  //     (offer) => offer.uniqueId === uniqueId
  //   );
  //   if (!matchedOffer) {
  //     setNoMatchedOffer(true);
  //     setOriginalUrl('');
  //     return;
  //   }
  //   const origLink = generateOriginalUrl(matchedOffer, params);
  //   setInvalidUrl(false);
  //   setNoMatchedOffer(false);
  //   setOriginalUrl(origLink);
  // };
  // return (
  //   <SimpleModal open={open} handleClose={handleClose}>
  //     <Typography variant="h6">Test Offer</Typography>
  //     <Typography>
  //       Test the redirect link to generate the original offer link with
  //       parameters
  //     </Typography>
  //     <TextField
  //       name="redirectLink"
  //       onChange={onChange}
  //       fullWidth
  //       label="Redirect Link"
  //       helperText={
  //         invalidUrl
  //           ? 'Invalid URL'
  //           : 'Input the redirect link generated in the offers table'
  //       }
  //       error={invalidUrl}
  //     />
  //     <TextField
  //       name="originalUrl"
  //       fullWidth
  //       value={originalUrl || ''}
  //       label="Original URL"
  //       helperText={noMatchedOffer ? 'No original offer link found' : ''}
  //       error={noMatchedOffer}
  //       disabled={true}
  //     />
  //     <Button variant="contained" onClick={() => getOriginalUrl(redirectLink)}>
  //       Generate Original Link
  //     </Button>
  //   </SimpleModal>
  // );
}
