import { createContext, useEffect, useState } from 'react';
import { SovrnReportWithRows } from '../types';
import useSovrn from '../useSovrn';
import { useParams } from 'react-router-dom';

export type SingleSovrnContextValues = {
  report: SovrnReportWithRows | null;
  setReport: React.Dispatch<React.SetStateAction<SovrnReportWithRows | null>>;
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
};

export const SingleSovrnContext = createContext<SingleSovrnContextValues>(
  {} as any
);

type Props = {
  children: React.ReactNode;
};
const SingleSovrnProvider = ({ children }: Props) => {
  const [report, setReport] = useState<SovrnReportWithRows | null>(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const { getSovrnReport } = useSovrn();

  const init = () => {
    const api = getSovrnReport();

    (async () => {
      setLoading(true);
      try {
        const res = await api.call(params.id);
        setReport(res);
      } catch (error) {}
      setLoading(false);
    })();

    return api.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const vals = {
    report,
    setReport,
    loading,
    setLoading,
  };

  return (
    <SingleSovrnContext.Provider value={vals}>
      {children}
    </SingleSovrnContext.Provider>
  );
};

export default SingleSovrnProvider;
