import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import useCustomOffer from '../useCustomOffers';
import { useParams } from 'react-router-dom';
import { toTwoDecimals } from '../../../lib';
import { Range } from 'react-date-range';
import OfferGroupClicksTableToolbar from './toolbar';
import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment';

type Props = {};

const OfferGroupClicksTable = (props: Props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actualClicks, setActualClicks] = useState([]);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const params = useParams();
  const client = useCustomOffer();

  const fetchData = (
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => {
    const api = client.getClicksSummary();

    (async () => {
      setLoading(true);
      try {
        const startDateFinal =
          moment(startDate).format('YYYY-MM-DD') ||
          moment(new Date()).format('YYYY-MM-DD');
        const endDateFinal =
          moment(endDate).format('YYYY-MM-DD') ||
          moment(new Date()).format('YYYY-MM-DD');

        const res = await api.call(params.id, startDateFinal, endDateFinal);

        const reportedMap = res.reported.reduce((acc: any, item: any) => {
          const { merchantId, merchantName } = item;
          const key = `${merchantId};${merchantName}`;
          acc[key] = item;
          return acc;
        }, {});

        const actualRows = res.actual.map((item: any) => {
          const { merchantId, merchantName, revenue, clicks } = item;
          const key = `${merchantId};${merchantName}`;

          const reported = reportedMap[key];

          const base = {
            merchantId,
            merchantName,
            actualClicks: clicks,
            actualRevenue: revenue,
            reportedClicks: 0,
            reportedRevenue: 0,
          };

          if (!reported) return base;

          const { clicks: rclicks, revenue: rrevenue } = reported;

          base.reportedClicks = rclicks;
          base.reportedRevenue = rrevenue;

          return base;
        });

        setRows(actualRows);
      } catch (error) {
        // Handle error here
      }
      setLoading(false);
    })();

    return api.abort;
  };

  useEffect(() => {
    fetchData(dateRange.startDate, dateRange.endDate);
  }, [dateRange]);

  const handleDateFilterSubmit = (range: Range) => {
    setDateRange({
      startDate: range.startDate || new Date(),
      endDate: range.endDate || new Date(),
      key: range.key,
    });
  };

  const columns: GridColDef[] = [
    {
      headerName: 'M. Id',
      field: 'merchantId',
      flex: 1,
    },
    {
      headerName: 'M. Name',
      field: 'merchantName',
      flex: 1,
    },
    {
      headerName: 'Reported Clicks',
      field: 'reportedClicks',
      flex: 1,
    },
    {
      headerName: 'Actual Clicks',
      field: 'actualClicks',
      flex: 1,
    },
    {
      headerName: 'Clk. diff. %',
      field: 'clickDifference',
      flex: 1,
      valueGetter: (params) => {
        const { actualClicks, reportedClicks } = params.row;
        const diff = actualClicks <= 0 ? 0 : 1 - reportedClicks / actualClicks;
        return diff;
      },
      valueFormatter: (params) => {
        const r = toTwoDecimals(params.value * 100);
        return `${r}%`;
      },
    },
    {
      headerName: 'Reported Revenue',
      field: 'reportedRevenue',
      flex: 1,
      valueFormatter: (params) => {
        const num = toTwoDecimals(params.value);
        return `$${num}`;
      },
    },
    {
      headerName: 'Actual Revenue',
      field: 'actualRevenue',
      flex: 1,
      valueFormatter: (params) => {
        const num = toTwoDecimals(params.value);
        return `$${num}`;
      },
    },
    {
      headerName: 'Rev. diff. %',
      field: 'revenueDifference',
      flex: 1,
      valueGetter: (params) => {
        const { actualRevenue, reportedRevenue } = params.row;
        const diff =
          actualRevenue <= 0 ? 0 : 1 - reportedRevenue / actualRevenue;
        return diff;
      },
      valueFormatter: (params) => {
        const r = toTwoDecimals(params.value * 100);
        return `${r}%`;
      },
    },
    {
      headerName: 'Avg. CPC',
      field: 'averageCpc',
      flex: 1,
      valueGetter: (params) => {
        const { actualRevenue, reportedRevenue, reportedClicks, actualClicks } =
          params.row;
        const revenue = actualRevenue + reportedRevenue;
        const clicks = reportedClicks + actualClicks;
        return revenue / clicks;
      },
      valueFormatter: (params) => {
        const r = toTwoDecimals(params.value);
        return `$${r}`;
      },
    },
  ];

  return (
    <>
      <Box>
        <Box display={'flex'} gap={1} alignItems={'center'}>
          <Chip
            variant="outlined"
            color="primary"
            label={`Starts From: ${moment(dateRange.startDate).format(
              'YYYY-MM-DD'
            )}`}
          ></Chip>
          <Chip
            variant="outlined"
            color="primary"
            label={`Ends To: ${moment(dateRange.endDate).format('YYYY-MM-DD')}`}
          ></Chip>
        </Box>
      </Box>
      <DataGrid
        columns={columns}
        rows={rows}
        autoHeight
        loading={loading}
        getRowId={(r) => r.merchantId}
        components={{
          Toolbar: () => (
            <OfferGroupClicksTableToolbar
              onDateFilterSubmit={handleDateFilterSubmit}
              initialDateRange={dateRange}
              actualClicks={actualClicks}
            />
          ),
        }}
      />
    </>
  );
};

export default OfferGroupClicksTable;
