import React, { useState } from 'react';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';

type Props = {
  onChange: (startDate: Date | undefined, endDate: Date | undefined) => void;
};

function ReportingDateFilter({ onChange }: Props) {
  const [state, setState] = useState<Range[]>([
    {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    },
  ]);

  const handleChange = (newValue: RangeKeyDict) => {
    const { selection } = newValue;

    const { startDate, endDate } = selection;

    onChange(startDate, endDate);

    setState([selection]);
  };

  return (
    <DateRangePicker
      onChange={handleChange}
      ranges={state}
      maxDate={new Date()}
    />
  );
}

export default ReportingDateFilter;
