import React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

interface QuickFilterProps {
  quickFilter: string;
  setQuickFilter: (value: string) => void;
}

const QuickFilter: React.FC<QuickFilterProps> = ({
  quickFilter,
  setQuickFilter,
}) => {
  return (
    <Box margin={2} display="flex" justifyContent="flex-end">
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
        value={quickFilter}
        onChange={(e) => setQuickFilter(e.target.value)}
        sx={{ width: '300px' }}
        size="small"
      />
    </Box>
  );
};

export default QuickFilter;
