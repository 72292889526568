import { createContext, useEffect, useState } from 'react';
import useTraffic from '../traffic/useTraffic';

export const PostbackContext = createContext({});

export default function PostbackProvider({ children }) {
    const { getTraffic } = useTraffic();

    const [traffic, setTraffic] = useState([]);

    const init = () => {
        const trafficCall = getTraffic();

        (async function () {
            try {
                const [loadedTraffic] = await Promise.all([
                    trafficCall.call()
                ]);

                setTraffic(loadedTraffic);
            } catch (e) {
                console.log("Fail loading traffics", e);
            }
        })();

        return () => {
            trafficCall.abort();
        };
    };

    useEffect(() => {
        return init();
    }, []);

    return <PostbackContext.Provider value={{ traffic }}>{children}</PostbackContext.Provider>
}