import { useContext } from 'react';
import { SovrnReportRow } from '../../types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { SingleSovrnContext } from '../context';
import SingleSovrnToolbar from '../SingleSovrnToolbar';

const SingleSovrnTable = () => {
  const { report, loading } = useContext(SingleSovrnContext);

  const idname = report?.endpoint === 'eagleview' ? 'SID' : 'clickId';

  const getRowId = (r: SovrnReportRow) => r[idname]!;
  const cols = report?.endpoint === 'eagleview' ? eagleRows : xmpCols;

  return (
    <DataGrid
      rows={report?.rows ?? []}
      loading={loading}
      columns={cols}
      autoHeight
      getRowId={getRowId}
      initialState={{
        sorting: {
          sortModel: [{ field: 'date', sort: 'desc' }],
        },
      }}
      slots={{
        toolbar: () => {
          return <SingleSovrnToolbar report={report} />;
        },
      }}
    ></DataGrid>
  );
};

const xmpCols: GridColDef<SovrnReportRow>[] = [
  {
    headerName: 'Date',
    field: 'date',
    flex: 1,
  },
  {
    headerName: 'Click Id',
    field: 'clickId',
    flex: 1,
  },
  {
    headerName: 'G. Value',
    field: 'generatedValue',
    flex: 1,
    valueGetter: (params) => {
      return params.row.generatedValue ?? '';
    },
  },
  {
    headerName: 'Clicks',
    field: 'clicks',
    flex: 1,
  },
  {
    headerName: 'CPC',
    field: 'cpc',
    flex: 1,
    valueFormatter: ({ value }) => {
      return `$${value}`;
    },
  },
  {
    headerName: 'Revenue',
    field: 'revenue',
    flex: 1,
    valueFormatter: ({ value }) => {
      return `$${value}`;
    },
  },
  {
    headerName: 'Conversion',
    field: 'conversion',
    flex: 1,
  },
];

const eagleRows: GridColDef<SovrnReportRow>[] = [
  {
    headerName: 'Date',
    field: 'date',
    flex: 1,
  },
  {
    headerName: 'SID',
    field: 'SID',
    flex: 1,
  },
  {
    headerName: 'G. Value',
    field: 'generatedValue',
    flex: 1,
    valueGetter: (params) => {
      return params.row.generatedValue ?? '';
    },
  },
  {
    headerName: 'Conversion',
    field: 'conversion',
    flex: 1,
  },
  {
    headerName: 'Payout',
    field: 'payout',
    flex: 1,
    valueFormatter: ({ value }) => {
      return `$${value}`;
    },
  },
];

export default SingleSovrnTable;
