import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { Typography, Box, Chip, Button } from '@mui/material';
import useHpReports from '../useHpReports';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    valueFormatter: (params) => params.value ? moment(params.value).format('YYYY-MM-DD') : 'None',
    renderCell: (params) => (
      params.value ? moment(params.value).format('YYYY-MM-DD') : <Chip label="None" />
    ),
  },
  {
    field: 'originalClickId',
    headerName: 'Original Click ID',
    flex: 1,
    renderCell: (params) => (
      params.value || <Chip label="None" />
    ),
  },
  {
    field: 'clickId',
    headerName: 'Click ID',
    flex: 1,
    renderCell: (params) => (
      params.value || <Chip label="None" />
    ),
  },
  {
    field: 'conversion',
    headerName: 'Conversion',
    flex: 1,
    renderCell: (params) => (
      params.value !== undefined ? params.value : <Chip label="None" />
    ),
  },
  {
    field: 'payout',
    headerName: 'Payout',
    flex: 1,
    renderCell: (params) => (
      params.value !== undefined ? params.value : <Chip label="None" />
    ),
  },
  {
    field: 'originalPayout',
    headerName: 'Original Payout',
    flex: 1,
    renderCell: (params) => (
      params.value !== undefined ? params.value : <Chip label="None" />
    ),
  },
];

const SingleHpReport = () => {
  const { id } = useParams();
  const { getReportById } = useHpReports();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(false);
  const [date , setDate] = useState('')

  useEffect(() => {
    setLoading(true);
    const apiCall = getReportById();
    (async function () {
      try {
        const res = await apiCall.call(id);
        console.log('res :>> ', res.rows);
        setDate(res.date)
        setRows(res.rows);
      } catch (e) {
        console.log('Fail fetching report details', e);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <Box my={4}>
      <Box mb={3}>
        <Link to="/hp-reports">
          <Button startIcon={<ArrowBackIcon />}>Back</Button>
        </Link>
      </Box>
        <Typography variant="h4" component="h1" gutterBottom>
          Report Details {moment(date).format('YYYY-MM-DD')}
        </Typography>
      </Box>
      <Box my={4} style={{ height: '100%', width: '100%' }}>
        <DataGrid getRowId={(r) => r._id} rows={rows} columns={columns} loading={loading}   slots={{
    toolbar: GridToolbar,
  }} />
      </Box>
    </>
  );
};

export default SingleHpReport;
