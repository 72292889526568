import React, { useContext } from 'react';
import SimpleToolbar from '../../../components/SimpleToolbar';
import AddIcon from '@mui/icons-material/Add';
import DeductionForm from './addDeduction';
import useCustomOffer from '../useCustomOffers';
import { OfferGroupContext } from '../context';
import { Edit } from '@mui/icons-material';
import { nanoid } from 'nanoid';

type Props = {
  selectedRow?: { merchantIds: string[]; deductionRate: any };
};

const CommissionDeductionToolbar = ({ selectedRow }: Props) => {
  const client = useCustomOffer();

  const { offerGroup, setOfferGroup } = useContext(OfferGroupContext);

  const onAddDeduction = async (e: any) => {
    let comSettings = offerGroup!.merchantCommissionSettings.slice() ?? [];
  
    e.merchantIds.forEach((merchantId: string) => {

      const match = comSettings.find(
        (settings) => settings.merchantId === merchantId
      );
  
      if (match) {
        match.deductionRate = e.deductionRate;
      } else {
        comSettings.unshift({
          merchantId, 
          deductionRate: e.deductionRate,
          _id: nanoid(), 
        });
      }
    });
  
    const newOfferGroup = {
      ...offerGroup!,
      merchantCommissionSettings: comSettings,
    };

    await client
      .updateOfferGroup()
      .call(offerGroup!._id, { merchantCommissionSettings: comSettings });

    setOfferGroup(newOfferGroup);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <DeductionForm
            open={open}
            handleClose={handleClose}
            onSubmit={onAddDeduction}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRow),
      icon: <Edit />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <DeductionForm
            open={open}
            handleClose={handleClose}
            onSubmit={onAddDeduction}
            initialValue={selectedRow}
          />
        );
      },
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
};

export default CommissionDeductionToolbar;
