import React, { useEffect, useState, createContext } from 'react';
import { SharedStateProvider } from './sharedContext';
import { useParams } from 'react-router-dom';
import useAffiliate from '../useAffiliate';
import { Affiliate } from '../types';
import { CanceledError } from 'axios';

type Props = {
  children: React.ReactNode;
};

type SingleAffiliateCTX = {
  affiliate: Affiliate | null;
  loading: boolean;
};

export const SingleAffiliateContext = createContext<SingleAffiliateCTX>({
  loading: true,
  affiliate: null,
});

function SingleAffiliateProvider({ children }: Props) {
  const [loading, setLoading] = useState(true);
  const [affiliate, setAffiliate] = useState<Affiliate | null>(null);
  const { getSingleAffiliate } = useAffiliate();

  const params = useParams();

  const init = () => {
    const apiCall = getSingleAffiliate();

    (async function () {
      try {
        setLoading(true);
        const singleAffiliate = await apiCall.call(params.affiliateId);
        setAffiliate(singleAffiliate);
      } catch (error) {
        if (!typeof CanceledError)
          console.log('[singleaffiliateprovider] error getting affiliate');
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, [params.affiliateId]);

  const values = {
    loading,
    affiliate,
  };

  return (
    <SingleAffiliateContext.Provider value={values}>
      <SharedStateProvider>{children}</SharedStateProvider>
    </SingleAffiliateContext.Provider>
  );
}

export default SingleAffiliateProvider;
