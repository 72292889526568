import { Box } from "@mui/material";
import LogsTable from "./LogsTable";

function LogsPage() {
  return (
    <Box mt={4}>
      <LogsTable />
    </Box>
  );
}

export default LogsPage;