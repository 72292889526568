import React, { useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import { Typography, Button, Box } from '@mui/material';
import SimpleModal from './SimpleModal';

type DateFilterProps = {
  open: boolean;
  handleClose: () => void;
  onSubmit: (range: Range) => void;
  initialData: Range;
};

const DateFilter: React.FC<DateFilterProps> = ({
  open,
  handleClose,
  onSubmit,
  initialData,
}) => {
  const [dateRange, setDateRange] = useState<Range[]>([initialData]);

  const handleChange = (newValue: any) => {
    setDateRange([newValue.selection]);
  };

  const handleSave = () => {
    onSubmit(dateRange[0]);
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Filter table by date</Typography>

      <DateRange
        editableDateInputs
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        onChange={(item) => handleChange(item)}
      />
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{ marginRight: 1 }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </SimpleModal>
  );
};

export default DateFilter;
