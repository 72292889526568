import React, { useContext, useState, useMemo } from 'react';
import SimpleToolbar from '../../../../../components/SimpleToolbar';
import { SingleTrafficContext } from '../../context';
import EditIcon from '@mui/icons-material/Edit';
import EditRedirect from '../EditRedirect';
import useTraffic from '../../../useTraffic';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DateFilter from '../DateFilter';

function RedirectToolbar({
  currentData,
  currentFilteredData,
  selectedRow,
  onEditRedirectDone,
  onDateFilterDone,
  dateFilter,
}) {
  const { trafficData, loading } = useContext(SingleTrafficContext);

  const { editRedirectValue } = useTraffic();

  const exportOptions = {
    visitedUrl: 'Visited URL',
    tag: 'Tag name',
    originalValue: 'Original value',
    generatedValue: 'Generated value',
    createdAt: {
      formatter: (value) =>
        new Date(value).toLocaleString('en-GB', {
          timeZone: 'UTC',
          timeStyle: 'long',
          dateStyle: 'short',
          hour12: false
        }),
      label: 'Created at',
    },
  };

  const onEditRedirect = async (newGeneratedValue) => {
    const redirect = await editRedirectValue().call(
      selectedRow._id,
      newGeneratedValue
    );
    onEditRedirectDone(redirect);
  };

  const modalButtons = [
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedRow),
      icon: <EditIcon />,
      render: (open, handleClose) => {
        return (
          <EditRedirect
            open={open}
            handleClose={handleClose}
            onSubmit={onEditRedirect}
            initialData={selectedRow}
            currentTableData={currentData}
          />
        );
      },
    },
    {
      name: 'filter',
      label: 'Date filter',
      renderIf: true,
      icon: <FilterAltIcon />,
      render: (open, handleClose) => {
        return (
          <DateFilter
            open={open}
            handleClose={handleClose}
            initialData={dateFilter}
            onSubmit={onDateFilterDone}
          />
        );
      },
    },
  ];

  if (loading) return null;

  const exportFileName = trafficData
    ? `${trafficData.name}.csv`
    : 'generatedValues.csv';

  return (
    <SimpleToolbar
      modalButtons={modalButtons}
      rawExportData={currentFilteredData}
      allowExport
      exportOptions={exportOptions}
      exportFileName={exportFileName}
    />
  );
}

export default RedirectToolbar;
