import { createContext, useContext, useState, ReactNode } from 'react';
import { QualityEntry } from './SingleQualityReport/types';

type Props = {
  children: React.ReactNode;
};

interface SharedStateContextProps {
  qualityEntries: QualityEntry[];
  setQualityEntries: React.Dispatch<React.SetStateAction<QualityEntry[]>>;
}

const SharedStateContext = createContext<SharedStateContextProps | undefined>(
  undefined
);

export const SharedStateProvider = ({ children }: Props) => {
  const [qualityEntries, setQualityEntries] = useState<QualityEntry[]>([]);

  return (
    <SharedStateContext.Provider value={{ qualityEntries, setQualityEntries }}>
      {children}
    </SharedStateContext.Provider>
  );
};

export const useSharedState = () => {
  const context = useContext(SharedStateContext);
  if (!context) {
    throw new Error('useSharedState must be used within a SharedStateProvider');
  }
  return context;
};
