import React, { useEffect, useState } from 'react';
import useMerchants from './useMerchants';
import moment from 'moment';
import { Box, Chip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';

import TooltipIconButton from '../../components/TooltipIconButton';
import SearchIcon from '@mui/icons-material/Search';

import MerchantToolbar from './MerchantToolbar';

function MerchantsPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState('false');
  const [selectedRow, setSelectedRow] = useState([]);

  const { getMerchants } = useMerchants();

  const navigate = useNavigate();

  const init = async () => {
    setLoading(true);
    const instance = getMerchants();

    (async function () {
      try {
        const res = await instance.call();

        const sortedData = res.data.merchants.sort((a, b) =>
          a.merchantName.localeCompare(b.merchantName)
        );

        setData(sortedData);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();

    return instance.abort;
  };

  const tableCols = [
    {
      headerName: 'Merchant ID',
      field: 'mid',
      flex: 1,
      renderCell: (params) => {
        const destination = `../merchants/${params.row.mid}/feeds`;

        return <Link to={destination}>{params.row.mid}</Link>;
      },
    },
    {
      headerName: 'Merchant Name',
      field: 'merchantName',
      flex: 2,
      renderCell: (params) => {
        const destination = `../merchants/${params.row.mid}/feeds`;

        return <Link to={destination}>{params.row.merchantName}</Link>;
      },
    },
    {
      headerName: 'URL',
      field: 'merchantUrl',
      flex: 2,
    },
    {
      headerName: 'Normalized URL',
      field: 'normalizedMerchantUrl',
      flex: 2,
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      renderCell: (params) => {
        const chipLabel = params.row.status;
        const chipColor = params.row.status == 'Active' ? 'primary' : 'default';
        return <Chip label={chipLabel} color={chipColor} />;
      },
    },
    {
      headerName: 'Data Feed',
      field: 'feedEnabled',
      flex:1,
      renderCell: (params) => {
        const dataFeed =
          params.row.feedEnabled === true
            ? 'With Data Feed'
            : 'Without Data Feed';
        return <Typography>{dataFeed}</Typography>;
      },
    },
    {
      headerName: 'Updated At',
      field: 'updatedAt',
      flex: 1,
      renderCell: (params) => {
        const formatDate = formattedDate(params.row.updatedAt);
        return formatDate;
      },
    },
    {
      headerName: 'Actions',
      field: '_id',
      flex: 1,
      renderCell: (params) => {
        const feedUrl = `${params.row.mid}/feeds`;

        return (
          <Box>
            <TooltipIconButton
              title="View feeds"
              onClick={() => navigate(feedUrl)}
              icon={<SearchIcon />}
            />
          </Box>
        );
      },
    },
  ];

  const formattedDate = (dateString) => {
    return moment(dateString).format('DD/MM/YY HH:mm');
  };

  const onAddAffiliateDone = (newMerchant) => {
    setData([newMerchant, ...data]);
  };

  const onEditAffiliateDone = (newMerchant) => {
    const newMerchants = data.map((item) => {
      if (item._id === newMerchant._id) return newMerchant;
      return item;
    });

    setData(newMerchants);
  };

  const selectedMerchant = data.find((item) => item._id === selectedRow[0]);

  useEffect(() => {
    init();
  }, []);

  return (
    <Box mt={4}>
      <DataGrid
        autoHeight
        rows={data}
        columns={tableCols}
        getRowId={(r) => r._id}
        onRowSelectionModelChange={setSelectedRow}
        loading={loading}
        slots={{
          toolbar: () => (
            <MerchantToolbar
              onAddMerchantDone={onAddAffiliateDone}
              onEditMerchantDone={onEditAffiliateDone}
              selectedMerchant={selectedMerchant}
            />
          ),
        }}
      />
    </Box>
  );
}

export default MerchantsPage;
