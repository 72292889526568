import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function SimpleMenu({
  item,
  menuItems,
  index,
  handleValueChange,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = open ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        endIcon={isOpen}
        sx={{ height: 54, width: 150, fontSize: '12px' }}
      >
        {menuItems[item.type]}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menuItems.map((label, type) => (
          <MenuItem
            key={label}
            value={type}
            onClick={() => {
              handleValueChange(index, 'type', type);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
