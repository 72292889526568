import SimpleToolbar from '../../../components/SimpleToolbar';
import AddIcon from '@mui/icons-material/Add';
import { OfferGroupTraffic } from '../types';
import AddCustomOfferGroupTrafficSource from '../AddOfferGroupTrafficSource';

type Props = {
  onAddOfferGroupSourcesDone: (newData: OfferGroupTraffic) => any;
};

const OfferGroupTrafficSourcesToolbar = ({
  onAddOfferGroupSourcesDone,
}: Props) => {
  const buttons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <AddCustomOfferGroupTrafficSource
            open={open}
            handleClose={handleClose}
            onSubmit={onAddOfferGroupSourcesDone}
          />
        );
      },
    },
  ];
  return <SimpleToolbar modalButtons={buttons}></SimpleToolbar>;
};

export default OfferGroupTrafficSourcesToolbar;
