import React, { useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QualityReportsEntriesTable from './QualityReportsEntriesTable';

import SingleQualityEntryProvider, {
  SingleQualityEntryContext,
} from './context';

function SingleQualityReportMainSection() {
  const { qualityEntry } = useContext(SingleQualityEntryContext);

  const date = qualityEntry
    ? moment(qualityEntry.date).utc().format('DD-MM-YYYY')
    : '';

  const affiliateId = qualityEntry?.affiliateId;
  const affiliateLink = `/affiliates/${affiliateId}`;

  return (
    <Box mt={4}>
      <Box mb={3}>
        <Link to={affiliateLink}>
          <Button startIcon={<ArrowBackIcon />}>Back</Button>
        </Link>
      </Box>
      <Typography variant="h4" gutterBottom>
        {date?.toString()}
      </Typography>
      {qualityEntry && (
        <QualityReportsEntriesTable qualityEntry={qualityEntry} />
      )}
    </Box>
  );
}

function SingleQualityReportPage() {
  return (
    <SingleQualityEntryProvider>
      <SingleQualityReportMainSection />
    </SingleQualityEntryProvider>
  );
}

export default SingleQualityReportPage;
