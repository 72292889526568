import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import useCustomOffer from '../useCustomOffers';
import ConfirmModal from '../../../components/ConfirmModal';
import { OfferGroupReport } from '../types';
import Delete from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';

type Props = {};

const OfferGroupReportTable = (props: Props) => {
  const [rows, setRows] = useState<OfferGroupReport[]>([]);
  const params = useParams();

  const client = useCustomOffer();

  const init = () => {
    const apiCall = client.getReports();

    (async () => {
      try {
        const res = await apiCall.call(params.id);
        setRows(res);
      } catch (error) {
        console.error(error);
      }
    })();

    return apiCall.abort;
  };

  const onDeleteReport = async (id: string) => {
    try {
      await client.deleteReport().call(id);
      const newRows = rows.filter((r) => r._id !== id);
      setRows(newRows);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return init();
  }, [params.id]);

  const cols: GridColDef<OfferGroupReport>[] = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      renderCell: ({ row }) => {
        return <Link to={`/custom-report/${row._id}`}>{row.date}</Link>;
      },
    },
    {
      field: '',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <ConfirmModal
          iconButton
          title="Delete"
          onConfirm={() => onDeleteReport(row._id)}
          icon={<Delete />}
        >
          Do you want to delete this report?
        </ConfirmModal>
      ),
    },
  ];

  return (
    <DataGrid
      columns={cols}
      rows={rows}
      autoHeight
      getRowId={(r) => r._id}
      initialState={{
        sorting: {
          sortModel: [{ field: 'date', sort: 'desc' }],
        },
      }}
    />
  );
};

export default OfferGroupReportTable;
