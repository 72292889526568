import React, { useEffect, useState, memo, useContext, useMemo } from 'react';
import useTraffic from '../../useTraffic';
import { Card, Box, Typography, IconButton, Chip } from '@mui/material';
import TooltipIconButton from '../../../../components/TooltipIconButton';

import { DataGrid } from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RedirectToolbar from './Toolbar';
import ConfirmModal from '../../../../components/ConfirmModal';
import { SingleTrafficContext } from '../context';
import moment from 'moment';

function RedirectTable() {
  const [loading, setLoading] = useState(false);
  const [redirectRows, setRedirectRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
    key: 'selection',
  });

  const { getRedirectTags, deleteGeneratedValue, generateNewRandomValue } =
    useTraffic();
  const { trafficData } = useContext(SingleTrafficContext);

  const init = () => {
    setLoading(true);
    const tagsApi = getRedirectTags();
    (async function () {
      try {
        const redirectTags = await tagsApi.call(trafficData._id);
        setRedirectRows(redirectTags);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();

    return tagsApi.abort;
  };

  const onEditRedirectDone = (newRedirect) => {
    const newRedirectRows = redirectRows.map((item) => {
      const match = item._id === newRedirect._id;

      if (match) return newRedirect;
      return item;
    });

    setRedirectRows(newRedirectRows);
  };

  const onDateFilterDone = (newFilter) => {
    setDateFilter(newFilter);
  };

  const onGenerateNewRandomValues = async (id) => {
    const redirectTag = await generateNewRandomValue().call(id);
    onGenerateNewRandomValuesDone(redirectTag);
  };

  const onGenerateNewRandomValuesDone = (newRedirect) => {
    const newRedirectRows = redirectRows.map((item) => {
      const match = item._id === newRedirect._id;

      if (match) return newRedirect;
      return item;
    });

    setRedirectRows(newRedirectRows);
  };

  const onDeleteGeneratedValues = async (id) => {
    try {
      setLoading(true);
      await deleteGeneratedValue().call(id);
      const newRedirectRows = redirectRows.filter((item) => item._id !== id);
      setRedirectRows(newRedirectRows);
    } finally {
      setLoading(false);
    }
  };

  const convertToUTC = (date) => {
    const dateObj = new Date(date);

    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();
    const day = dateObj.getDate();

    return new Date(Date.UTC(year, month, day));
  };

  const filteredRedirectRows = useMemo(() => {
    const { startDate, endDate } = dateFilter;

    const noStart = startDate == null;
    const noEnd = endDate == null;

    const showAllItems = noStart && noEnd;

    if (showAllItems) return redirectRows;

    const utcStartDate = convertToUTC(startDate);
    const utcEndDate = convertToUTC(endDate);

    const endDateMoment = moment.utc(utcEndDate).endOf('day');
    const startDateMoment = moment.utc(utcStartDate).startOf('day');

    if (noStart) {
      return redirectRows.filter((item) => {
        const createdAt = new Date(item.createdAt);

        return createdAt <= endDateMoment;
      });
    }

    if (noEnd) {
      return redirectRows.filter((item) => {
        const createdAt = new Date(item.createdAt);

        return createdAt >= startDateMoment;
      });
    }

    return redirectRows.filter((item, index) => {
      const createdAt = new Date(item.createdAt);

      const afterStart = createdAt >= startDateMoment;
      const beforeEnd = createdAt <= endDateMoment;

      return afterStart && beforeEnd;
    });
  }, [dateFilter, redirectRows]);

  useEffect(() => {
    return init();
  }, [trafficData]);

  const selectedRowData = useMemo(() => {
    return redirectRows.find((item) => item._id === selectedRow[0]);
  }, [selectedRow, redirectRows]);

  const { startDate, endDate } = dateFilter;

  const hasDate = startDate || endDate;
  const startString =
    startDate && new Date(startDate).toLocaleDateString('en-GB');
  const endString = endDate && new Date(endDate).toLocaleDateString('en-GB');

  const tableCols = generateTableCols(
    onDeleteGeneratedValues,
    onGenerateNewRandomValues
  );

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="h6">Generated values</Typography>
        <IconButton size="small" color="primary" onClick={init}>
          <RefreshIcon />
        </IconButton>
      </Box>
      <Card>
        {hasDate && (
          <Box p={1} display="flex" gap={1}>
            {startString && <Chip label={`Starts ${startString}`} />}
            {endString && <Chip label={`Ends ${endString}`} />}
          </Box>
        )}

        <DataGrid
          autoHeight
          rows={filteredRedirectRows}
          columns={tableCols}
          getRowId={(r) => r._id}
          loading={loading}
          onRowSelectionModelChange={setSelectedRow}
          initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          slots={{
            toolbar: () => (
              <RedirectToolbar
                currentData={redirectRows}
                selectedRow={selectedRowData}
                onEditRedirectDone={onEditRedirectDone}
                onDateFilterDone={onDateFilterDone}
                dateFilter={dateFilter}
                currentFilteredData={filteredRedirectRows}
              />
            ),
          }}
        />
      </Card>
    </Box>
  );
}

export default memo(RedirectTable);

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

const generateTableCols = (onDelete, onGenerateNewRandomValues) => [
  {
    headerName: 'Created',
    field: 'createdAt',
    flex: 1,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleString('en-GB', {
        timeZone: 'UTC',
        timeStyle: 'long',
        dateStyle: 'short',
        hour12: false,
      });
    },
  },
  {
    headerName: 'Visited url',
    field: 'visitedUrl',
    flex: 2,
  },
  {
    headerName: 'Tag name',
    field: 'tag',
    flex: 1,
  },
  {
    headerName: 'Original value',
    field: 'originalValue',
    flex: 1,
  },
  {
    headerName: 'Generated value',
    field: 'generatedValue',
    flex: 1,
  },
  {
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => {
      return (
        <Box>
          <TooltipIconButton
            icon={<ContentCopyIcon />}
            title="Copy URL"
            onClick={() => copyToClipboard(params.row.visitedUrl)}
            size="small"
          />
          <ConfirmModal
            onConfirm={() => {
              onGenerateNewRandomValues(params.row._id);
            }}
            title="Recreate Generated Value"
            icon={<RefreshIcon />}
          >
            Do you want to recreate the Generated Value?
          </ConfirmModal>
          <ConfirmModal
            onConfirm={() => {
              onDelete(params.row._id);
            }}
            title="Delete"
            icon={<DeleteIcon />}
          >
            Do you want to delete this generated value?
          </ConfirmModal>
        </Box>
      );
    },
  },
];
