export function getAxiosErrorMessage(error: any) {
  if (error.response) return error.response.data.error;
  return 'Server error';
}

export function generateRandomString() {
  const num = Math.round(Math.random() * 100000);

  return num.toString(16);
}

export function toTwoDecimals(number: number) {
  return +number.toFixed(2);
}
