import { Alert, Card, Chip, Box, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import useAffiliate from '../useAffiliate';
import { Affiliate } from '../types';
import AffiliateToolbar from '../AffiliateToolbar';
import ConfirmModal from '../../../components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { CanceledError } from 'axios';

import TooltipIconButton from '../../../components/TooltipIconButton';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {};

const AffiliateTable = (props: Props) => {
  const { getAffiliates, deleteAffiliate } = useAffiliate();

  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const selectedAffiliate = useMemo(() => {
    return affiliates.find((item) => item._id === selectedRow[0]);
  }, [affiliates, selectedRow]);

  const init = () => {
    const apiCall = getAffiliates();

    (async function () {
      try {
        setLoading(true);
        const allAffiliates = await apiCall.call();
        setAffiliates(allAffiliates);
      } catch (error) {
        if (!typeof CanceledError)
          console.log('[init] error getting affiliates');
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  const onAddAffiliateDone = (newAffiliate: Affiliate) => {
    setAffiliates([newAffiliate, ...affiliates]);
  };

  const onEditAffiliateDone = (newAffiliate: Affiliate) => {
    const newAffiliates = affiliates.map((item) => {
      if (item._id === newAffiliate._id) return newAffiliate;
      return item;
    });

    setAffiliates(newAffiliates);
  };

  const onDeleteAffiliate = async (affiliateId: Affiliate['_id']) => {
    try {
      setLoading(true);
      await deleteAffiliate().call(affiliateId);
      const newAffiliates = affiliates.filter(
        (item) => item._id !== affiliateId
      );
      setAffiliates(newAffiliates);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return init();
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const generateTableCols = (onDelete: Function) => {
    const tableCols: GridColDef[] = [
      {
        headerName: 'Affiliate Name',
        field: 'name',
        flex: 1,
        renderCell: (params) => {
          const destination = params.row._id;
          return <Link to={destination}>{params.row.name}</Link>;
        },
      },
      {
        headerName: 'Active status',
        field: 'isActive',
        flex: 1,
        renderCell: (params) => {
          const statusData = [
            {
              label: 'Inactive',
              color: 'default',
            },
            {
              label: 'Active',
              color: 'primary',
            },
          ] as const;

          const currentStatusData = statusData[+Boolean(params?.value)];

          return (
            <Chip
              label={currentStatusData.label}
              color={currentStatusData.color}
            />
          );
        },
      },
      {
        headerName: 'Created at',
        field: 'createdAt',
        flex: 1,
        valueFormatter: (params) => {
          const date = new Date(params.value);
          const formattedDate = date.toLocaleDateString('en-GB');
          return formattedDate;
        },
      },
      {
        headerName: 'Affiliate Tracking Prefix',
        field: 'prefix',
        flex: 1,
      },
      {
        headerName: 'Actions',
        field: '_id',
        flex: 0.5,
        renderCell: (params) => {
          let clipBoardText = params.row.apiKey;

          return (
            <Box>
              <TooltipIconButton
                icon={<SearchIcon />}
                title="View single affiliate"
                onClick={() => navigate(params.row._id)}
                size="small"
              />
              <ConfirmModal
                iconButton
                icon={<VisibilityIcon />}
                title="View affiliate api key"
                onConfirm={() => {}}
              >
                <Alert severity="info">
                  You may copy the API Key listed below in order to access your
                  quality reports data
                </Alert>

                <Box display="flex" gap={1}>
                  <TextField
                    value={params.row.apiKey}
                    label="API Key"
                    disabled
                    fullWidth
                  />
                  <TooltipIconButton
                    icon={<ContentCopyIcon />}
                    title="Copy API Key"
                    onClick={() => copyToClipboard(clipBoardText)}
                    size="small"
                  />
                </Box>
              </ConfirmModal>
              <ConfirmModal
                iconButton
                icon={<DeleteIcon />}
                title="Delete affiliate"
                onConfirm={() => {
                  onDelete(params.row._id);
                }}
              >
                <Alert severity="warning">
                  Are you sure you want to delete this affiliate? This process
                  is irreversible
                </Alert>
              </ConfirmModal>
            </Box>
          );
        },
      },
    ];

    return tableCols;
  };

  const tableCols = generateTableCols(onDeleteAffiliate);

  return (
    <Card>
      <DataGrid
        autoHeight
        rows={affiliates}
        columns={tableCols}
        getRowId={(r) => r._id}
        onRowSelectionModelChange={setSelectedRow}
        loading={loading}
        slots={{
          toolbar: () => (
            <AffiliateToolbar
              onAddAffiliateDone={onAddAffiliateDone}
              onEditAffiliateDone={onEditAffiliateDone}
              selectedAffiliate={selectedAffiliate}
            />
          ),
        }}
      />
    </Card>
  );
};

export default AffiliateTable;
