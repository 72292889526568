import { Box, Button, CircularProgress, Typography } from '@mui/material';
import TrafficBlockForm from '../TrafficBlockForm';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { getAxiosErrorMessage } from '../../../lib';
import useTraffic from '../useTraffic';
import { TrafficSource } from '../types';

type Props = {};

const SingleTrafficBlock = (props: Props) => {
  const [trafficData, setTrafficData] = useState<TrafficSource | null>(null);
  const [loading, setLoading] = useState(false);

  const { getSingleTraffic } = useTraffic();

  const params = useParams();
  const navigate = useNavigate();
  const client = useTraffic();

  const init = (id: string) => {
    setLoading(true);
    const apiCall = getSingleTraffic();

    (async function () {
      try {
        const singleTraffic = await apiCall.call(id);
        setTrafficData(singleTraffic);
      } catch (error) {
        console.error(getAxiosErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    if (!params.sourceId) return;

    return init(params.sourceId);
  }, [params.sourceId]);

  const onSubmitRules = async (rules: TrafficSource['blockRules']) => {
    if (!trafficData) return;

    const params = { ...trafficData, blockRules: rules };
    await client.editTraffic().call(trafficData._id, params);
  };

  return (
    <Box my={4}>
      <Box mb={3}>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <Button startIcon={<ArrowBack />}>Back</Button>
        </Link>
      </Box>
      <Typography variant="h4" gutterBottom>
        Block rules
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TrafficBlockForm
          blockRules={trafficData?.blockRules}
          onSubmit={onSubmitRules}
        />
      )}
    </Box>
  );
};

export default SingleTrafficBlock;
