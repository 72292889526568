import React from 'react';
import { useCSVReader } from 'react-papaparse';
import PublishIcon from '@mui/icons-material/Publish';
import { Button } from '@mui/material';

function ImportOffer({ onUpload }) {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={data => {
        onUpload(data.data);
      }}
    >
      {({ getRootProps }) => (
        <>
          <Button {...getRootProps()} size="small" startIcon={<PublishIcon />}>
            Import
          </Button>
        </>
      )}
    </CSVReader>
  );
}

export default ImportOffer;
