import useApi from '../../../../hooks/useApi';

const BASE_URL = '/api/affiliate';

const useOriginalReports = () => {
  const { api, createApiCall } = useApi();

  const getOriginalReports = createApiCall(
    async ({ signal }, date: Date, affiliatePrefix: string) => {
      const reportsDto = {
        date: date,
        affiliatePrefix: affiliatePrefix,
      };

      const {
        data: { originalReports },
      } = await api.post(`${BASE_URL}/originalReports`, reportsDto);

      return originalReports;
    }
  );

  return {
    getOriginalReports,
  };
};

export default useOriginalReports;
