import React, { useState, useContext } from 'react';
import { Card, Box, Typography, TextField, Button, CircularProgress, Alert } from '@mui/material';
import useApi from '../../hooks/useApi';
import { useNavigate, Navigate, useSearchParams } from 'react-router-dom';
import { getAxiosErrorMessage } from '../../lib';
import { AuthContext } from '../../context/authContext';

const DEFAULT_CHALLENGE_PARAMS = {
  challengeName: '',
  session: '',
  newPassword: '',
  username: '',
};

function Login() {
  const [user, setUser] = useState({ password: '', username: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [queries, setQueries] = useSearchParams();

  const { api: apiClient } = useApi();

  const [challengeRequired, setChallengeRequired] = useState(false);
  const [challengeParams, setChallengeParams] = useState(DEFAULT_CHALLENGE_PARAMS);

  const { clearCredentials, saveCredentials, isAuthenticated } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleNewPasswordChange = (e: any) => {
    const { value } = e.target;
    setChallengeParams({
      ...challengeParams,
      newPassword: value,
    });
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError('');
      const { data } = await apiClient.post('/api/auth/login', user);

      const { ChallengeName, Session } = data;

      if (ChallengeName) {
        setChallengeRequired(true);
        setChallengeParams({
          challengeName: ChallengeName,
          session: Session,
          newPassword: '',
          username: user.username,
        });
      } else {
        saveCredentials(data);
        navigate('/');
      }
    } catch (e) {
      console.error(e);
      setError(getAxiosErrorMessage(e));
      clearCredentials();
    } finally {
      setLoading(false);
    }
  };

  const onChallengeSubmit = async () => {
    try {
      setLoading(true);
      setError('');
      const { data } = await apiClient.post('/api/auth/challenge', challengeParams);

      saveCredentials(data);

      setChallengeRequired(false);
      navigate('/');
    } catch (e) {
      console.error(e);
      setError(getAxiosErrorMessage(e));
      clearCredentials();
      setChallengeParams({ ...challengeParams, newPassword: '' });
    } finally {
      setLoading(false);
    }
  };

  if (isAuthenticated) {
    const destination = queries.get('r') ?? '/admin';

    return <Navigate to={destination} />;
  }

  return (
    <Box mt={4} display="flex" justifyContent="center">
      <Card sx={{ width: 500 }}>
        <Box display="flex" flexDirection="column" gap={2} p={4}>
          <Typography variant="h6">
            {challengeRequired ? 'New password required ' : 'Login'}
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          {challengeRequired ? (
            <>
              <TextField
                onChange={handleNewPasswordChange}
                type="password"
                value={challengeParams.newPassword}
                placeholder="New password"
              />
              <Button variant="contained" onClick={onChallengeSubmit}>
                {loading ? <CircularProgress /> : 'Submit'}
              </Button>
            </>
          ) : (
            <>
              <TextField
                onChange={handleChange}
                name="username"
                value={user.username}
                placeholder="Username"
              />
              <TextField
                onChange={handleChange}
                name="password"
                type="password"
                value={user.password}
                placeholder="Password"
              />
              <Button variant="contained" onClick={onSubmit}>
                {loading ? <CircularProgress /> : 'Submit'}
              </Button>
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default Login;
