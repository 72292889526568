import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddQualityReports from '../AddQualityReports';
import EditQualityReports from '../EditQualityReports';
import SimpleToolbar from '../../../../components/SimpleToolbar';
import useAffiliate from '../../useAffiliate';
import { AddQualityReportsDTO } from '../SingleQualityReport/types';
import { QualityEntry } from '../SingleQualityReport/types';

type Props = {
  onAddQualityReportsDone: (newQualityReport: QualityEntry) => any;
  onEditQualityReportsDone: (newQualityReport: QualityEntry) => any;
  selectedQualityReport?: QualityEntry;
};

const SingleAffiliateToolbar = ({
  onAddQualityReportsDone,
  onEditQualityReportsDone,
  selectedQualityReport,
}: Props) => {
  const { addAffiliateStats, editAffiliateStats } = useAffiliate();

  const onAddQualityReports = async (dto: AddQualityReportsDTO) => {
    const newQualityReport = await addAffiliateStats().call(dto);
    onAddQualityReportsDone(newQualityReport);
  };

  const onEditQualityReports = async (
    qualityReportId: string,
    dto: QualityEntry
  ) => {
    const newQualityReport = await editAffiliateStats().call(
      qualityReportId,
      dto
    );
    onEditQualityReportsDone(newQualityReport);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <AddQualityReports
            open={open}
            handleClose={handleClose}
            onAddQualityReports={onAddQualityReports}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: Boolean(selectedQualityReport),
      icon: <EditIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <EditQualityReports
            open={open}
            handleClose={handleClose}
            onEditQualityReports={onEditQualityReports}
            initialValues={selectedQualityReport!}
          />
        );
      },
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
};

export default SingleAffiliateToolbar;
