import { AuthContext } from '../context/authContext';
import { useContext, useEffect, useRef } from 'react';
import axios from 'axios';

type CreateApiCallback = (controller: AbortController, ...rest: any) => any;

export default function useApi() {
  const {
    tokens: { IdToken, AccessToken, RefreshToken },
    clearCredentials,
  } = useContext(AuthContext);

  const apiRef = useRef(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })
  );

  const createApiCall = (callback: CreateApiCallback) => () => {
    const aborter = new AbortController();

    return {
      call: async (...params: any) => {
        return await callback(aborter, ...params);
      },
      abort: () => {
        aborter.abort();
      },
    };
  };

  useEffect(() => {
    const client = apiRef.current;

    client.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${IdToken}`;
        return config;
      },
      err => {
        return Promise.reject(err);
      }
    );

    client.interceptors.response.use(
      request => request,
      err => {
        if (err.response) {
          const { status } = err.response;
          if (status === 401) {
            clearCredentials();
          }
        }
        return Promise.reject(err);
      }
    );

    return () => {
      client.interceptors.response.clear();
      client.interceptors.request.clear();
    };
  }, [IdToken, AccessToken, RefreshToken]);

  return { api: apiRef.current, createApiCall };
}
