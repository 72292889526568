import React, { useState } from 'react';
import { Typography, TextField, Button, CircularProgress } from '@mui/material';
import { QualityEntries, QualityEntry } from '../types';
import SimpleModal from '../../../../../components/SimpleModal';

type Props = {
  open: boolean;
  handleClose: () => any;
  onEditQualityReportsEntries: (
    qualityEntryId: QualityEntry['_id'],
    qualityReportsEntriesId: QualityEntries['_id'],
    newQualityReportEntry: QualityEntries
  ) => any;
  initialValues: QualityEntries;
  qualityEntry: QualityEntry;
};

const EditQualityReportsEntries = ({
  open,
  handleClose,
  onEditQualityReportsEntries,
  initialValues,
  qualityEntry,
}: Props) => {
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    const regex = /^[0-9]*(\.[0-9]*)?$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      const { name, value } = e.target;
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await onEditQualityReportsEntries(qualityEntry._id, initialValues._id, {
        ...values,
      });
      handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit Merchant Quality Report Entry</Typography>
      <TextField
        fullWidth
        label="Merchant Name"
        value={values.merchantName}
        disabled
      />
      <TextField
        fullWidth
        name="merchantId"
        label="Merchant ID"
        value={values.merchantId}
        disabled
      />
      <TextField
        fullWidth
        name="placementId"
        onChange={handleChange}
        label="Placement ID"
        value={values.placementId}
      />
      <TextField
        fullWidth
        name="clicks"
        onChange={handleChange}
        label="Clicks"
        value={values.clicks}
      />
      <TextField
        fullWidth
        name="costOfSale"
        onChange={handleChange}
        label="Cost of Sale"
        value={values.costOfSale}
      />
      <TextField
        fullWidth
        name="targetCostOfSale"
        onChange={handleChange}
        label="Target Cost of Sale"
        value={values.targetCostOfSale}
      />
      <TextField
        fullWidth
        name="conversionRate"
        onChange={handleChange}
        label="Conversion Rate"
        value={values.conversionRate}
      />
      <TextField
        fullWidth
        name="salesCount"
        onChange={handleChange}
        label="Sales Count"
        value={values.salesCount}
      />
      <TextField
        fullWidth
        name="sales"
        onChange={handleChange}
        label="Sales"
        value={values.sales}
      />
      <TextField
        fullWidth
        name="cpc"
        onChange={handleChange}
        label="Cost per Click"
        value={values.cpc}
      />
      <TextField
        fullWidth
        name="revenue"
        onChange={handleChange}
        label="Revenue"
        value={values.revenue}
      />
      <Button color="primary" variant="contained" onClick={onSubmit} fullWidth>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
      </Button>
    </SimpleModal>
  );
};

export default EditQualityReportsEntries;
