import { useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import { Button, TextField, Typography } from '@mui/material';
import { EditMerchantDto, Merchant } from '../types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onEditMerchant: (
    merchantId: Merchant['_id'],
    newAffiliate: EditMerchantDto
  ) => any;
  initialValues: Merchant;
};

const EditMerchant = ({
  initialValues,
  open,
  handleClose,
  onEditMerchant,
}: Props) => {
  const [values, setValues] = useState<Merchant>(initialValues);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues(() => ({
      ...values,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    await onEditMerchant(initialValues._id, values);
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit affiliate</Typography>
      <TextField
        fullWidth
        name="merchantName"
        onChange={handleChange}
        label="Merchant Name"
        value={values.merchantName}
      />
      <TextField
        fullWidth
        name="merchantUrl"
        onChange={handleChange}
        label="Merchant Url"
        value={values.merchantUrl}
      />
      <TextField
        fullWidth
        name="normalizedMerchantUrl"
        onChange={handleChange}
        label="Normalized Merchant Url"
        value={values.normalizedMerchantUrl}
        helperText="URL without https://"
      />
      <Button color="primary" variant="contained" onClick={onSubmit} fullWidth>
        Submit
      </Button>
    </SimpleModal>
  );
};

export default EditMerchant;
