import React from 'react';
import SimpleToolbar from '../../../components/SimpleToolbar';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { AddMerchantDto, EditMerchantDto, Merchant } from '../types';
import useMerchants from '../useMerchants';
import AddMerchant from '../AddMerchant';
import EditMerchant from '../EditMerchant';

type Props = {
  selectedMerchant: Merchant;
  onAddMerchantDone: (newMerchant: Merchant) => any;
  onEditMerchantDone: (newMerchant: Merchant) => any;
};

const MerchantToolbar = ({
  selectedMerchant,
  onAddMerchantDone,
  onEditMerchantDone,
}: Props) => {
  const { addMerchant, editMerchant } = useMerchants();

  const onAddMerchant = async (dto: AddMerchantDto) => {
    const api = addMerchant();
    const newMerchant = await api.call(dto);
    onAddMerchantDone(newMerchant);
  };

  const onEditMerchant = async (id: string, dto: EditMerchantDto) => {
    const api = editMerchant();
    const newMerchant = await api.call(id, dto);
    onEditMerchantDone(newMerchant);
  };

  const modalButtons = [
    {
      name: 'add',
      label: 'Add',
      renderIf: true,
      icon: <AddIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <AddMerchant
            open={open}
            handleClose={handleClose}
            onAddMerchant={onAddMerchant}
          />
        );
      },
    },
    {
      name: 'edit',
      label: 'Edit',
      renderIf: !!selectedMerchant,
      icon: <EditIcon />,
      render: (open: boolean, handleClose: () => any) => {
        return (
          <EditMerchant
            open={open}
            handleClose={handleClose}
            onEditMerchant={onEditMerchant}
            initialValues={selectedMerchant!}
          />
        );
      },
    },
  ];

  return <SimpleToolbar modalButtons={modalButtons} />;
};

export default MerchantToolbar;
