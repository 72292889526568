import React from 'react';
import { createContext, useState, useEffect } from 'react';
import useTraffic from '../useTraffic';
import { getAxiosErrorMessage } from '../../../lib';

export const SingleTrafficContext = createContext(null);

function SingleTrafficProvider({ sourceId, children }) {
  const { getSingleTraffic } = useTraffic();
  const [loading, setLoading] = useState(false);
  const [trafficData, setTrafficData] = useState(null);

  const init = id => {
    setLoading(true);
    const apiCall = getSingleTraffic();

    (async function () {
      try {
        const singleTraffic = await apiCall.call(id);
        setTrafficData(singleTraffic);
      } catch (error) {
        console.error(getAxiosErrorMessage(error));
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init(sourceId);
  }, [sourceId]);

  return (
    <SingleTrafficContext.Provider value={{ trafficData, loading, setTrafficData }}>
      {children}
    </SingleTrafficContext.Provider>
  );
}

export default SingleTrafficProvider;
