import React, { useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router-dom';
import { CanceledError } from 'axios';
import useAffiliate from '../../useAffiliate';
import { QualityEntry } from './types';

type Props = {
  children: React.ReactNode;
};

type SingleQualityEntryCTX = {
  qualityEntry: QualityEntry | null;
  loading: boolean;
};

export const SingleQualityEntryContext = createContext<SingleQualityEntryCTX>({
  loading: true,
  qualityEntry: null,
});

function SingleQualityEntryProvider({ children }: Props) {
  const [loading, setLoading] = useState(true);
  const [qualityEntry, setQualityEntry] = useState<QualityEntry | null>(null);
  const { getSingleQualityEntry } = useAffiliate();

  const params = useParams();

  const init = () => {
    const apiCall = getSingleQualityEntry();

    (async function () {
      try {
        setLoading(true);
        const singleQualityEntry = await apiCall.call(params.qualityEntryId);
        setQualityEntry(singleQualityEntry);
      } catch (error) {
        if (!typeof CanceledError)
          console.log(
            '[singlequalityentryprovider] error getting quality entry'
          );
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, [params.qualityEntryId]);

  const values = {
    loading,
    qualityEntry,
  };

  return (
    <SingleQualityEntryContext.Provider value={values}>
      {children}
    </SingleQualityEntryContext.Provider>
  );
}

export default SingleQualityEntryProvider;
