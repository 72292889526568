import React from 'react';
import { Box } from '@mui/material';
import OfferTable from './OfferTable';
import OfferProvider from './context';

function OfferPage() {
  return (
    <OfferProvider>
      <Box mt={4}>
        <OfferTable />
      </Box>
    </OfferProvider>
  );
}

export default OfferPage;
