import {
  TextField,
  Typography,
  Button,
  MenuItem,
  Menu,
  Box,
  Alert,
} from '@mui/material';
import SimpleModal from '../../../../components/SimpleModal';
import { useState, useContext } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingButton from '../../../../components/LoadingButton';

import { SingleTrafficContext } from '../context';

const CONDITIONS = ['None', 'Starts with', 'Ends with', 'Contains'];

export default function AddTraffic({ open, handleClose, onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    tag: '',
    autogenerateRule: 0,
    length: 7,
    groupBy: '',
    replacementMethod: 'urlParameter',
    prefix: '',
    defaultValue: 'NONE',
  });
  const [conditionType, setConditionType] = useState(0);
  const [conditionValue, setConditionValue] = useState('');
  const [anchor, setAnchor] = useState(null);

  const { trafficData } = useContext(SingleTrafficContext);

  const onChange = (e) => {
    const { name, value } = e.target;

    setValues(() => {
      return {
        ...values,
        [name]: value,
      };
    });
  };

  const changeConditionValue = (e) => {
    setConditionValue(e.target.value);
  };

  const openConditions = (e) => setAnchor(e.currentTarget);

  const closeConditions = () => setAnchor(null);

  const selectCondition = (e) => {
    const { value } = e.target;

    if (value === 0) setConditionValue('');

    setConditionType(value);
    setAnchor(null);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await onSubmit({
        ...values,
        conditionRegex: {
          value: conditionValue,
          type: conditionType,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isMenuOpen = Boolean(anchor);
  const menuIcon = isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  const groupMenuItems = trafficData?.parameterOptions ?? [];

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Add parameters to this source</Typography>
      <TextField
        value={values.tag}
        onChange={onChange}
        fullWidth
        name="tag"
        label="Tag"
        placeholder="Tag"
        helperText="The parameter that you want to detect in the url"
      />
      <TextField
        value={values.autogenerateRule}
        onChange={onChange}
        fullWidth
        name="autogenerateRule"
        label="Autogenerate rule"
        select
      >
        <MenuItem value={0}>None</MenuItem>
        <MenuItem value={1}>Letters only</MenuItem>
        <MenuItem value={2}>Numbers only</MenuItem>
        <MenuItem value={3}>Alphanumeric</MenuItem>
      </TextField>
      <TextField
        type="number"
        name="length"
        onChange={onChange}
        placeholder="Length"
        label="Length"
        helperText="Fixed length of the generated value"
        value={values.length}
      />
      <TextField
        select
        onChange={onChange}
        label="Group by"
        name="groupBy"
        helperText="This allows the generated value to be unique based on a group"
        SelectProps={{ displayEmpty: true }}
        InputLabelProps={{ shrink: true }}
        value={values.groupBy}
      >
        <MenuItem value="">None</MenuItem>
        {groupMenuItems.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.tag}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        name="prefix"
        label="Prefix"
        onChange={onChange}
        placeholder="Prefix"
        value={values.prefix}
        helperText="Must be less than the set length of the generated value"
      />

      <TextField
        name="defaultValue"
        label="Default value"
        select
        onChange={onChange}
        placeholder="Default value"
        value={values.defaultValue}
        helperText="Default value is added before any generate rule is applied"
      >
        <MenuItem value="NONE">None</MenuItem>
        <MenuItem value="VISITOR_IP">Visitor IP</MenuItem>
        <MenuItem value="VISITOR_UA">Visitor UA</MenuItem>
      </TextField>

      <Typography mt={2} variant="title">
        Replacement Method
      </Typography>
      <TextField
        value={values.replacementMethod}
        onChange={onChange}
        fullWidth
        name="replacementMethod"
        label="Method"
        select
      >
        <MenuItem value="urlParameter">Add as URL parameter</MenuItem>
        <MenuItem value="dynamicParameter">Add as dynamic parameter</MenuItem>
        <MenuItem value="urlAndDynamicParameter">
          Both as URL and dynamic parameter
        </MenuItem>
      </TextField>

      <Typography mt={2} variant="title">
        Optional Conditions
      </Typography>
      <Alert severity="info">
        CASE-SENSITIVE. These are checked before a value is generated.
      </Alert>
      <Box display="flex" alignItems="center" gap={2}>
        <Button
          onClick={openConditions}
          variant="outlined"
          endIcon={menuIcon}
          sx={{ height: 54 }}
        >
          {CONDITIONS[conditionType]}
        </Button>
        <TextField
          onChange={changeConditionValue}
          value={conditionValue}
          disabled={conditionType === 0}
          sx={{ flex: 1 }}
        />

        <Menu anchorEl={anchor} open={isMenuOpen} onClose={closeConditions}>
          {CONDITIONS.map((label, index) => {
            return (
              <MenuItem key={label} value={index} onClick={selectCondition}>
                {label}
              </MenuItem>
            );
          })}
        </Menu>
      </Box>

      <LoadingButton
        variant="contained"
        onClick={handleSubmit}
        loading={loading}
      >
        Add
      </LoadingButton>
    </SimpleModal>
  );
}
