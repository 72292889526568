import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CellFilter({ filters, setFilters, column, rows }) {
  const renderOption = (props, option, state) => (
    <li {...props}>
      <FormControlLabel
        control={<Checkbox checked={state.selected} />}
        label={option}
      />
    </li>
  );

  // Handle changes in the Autocomplete component
  const handleAutocompleteChange = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column.field]: newValue, // Update the filters state with the selected/deselected values
    }));
  };

  return (
    <Box>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id="tags-standard"
        options={rows}
        value={filters[column.field]}
        getOptionLabel={(option) => option}
        renderOption={renderOption}
        onChange={handleAutocompleteChange} // Handle selection/deselection changes
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder="Filter" />
        )}
        renderTags={() => null} // This will hide selected tags
      />
    </Box>
  );
}
