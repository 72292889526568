import { Box } from '@mui/material';
import DomainTable from './DomainTable';

function DomainPage() {
  return (
    <Box mt={4}>
      <DomainTable />
    </Box>
  );
}

export default DomainPage;
