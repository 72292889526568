import { Box } from '@mui/material';
import OfferGroupReportTable from '../OfferGroupReportTable';

type Props = {};

const OfferGroupReport = (props: Props) => {
  return (
    <Box>
      <OfferGroupReportTable />
    </Box>
  );
};

export default OfferGroupReport;
