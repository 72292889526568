import React from 'react';
import { Box } from '@mui/material';
import BlacklistedProductsTable from './BlacklistedProductsTable';

function ProductsPage() {
  return (
    <>
      <Box mt={4}>
        <BlacklistedProductsTable />
      </Box>
    </>
  );
}

export default ProductsPage;
