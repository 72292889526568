import React, { useState } from 'react';
import { Typography, TextField, Button } from '@mui/material';
import SimpleModal from '../../../components/SimpleModal';
import { BlacklistedProduct } from '../types';

type Props = {
  open: boolean;
  handleClose: () => any;
  onEditProduct: (
    productId: BlacklistedProduct['_id'],
    newProduct: BlacklistedProduct
  ) => any;
  initialValues: BlacklistedProduct;
};

const EditProduct = ({
  open,
  handleClose,
  onEditProduct,
  initialValues,
}: Props) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (!isNaN(value)) {
      setValues(() => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const onSubmit = async () => {
    await onEditProduct(initialValues._id, {
      ...values,
      productId: values.productId,
    });
    handleClose();
  };

  return (
    <SimpleModal open={open} handleClose={handleClose}>
      <Typography variant="h6">Edit Blacklisted Product</Typography>
      <TextField
        fullWidth
        name="productId"
        onChange={handleChange}
        label="Product Id"
        value={values.productId}
      />
      <Button
        color="primary"
        variant="contained"
        onClick={onSubmit}
        disabled={!values.productId}
        fullWidth
      >
        Submit
      </Button>
    </SimpleModal>
  );
};

export default EditProduct;
